// import EventSessionCreationForm from "../../components/EventSessionCreationForm";

import EventSessionCreationStepper from "../../components/EventSessionCreationStepper";

const EventSessionCreation = () => {
  return (
    <>
      <EventSessionCreationStepper />{" "}
    </>
  );
};

export default EventSessionCreation;
