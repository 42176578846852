import { useTranslation } from "react-i18next";
import { FaCalendarAlt, FaRobot, FaUserCircle } from "react-icons/fa";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
import { IRegistrantEvent } from "../../providers/registrantEvents/registrantEvents.interface";
import { IEvent } from "../../providers/events/events.interface";
import Chart from "../Chart";
import { IUser } from "../../providers/users/user.interface";
import { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { MdLiveTv } from "react-icons/md";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import Loading from "../Loading";
import { useUser } from "../../providers/users";

interface IEventFromDashboard extends IEvent {
  eventSessions: IEventSession[];
  registrationCount: number;
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { dashboardData, getDashboardData } = useTenant();
  const { apiLoading } = useUser();
  const [timePeriod, setTimePeriod] = useState<string>("Last 7 days");
  const [eventTypeFilter, setEventTypeFilter] = useState<string>("All");
  const [top5Events, setTop5Events] = useState<IEventFromDashboard[]>([]);

  const fetchRegistrantEvents = async (selectedTimePeriod: string) => {
    const daysLimit = selectedTimePeriod === "Last 7 days" ? 7 : 30;
    const timestamp = new Date(Date.now() - daysLimit * 86400000).getTime();
    await getDashboardData(timestamp, false);
  };

  const handleTimePeriodChange = (event: any) => {
    const selectedTimePeriod = event.target.value;
    setTimePeriod(selectedTimePeriod);
    fetchRegistrantEvents(selectedTimePeriod);
  };

  const getTopEventsWithRegistrationCounts = () => {
    const eventsWithCounts: IEventFromDashboard[] = dashboardData.events.filter(
      (event: IEvent) =>
        eventTypeFilter === "All" || event.eventType === eventTypeFilter
    );
    eventsWithCounts.forEach((event) => {
      event.registrationCount = event.eventSessions.find(
        (session: IEventSession) => session.sessionNumber === 1
      )?.registrationCount;
    });

    eventsWithCounts.sort((a, b) => b.registrationCount - a.registrationCount);
    return eventsWithCounts.slice(0, 5);
  };

  useEffect(() => {
    if (dashboardData) {
      const updatedTop5Events = getTopEventsWithRegistrationCounts();
      setTop5Events(updatedTop5Events);
    }
  }, [dashboardData, eventTypeFilter, timePeriod]);

  const sources = [
    {
      label: "linkedln",
      percentage: calculatePercentageSource(
        dashboardData.registrants,
        "linkedln"
      ),
    },
    {
      label: "google",
      percentage: calculatePercentageSource(
        dashboardData.registrants,
        "google"
      ),
    },
    {
      label: "instagram",
      percentage: calculatePercentageSource(
        dashboardData.registrants,
        "instagram"
      ),
    },
    {
      label: "email",
      percentage: calculatePercentageSource(dashboardData.registrants, "email"),
    },
  ];

  const demographics = [
    {
      label: "united states",
      percentage: calculatePercentageCountry(
        dashboardData.registrants,
        "united states"
      ),
    },
    {
      label: "brazil",
      percentage: calculatePercentageCountry(
        dashboardData.registrants,
        "brazil"
      ),
    },
    {
      label: "canada",
      percentage: calculatePercentageCountry(
        dashboardData.registrants,
        "canada"
      ),
    },
    {
      label: "australia",
      percentage: calculatePercentageCountry(
        dashboardData.registrants,
        "australia"
      ),
    },
  ];

  if (apiLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <div className="leftDiv">
        <section className="boxesDiv">
          <div className="bigBox">
            <div className="automatedEvents">
              <p>Automated Events Overview</p>
              <div>
                <FaCalendarAlt />
                <div>
                  <p>Events Created</p>
                  <span>{dashboardData.events.length}</span>
                </div>
              </div>
            </div>
            <div className="smallBoxes">
              <div>
                <MdLiveTv />
                <p>Live Now</p>
                <span>{dashboardData.liveNow}</span>
              </div>
              <div>
                <FaRobot />
                <p>AI Moderators</p>
                <span>{dashboardData.assistants.length}</span>
              </div>
            </div>
          </div>
        </section>
        <Chart
          registrations={dashboardData.registrantEvents}
          timePeriod={timePeriod}
        />
        <section className="registrantionMetrics">
          <div className="registrantionSources">
            <p>Registration Sources</p>
            {sources.map((source) => {
              return (
                <div className="sourceCards" key={source.label}>
                  <div className="outlineBar">
                    <div
                      className="inlineBar"
                      style={{ width: `${source.percentage}%` }}
                    ></div>
                  </div>
                  <span>{source.percentage}%</span>
                  <p>{source.label}</p>
                </div>
              );
            })}
          </div>
          <div className="registrantionSources">
            <p>Demographics</p>
            {demographics.map((source) => {
              return (
                <div className="sourceCards" key={source.label}>
                  <div className="outlineBar">
                    <div
                      className="inlineBar"
                      style={{ width: `${source.percentage}%` }}
                    ></div>
                  </div>
                  <span>{source.percentage}%</span>
                  <p>{source.label}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <div className="rightDiv">
        <h3>Top 5 Events</h3>
        <div className="filterSection">
          <div>
            <p onClick={() => setEventTypeFilter("All")}>All</p>
            <p onClick={() => setEventTypeFilter("single-day")}>
              Single-day event
            </p>
            <p onClick={() => setEventTypeFilter("multi-day")}>
              Multi-day event
            </p>
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            value={timePeriod}
            onChange={handleTimePeriodChange}
          >
            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
          </Select>
        </div>
        <section className="eventsList">
          {top5Events.map((event) => (
            <div className="eventItem" key={event.id}>
              <div className="eventTileAndLogo">
                <BsFillCalendarCheckFill />
                <div>
                  <p>{event.name}</p>
                  <span>{event.eventType} event</span>
                </div>
              </div>
              <p>{event.registrationCount} </p>
            </div>
          ))}
        </section>
        <section className="teamMembersList">
          <p>Team Members List</p>
          <div className="membersList">
            {dashboardData.users.map((e: IUser) => {
              return (
                <div className="userAvatar" key={e.id}>
                  <FaUserCircle />
                  <p>
                    {e.first_name} {e.last_name}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </Container>
  );
};

export default Dashboard;

function calculatePercentageSource(items: any, searchString: string) {
  if (!Array.isArray(items) || typeof searchString !== "string") {
    return 0;
  }
  const totalItems = items.length;
  if (totalItems === 0) return 0;
  const matchingItems = items.filter(
    (item) => item.utm_source === searchString
  );
  const matchingCount = matchingItems.length;
  const percentage = (matchingCount / totalItems) * 100;
  return Math.floor(percentage);
}

function calculatePercentageCountry(items: any, searchString: string) {
  if (!Array.isArray(items) || typeof searchString !== "string") {
    return 0;
  }
  const totalItems = items.length;
  if (totalItems === 0) return 0;
  const matchingItems = items.filter((item) => item.country === searchString);
  const matchingCount = matchingItems.length;
  const percentage = (matchingCount / totalItems) * 100;
  return percentage;
}
