import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  form > label {
    margin-top: 10px;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
    /* float: right; */
    /* pointer-events: none; */
  }

  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .assistantList {
    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    padding: 15px;
    margin-top: 30px;
  }
  .filterSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 343px; */
    gap: 10px;
    margin-top: 20px;
  }
  .userActions > svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
