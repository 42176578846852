import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
import path from "path";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
const APISettings = () => {
  const { tenant, getTenant } = useTenant();
  const { path } = useParams();
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {};

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, []);
  return (
    <Container>
      <h3>API settings</h3>
      <TextField
        error={errors.name === undefined ? false : true}
        helperText={errors.name?.message?.toString()}
        id="standard-name"
        label="API Endpoint"
        disabled={true}
        defaultValue={process.env.REACT_APP_BACKEND_URL}
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
          mt: 2,
          textAlign: "center",
          backgroundColor: "#FFF",
          "& fieldset": { border: "none" },
        }}
      />

      <TextField
        error={errors.name === undefined ? false : true}
        helperText={errors.name?.message?.toString()}
        id="standard-name"
        label="API KEY"
        disabled={true}
        defaultValue={tenant.settings && tenant.settings["x-epa-token"]}
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
          mt: 2,
          textAlign: "center",
          backgroundColor: "#FFF",
          "& fieldset": { border: "none" },
        }}
      />
    </Container>
  );
};

export default APISettings;
