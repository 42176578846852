import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { formatTime } from "../EventSessionList";
import { IoMdCart } from "react-icons/io";
import ModalAddLiveContent from "../ModalAddLiveContent";
import TitleWithRefreshIcon from "../TitleWithRefreshIcon";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}
const EventSessionReplayForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const { t } = useTranslation();
  const [chatHistory, setChatHistory] = useState(
    (eventSession?.replayControls &&
      eventSession?.replayControls.chatHistory) ||
      false
  );
  const [chatBox, setChatBox] = React.useState(
    (eventSession?.replayControls && eventSession?.replayControls.chatBox) ||
      "enabled"
  );
  const [checked, setChecked] = React.useState(
    (eventSession?.replayControls && eventSession?.replayControls.enabled) ||
      false
  );
  const [replayControls, setReplayControls] = React.useState(
    (eventSession?.replayControls &&
      eventSession?.replayControls.videoControls) ||
      false
  );
  const [countdown, setCountdown] = React.useState(
    (eventSession?.replayControls && eventSession?.replayControls.countdown) ||
      false
  );
  const [replayExpiration, setReplayExpiration] = React.useState(
    eventSession?.replayControls && eventSession?.replayControls.expirationAfter
      ? true
      : false
  );
  const [replica, setReplica] = React.useState(
    (eventSession?.replayControls &&
      eventSession?.replayControls.videoReplica) ||
      true
  );
  const { editEventSession } = useEventSession();
  const { apiLoading } = useUser();
  const { event_id, path } = useParams();
  const navigate = useNavigate();
  const [modalLiveContent, setModalAddLiveContent] = useState(false);

  const handleReplayExpiration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReplayExpiration(!replayExpiration);
  };
  const handleReplica = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplica(!replica);
  };
  const handleChangeChatHistory = (event: any) => {
    setChatHistory(event.target.checked);
  };
  const handleChangeChatBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatBox((event.target as HTMLInputElement).value);
  };

  const handleChangeCountdown = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCountdown(event.target.checked);
  };
  const handleChangeReplayControls = (event: React.ChangeEvent<any>) => {
    setReplayControls(event.target.checked);
  };
  const handleChangeReplay = (event: React.ChangeEvent<any>) => {
    setChecked(event.target.checked);
  };

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    data.replayControls = {
      enabled: checked,
      chatBox: chatBox,
      chatHistory: chatHistory || false,
      videoControls: replayControls,
      countdown: countdown,
      videoReplica: replica,
      videoUrl: replica ? eventSession?.videoUrl : data.videoUrl,
      videoDuration: replica
        ? eventSession?.videoDuration
        : data.hours * 3600 + data.minutes * 60 + data.seconds,
      expirationAfter: replayExpiration
        ? Number(data.expirationAfter) * 3600 * 1000
        : 0,
      replayExpiration: replayExpiration,
      actionText: data.actionText,
    };

    delete data.actionText;
    delete data.expirationAfter;
    delete data.hours;
    delete data.videoUrl;
    delete data.minutes;
    delete data.seconds;
    if (!eventSession) {
      toast.error("Something got wrong, please try again.");
      navigate(`/${path}/events/${response.eventId}/eventsessions`);
      return;
    }
    const response = await editEventSession(eventSession.id, data);
    setEventSession(response);
    navigate(`/${path}/events/${response.eventId}/eventsessions`);
  };

  const formSchema = yup
    .object()
    .shape({
      expirationAfter: yup
        .string()

        .max(128, t("field_max")),
      videoUrl: yup.string().url(t("video_url_type")),
      actionText: yup.string().max(128, t("field_max")),
      hours: yup.number().max(10),
      minutes: yup.number().max(60),

      seconds: yup.number().max(60),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TitleWithRefreshIcon title={"Replay settings"} />
          <div className="videoPlaySettings">
            <div>
              <span>Activate replay page</span>
              <Switch
                checked={checked}
                onChange={handleChangeReplay}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div>
              <span>Enable replay controls</span>
              <Switch
                checked={replayControls}
                onChange={handleChangeReplayControls}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div>
              <span>Display countdown on replay page</span>
              <Switch
                checked={countdown}
                onChange={handleChangeCountdown}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Replay expiration
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={replayExpiration}
                onChange={handleReplayExpiration}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>{" "}
            {replayExpiration === true && (
              <>
                {" "}
                <TextField
                  error={errors.expirationAfter === undefined ? false : true}
                  helperText={errors.expirationAfter?.message?.toString()}
                  id="standard-expirationAfter"
                  defaultValue={
                    eventSession?.replayControls &&
                    eventSession?.replayControls.expirationAfter &&
                    eventSession?.replayControls.expirationAfter / 3600000
                  }
                  label="Expires after"
                  variant="filled"
                  type="number"
                  required={replayExpiration ? true : false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                    ml: 2,
                    mr: 1,
                  }}
                  {...register("expirationAfter")}
                />
                <span>hours after the session starts</span>{" "}
              </>
            )}
          </div>
          <h3>Replay content settings</h3>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Is the replay going to be the replica of the main event
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={replica}
              onChange={handleReplica}
            >
              <FormControlLabel
                value={true as any}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value={false as any}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>{" "}
          <TextField
            id="standard-actionText"
            error={errors.actionText === undefined ? false : true}
            helperText={errors.actionText?.message?.toString()}
            label="Call to action"
            defaultValue={
              eventSession?.replayControls &&
              eventSession.replayControls.actionText
            }
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("actionText")}
          />
          {replica ? (
            <></>
          ) : (
            <>
              <h3>{t("video_configuration")}</h3>
              <TextField
                id="standard-videoUrl"
                error={errors.videoUrl === undefined ? false : true}
                helperText={errors.videoUrl?.message?.toString()}
                label={t("video_url")}
                defaultValue={
                  (eventSession?.replayControls &&
                    eventSession.replayControls.videoUrl) ||
                  (eventSession && eventSession.videoUrl)
                }
                disabled={replica}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("videoUrl")}
              />
              <h3>{t("video_duration")}</h3>
              <div className="durationDiv">
                <TextField
                  id="standard-hour"
                  error={errors.hours === undefined ? false : true}
                  helperText={errors.hours?.message?.toString()}
                  label={t("hours")}
                  variant="filled"
                  disabled={replica}
                  type="number"
                  defaultValue={
                    (eventSession?.replayControls &&
                      eventSession.replayControls.videoDuration &&
                      Math.floor(
                        eventSession.replayControls.videoDuration / 3600
                      )) ||
                    (eventSession &&
                      Math.floor(eventSession.videoDuration / 3600))
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                  {...register("hours")}
                />
                <TextField
                  id="standard-minutes"
                  error={errors.minutes === undefined ? false : true}
                  helperText={errors.minutes?.message?.toString()}
                  label={t("minutes")}
                  defaultValue={
                    (eventSession?.replayControls &&
                      eventSession.replayControls.videoDuration &&
                      Math.floor(
                        (eventSession.replayControls.videoDuration % 3600) / 60
                      )) ||
                    (eventSession &&
                      Math.floor((eventSession.videoDuration % 3600) / 60))
                  }
                  disabled={replica}
                  type="number"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    ml: 1,
                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                  {...register("minutes")}
                />
                <TextField
                  id="standard-seconds"
                  error={errors.seconds === undefined ? false : true}
                  helperText={errors.seconds?.message?.toString()}
                  label={t("seconds")}
                  defaultValue={
                    (eventSession?.replayControls &&
                      eventSession.replayControls.videoDuration &&
                      eventSession.replayControls.videoDuration % 60) ||
                    (eventSession && eventSession.videoDuration % 60)
                  }
                  disabled={replica}
                  type="number"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    ml: 1,
                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                  {...register("seconds")}
                />
              </div>
              <h3>Live Content</h3>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                <Button
                  onClick={() => setModalAddLiveContent(true)}
                  variant="contained"
                  sx={{ mr: 1, borderRadius: "25px" }}
                >
                  Add Live Content
                </Button>
              </Box>
              <div className="liveContentContainer">
                {eventSession && eventSession.liveContents ? (
                  eventSession.liveContents
                    .filter((e) => e.liveOrReplay === "replay")
                    .map((e: any) => (
                      <div className="liveContent">
                        <div>
                          <h5>
                            <IoMdCart width={30} height={30} /> {e.name}
                          </h5>
                          <p>Start offer: {formatTime(e.startOffer)}</p>
                          <p>End offer: {formatTime(e.endOffer)}</p>
                        </div>
                        <div>
                          <AiOutlineEdit />
                          <MdDelete />
                        </div>
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
          <div className="chatSettings">
            <div className="advancedSettings">
              <h3>Chat basic settings</h3>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Live chat box
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={chatBox}
                    onChange={handleChangeChatBox}
                  >
                    <FormControlLabel
                      value="enabled"
                      control={<Radio />}
                      label="Enabled"
                    />
                    <FormControlLabel
                      value="disabled"
                      control={<Radio />}
                      label="Disabled"
                    />
                    <FormControlLabel
                      value="questionBox"
                      control={<Radio />}
                      label="Display question box"
                    />
                  </RadioGroup>
                </FormControl>{" "}
              </div>
            </div>
          </div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1, borderRadius: "25px" }}
              disabled={apiLoading}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              variant="contained"
              disabled={apiLoading}
              sx={{ mr: 1, borderRadius: "25px" }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Container>
      {modalLiveContent && (
        <ModalAddLiveContent
          setModalAddLiveContent={setModalAddLiveContent}
          liveOrReplay="replay"
        />
      )}
    </>
  );
};

export default EventSessionReplayForm;
