import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import { IRegistrantEvent } from "../../providers/registrantEvents/registrantEvents.interface";
import { Container } from "./styles";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineLiveTv, MdPeople } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { formatTime } from "../EventSessionList";

interface IProps {
  registrantEvents: IRegistrantEvent[];
  session: IEventSession;
}

const AnalyticsItem = ({ registrantEvents, session }: IProps) => {
  const liveWatchPercentage = percentWatchedFullEvent(
    registrantEvents.filter((e) => e.watchedLive === true),
    session.videoDuration
  );

  const replayWatchPercentage = session.replayControls
    ? percentWatchedFullReplay(
        registrantEvents.filter((e) => e.watchedReplay === true),
        session.replayControls.videoDuration
          ? session.replayControls.videoDuration
          : 0
      )
    : 0;

  return (
    <Container>
      <h3>Live event</h3>
      <section>
        <p>
          <MdPeople /> Live attendees:{" "}
          {registrantEvents.filter((e) => e.watchedLive === true).length}
        </p>
        <p>
          <IoTimeOutline /> Session length: {formatTime(session.videoDuration)}
        </p>
        <p>
          <MdOutlineLiveTv />
          Average time on live room:{" "}
          {formatTime(
            avrgWatchTime(
              registrantEvents.filter((e) => e.watchedLive === true)
            )
          )}
        </p>
        <p>
          <FaRegEye /> Watched the full event: {liveWatchPercentage}%
        </p>
      </section>

      {session.replayControls && (
        <>
          <h3>Replay event</h3>
          <section>
            <p>
              <MdPeople /> Replay attendees:{" "}
              {registrantEvents.filter((e) => e.watchedReplay === true).length}
            </p>
            <p>
              <IoTimeOutline /> Session length:{" "}
              {formatTime(
                session.replayControls.videoDuration
                  ? session.replayControls.videoDuration
                  : 0
              )}
            </p>
            <p>
              <MdOutlineLiveTv />
              Average time on replay room:{" "}
              {formatTime(
                avrgWatchReplayTime(
                  registrantEvents.filter((e) => e.watchedReplay === true)
                )
              )}
            </p>
            <p>
              <FaRegEye /> Watched the full replay: {replayWatchPercentage}%
            </p>
          </section>
        </>
      )}
    </Container>
  );
};

export default AnalyticsItem;

function avrgWatchTime(arr: IRegistrantEvent[]) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return 0;
  }
  const somaWatchedTime = arr.reduce((total, currentItem) => {
    const itemToSum = currentItem.watchedTime ? currentItem.watchedTime : 0;
    return total + itemToSum;
  }, 0);

  const mediaWatchedTime = somaWatchedTime / arr.length;

  return Math.floor(mediaWatchedTime);
}

function avrgWatchReplayTime(arr: IRegistrantEvent[]) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return 0;
  }

  const somaWatchedTime = arr.reduce((total, currentItem) => {
    const itemToSum = currentItem.watchedReplayTime
      ? currentItem.watchedReplayTime
      : 0;
    return total + itemToSum;
  }, 0);

  const mediaWatchedTime = somaWatchedTime / arr.length;

  return Math.floor(mediaWatchedTime);
}

function percentWatchedFullEvent(
  arr: IRegistrantEvent[],
  videoDuration: number
) {
  if (!Array.isArray(arr) || arr.length === 0 || videoDuration === 0) {
    return 0;
  }

  const threshold = videoDuration * 0.95;
  const count = arr.filter((e) => e.watchedTime >= threshold).length;

  return ((count / arr.length) * 100).toFixed(2);
}
function percentWatchedFullReplay(
  arr: IRegistrantEvent[],
  videoDuration: number
) {
  if (!Array.isArray(arr) || arr.length === 0 || videoDuration === 0) {
    return 0;
  }

  const threshold = videoDuration * 0.95;
  const count = arr.filter((e) => e.watchedReplayTime >= threshold).length;

  return ((count / arr.length) * 100).toFixed(2);
}
