import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import {
  IDynamicValues,
  IWhatsappNotification,
  IWhatsappTemplate,
} from "../../providers/notifications/notifications.interface";
import { useTag } from "../../providers/tags";
import { useUser } from "../../providers/users";
import DynamicValuesContainer, {
  calculateExpirationTime,
} from "../DynamicValuesContainer";
import ModalFormBase from "../ModalFormBase";
import NotificationTags from "../NotificationTags";
import { Container } from "./styles";
import HourRelationContainer from "../HoursRelationContainer";
import Loading from "../Loading";
import { FaSearch } from "react-icons/fa";

interface IProps {
  setModalEditWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
  notification: IWhatsappNotification;
}

const ModalEditWhatsapp = ({ setModalEditWhatsapp, notification }: IProps) => {
  const { apiLoading } = useUser();
  const {
    allWhatsappTemplates,
    getAllWhatsappTemplates,
    updateWhatsappNotification,
    sendSampleTestWhatsapp,
  } = useNotification();
  const { session_id } = useParams();
  const [WhatsappId, setWhatsappId] = useState(notification.templateId);
  const { t } = useTranslation();
  const [toRegistrants, setToRegistrants] = useState(notification.to);
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues[]>(
    notification.dynamicValues
  );
  const [searchText, setSearchText] = useState("");
  const [WhatsappTest, setWhatsappTest] = useState("");
  const [isSending, setIsSending] = useState(false);

  const filteredWhatsappTemplates: any[] = allWhatsappTemplates
    ? allWhatsappTemplates.filter(
        (e: any) =>
          e.name && e.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];
  const formSchema = yup
    .object()
    .shape({
      hoursInput: yup.number().typeError(t("field_required")),
      minutes: yup.number().max(60).typeError(t("field_required")),
      hoursExpiration: yup.number().integer().moreThan(-1),
      minutesExpiration: yup.number().integer().moreThan(-1).max(59),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (
      !allWhatsappTemplates ||
      allWhatsappTemplates.some((e: IWhatsappTemplate) =>
        ["received", "pending"].includes(e.status)
      )
    ) {
      getAllWhatsappTemplates(false);
    }
    if (!allTags) {
      getAllTags();
    }
    if (tags.length === 0 && notification.tags && notification.to !== "all") {
      setTags(notification.tags);
    }
    if (
      tagsExclude.length === 0 &&
      notification.tagsExclude &&
      notification.to !== "all"
    ) {
      setTagsExclude(notification.tagsExclude);
    }
  }, [
    allWhatsappTemplates,
    allTags,
    getAllTags,
    notification.tags,
    notification.tagsExclude,
    notification.to,
    tags.length,
    tagsExclude.length,
  ]);

  const onSubmitUpdate = async (data: any) => {
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    delete data.hoursInput;
    delete data.minutes;
    delete data.seconds;
    data.hourRelation = notification.hourRelation;
    if (notification.hourRelation === "on") data.hours = 0;
    data.templateId = WhatsappId;
    data.sessionId = session_id;
    data.enabled = true;
    const template = allWhatsappTemplates.find(
      (e: IWhatsappTemplate) => e.id === WhatsappId
    );
    data.dynamicValues = calculateExpirationTime(dynamicValues);

    if (tags.length > 0) {
      data.tags = tags;
    }
    data.tagsExclude = tagsExclude;
    await updateWhatsappNotification(notification.id, data, template);
    setModalEditWhatsapp(false);
  };
  useEffect(() => {
    if (WhatsappId && allWhatsappTemplates) {
      const dynamicLinks = allWhatsappTemplates.find(
        (e: IWhatsappTemplate) => e.id === WhatsappId
      ).dynamicValues;

      if (WhatsappId !== notification.templateId) {
        dynamicLinks.map((e: IDynamicValues) => {
          e.expirationTime = 0;
          e.redirectLink = "";
          return e;
        });

        setDynamicValues(dynamicLinks);
      } else {
        setDynamicValues(notification.dynamicValues);
      }
    }
  }, [WhatsappId, allWhatsappTemplates]);
  return (
    <ModalFormBase
      title="Whatsapp Notification"
      setModal={setModalEditWhatsapp}
      onSubmit={handleSubmit(onSubmitUpdate)}
      apiLoading={apiLoading}
    >
      <Container>
        <HourRelationContainer
          hourRelation={notification.hourRelation}
          register={register}
          errors={errors}
          hours={notification.hours}
          notificationType="whatsapp"
        />

        <NotificationTags
          tags={tags}
          setTags={setTags}
          tagsExclude={tagsExclude}
          setTagsExclude={setTagsExclude}
          register={register}
          allTags={allTags}
          toRegistrants={toRegistrants}
          setToRegistrants={setToRegistrants}
        />

        <h3 className="WhatsappSettings">Whatsapp Settings</h3>
        {allWhatsappTemplates ? (
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              Select Whatsapp Template
            </InputLabel>

            <Select
              labelId="demo-simple-select-roles"
              id="demo-simple-select-roles"
              required
              MenuProps={{ autoFocus: false }}
              value={WhatsappId}
              onChange={(e) => setWhatsappId(e.target.value)}
              label={"Select Whatsapp Template "}
              onClose={() => setSearchText("")}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaSearch />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filteredWhatsappTemplates &&
                filteredWhatsappTemplates
                  .filter((a: IWhatsappTemplate) => a.status === "approved")
                  .map((e: IWhatsappTemplate) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        ) : (
          <Loading />
        )}
        {!["on"].includes(notification.hourRelation) &&
          allWhatsappTemplates && (
            <DynamicValuesContainer
              addNew={false}
              dynamicValues={dynamicValues}
              setDynamicValues={setDynamicValues}
              hourRelation={notification.hourRelation}
            />
          )}

        {WhatsappId && (
          <div className="testDiv">
            <TextField
              id="standard-WhatsappFrom"
              label="Send test Whatsapp"
              placeholder="+000000000000"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              value={WhatsappTest}
              onChange={(e) => setWhatsappTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={async () => {
                if (!WhatsappTest) {
                  return;
                }
                setIsSending(true);
                await sendSampleTestWhatsapp(WhatsappTest, WhatsappId);
                setIsSending(false);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              disabled={apiLoading || !WhatsappTest || isSending}
              size="small"
            >
              Send
            </Button>
          </div>
        )}
      </Container>
    </ModalFormBase>
  );
};

export default ModalEditWhatsapp;
