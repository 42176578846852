import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import DataLayout from "../components/DataLayout";
import Layout from "../components/Layout";
import ActivationPage from "../pages/ActivationPage";
import AdminUsersPage from "../pages/RegistrantsPage";
import AssistantCreationPage from "../pages/AssistantCreationPage";
import AssistantUpdatePage from "../pages/AssistantUpdatePage";
import AssistantsPage from "../pages/AssistantsPage";
import ErrorPage from "../pages/ErrorPage";
import EventSessionChat from "../pages/EvenSessionChat";
import EventCreation from "../pages/EventCreation";
import EventSessions from "../pages/EventSessions";
import EventSessionCreation from "../pages/EventSessionsCreation";
import EventSessionUpdate from "../pages/EventSessionsUpdate";
import EventUpdate from "../pages/EventUpdate";
import Events from "../pages/Events";
import FindTenant from "../pages/FindTenantPage";
import Home from "../pages/Home";
import Login from "../pages/LoginPage";
import Profile from "../pages/Profile";
import RegisterPage from "../pages/RegisterPage";
import SendPasswordRecoveryPage from "../pages/SendPasswordRecoveryCodePage/index";
import SettingsPage from "../pages/Settings/index";
import UserUpdate from "../pages/UserUpdatePage";
import { useEventSession } from "../providers/eventSessions";
import { useEvent } from "../providers/events";
import { useSalesCopilot } from "../providers/salesCopilot";
import { useTenant } from "../providers/tenant";
import { useUser } from "../providers/users";
import PrivateRoutes from "./PrivateRoutes";
import Analytics from "../pages/Analytics";
import RegistrantsPage from "../pages/RegistrantsPage";
import { useRegistrantEvent } from "../providers/registrantEvents";
import { useTag } from "../providers/tags";
import TagsPage from "../pages/TagPage";
import ChangePassword from "../pages/ChangePassword";

export const AppRoutes = () => {
  const { verifyUserLoggedIn, user, roleAdmin, isLogged } = useUser();
  const { getAllEvents, allEvents } = useEvent();
  const { getAllEventSessions, allEventSessions } = useEventSession();
  const { getAllAssistants, allAssistants } = useSalesCopilot();

  const { getAllTags, allTags } = useTag();
  const {
    getTenant,
    tenant,
    getTenantUsers,
    getTenantByPath,
    tenantByPath,
    allUsers,
    getDashboardData,
    dashboardData,
  } = useTenant();
  const location = useLocation();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (
      !location.pathname.includes("/recovery/") ||
      !location.pathname.includes("/activation/")
    ) {
      verifyUserLoggedIn();
    }
    if (!tenantByPath) {
      getTenantByPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      if (isLogged) {
        verifyUserLoggedIn();
      }
    }, 12 * 60 * 60 * 1000); // Verifica a cada 4 minutos

    return () => clearInterval(tokenCheckInterval);
  }, [isLogged]);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.default_language === "english" ? "en" : "en");
    }
  }, [i18n, user]);
  return (
    <Routes>
      <Route path="/:path" element={<Login />} id="login" />
      <Route path="/:path/register" element={<RegisterPage />} />
      <Route
        element={
          <PrivateRoutes>
            <DataLayout
              key="dashboard2"
              retrieveData={getDashboardData}
              allData={dashboardData}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/dashboard"
          element={
            <PrivateRoutes>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>

      <Route
        element={
          <PrivateRoutes rolesArray={roleAdmin}>
            <DataLayout
              key={"registrants"}
              retrieveData={getTenant}
              allData={tenant}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/registrants"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <RegistrantsPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <PrivateRoutes rolesArray={roleAdmin}>
            <DataLayout
              key={"tags"}
              retrieveData={getAllTags}
              allData={allTags}
            />
          </PrivateRoutes>
        }
      >
        <Route
          path="/:path/tags"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <TagsPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
      <Route
        element={
          <DataLayout
            key={"events"}
            retrieveData={getAllEvents}
            allData={allEvents}
          />
        }
      >
        <Route
          element={
            <DataLayout
              key={"eventsSessions"}
              retrieveData={getAllEventSessions}
              allData={allEventSessions}
            />
          }
        >
          <Route
            path="/:path/events"
            element={
              <PrivateRoutes>
                <Layout>
                  <Events />
                </Layout>
              </PrivateRoutes>
            }
          />
          <Route
            path="/:path/events/create"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <EventCreation />{" "}
                </Layout>
              </PrivateRoutes>
            }
          />
          <Route
            path="/:path/events/:event_id/update"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <EventUpdate />
                </Layout>
              </PrivateRoutes>
            }
          />

          <Route
            path="/:path/events/:event_id/eventsessions"
            element={
              <PrivateRoutes>
                <Layout>
                  <EventSessions />
                </Layout>
              </PrivateRoutes>
            }
          />
          <Route
            path="/:path/events/:event_id/eventsessions/create"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <EventSessionCreation />{" "}
                </Layout>
              </PrivateRoutes>
            }
          />
          <Route
            path="/:path/eventsessions/:session_id/update"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <EventSessionUpdate />
                </Layout>
              </PrivateRoutes>
            }
          />
          <Route
            path="/:path/eventsessions/:session_id/chat"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <EventSessionChat />
                </Layout>
              </PrivateRoutes>
            }
          />

          <Route
            path="/:path/analytics"
            element={
              <PrivateRoutes rolesArray={roleAdmin}>
                <Layout>
                  <Analytics />
                </Layout>
              </PrivateRoutes>
            }
          />
        </Route>
      </Route>

      <Route
        path="/:path/profile"
        element={
          <PrivateRoutes>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoutes>
        }
      />
      <Route path="/:path/changepassword" element={<ChangePassword />} />
      <Route
        path="/:path/profile/update"
        element={
          <PrivateRoutes>
            <Layout>
              <UserUpdate />
            </Layout>
          </PrivateRoutes>
        }
      />

      <Route
        element={
          <DataLayout
            key={"settings"}
            retrieveData={getTenant}
            allData={tenant}
          />
        }
      >
        <Route
          path="/:path/settings"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <SettingsPage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>

      <Route
        element={
          <PrivateRoutes rolesArray={roleAdmin}>
            <DataLayout
              key={"users"}
              retrieveData={getTenantUsers}
              allData={allUsers}
            />
          </PrivateRoutes>
        }
      ></Route>

      <Route path="/:path/*" element={<ErrorPage dashboardLink={true} />} />
      <Route path="/" element={<FindTenant />} />
      <Route path="/:path/activation" element={<ActivationPage />} />
      <Route
        path="/:path/recovery/password"
        element={<SendPasswordRecoveryPage />}
      />
      <Route
        element={
          <DataLayout
            key={"assistants"}
            retrieveData={getAllAssistants}
            allData={allAssistants}
          />
        }
      >
        <Route
          path="/:path/assistants"
          element={
            <PrivateRoutes>
              <Layout>
                {" "}
                <AssistantsPage />{" "}
              </Layout>
            </PrivateRoutes>
          }
        />

        <Route
          path="/:path/assistants/create"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <AssistantCreationPage />
              </Layout>
            </PrivateRoutes>
          }
        />
        <Route
          path="/:path/assistants/:assistant_id/update"
          element={
            <PrivateRoutes rolesArray={roleAdmin}>
              <Layout>
                <AssistantUpdatePage />
              </Layout>
            </PrivateRoutes>
          }
        />
      </Route>
    </Routes>
  );
};
