import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormHelperText, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../providers/notifications";
import { useTenant } from "../../providers/tenant";
const SMPTUpdateForm = () => {
  const { apiLoading } = useUser();
  const { updateIntegrations, tenant } = useTenant();
  const { t } = useTranslation();
  const { sendTestEmail } = useNotification();
  const [emailTest, setEmailTest] = useState("");
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await updateIntegrations({ postmark: data });
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("field_required")).max(128, t("field_max")),
      token: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
      emailFrom: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
      nameFrom: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Postmark</h3>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          defaultValue={
            tenant.integrations.postmark && tenant.integrations.postmark.name
          }
          id="standard-name"
          label={t("name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("name")}
        />
        <TextField
          id="standard-token"
          error={errors.token === undefined ? false : true}
          helperText={errors.token?.message?.toString()}
          defaultValue={
            tenant.integrations.postmark && tenant.integrations.postmark.token
          }
          label="Token"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("token")}
        />

        <TextField
          id="standard-nameFrom"
          error={errors.nameFrom === undefined ? false : true}
          helperText={errors.nameFrom?.message?.toString()}
          defaultValue={
            tenant.integrations.postmark &&
            tenant.integrations.postmark.nameFrom
          }
          label="From(name)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("nameFrom")}
        />
        <TextField
          id="standard-emailFrom"
          error={errors.emailFrom === undefined ? false : true}
          helperText={errors.emailFrom?.message?.toString()}
          defaultValue={
            tenant.integrations.postmark &&
            tenant.integrations.postmark.emailFrom
          }
          label="From (Email address)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("emailFrom")}
        />
        {tenant.integrations.postmark && (
          <div className="testDiv">
            <TextField
              id="standard-emailFrom"
              label="Send test email"
              placeholder="youremail@email.com"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              type="email"
              value={emailTest}
              onChange={(e) => setEmailTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={() => {
                if (!emailTest) {
                  return;
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(emailTest)) {
                  return;
                }
                sendTestEmail(emailTest);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              disabled={apiLoading}
              size="small"
            >
              Send
            </Button>
          </div>
        )}
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              save
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default SMPTUpdateForm;
