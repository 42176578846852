import React, { useState, useCallback, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container } from "./styles";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const modules = {
  toolbar: [
    [{ font: fontValues }, { size: sizeValues }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export const codes = [
  {
    tag: "{{ATTENDEE_FIRST_NAME}}",
    value: "firstName",
    description: "First name of attendee",
    example: "João",
  },
  {
    tag: "{{ATTENDEE_EMAIL}}",
    value: "email",
    description: "Email of attendee",
    example: "joao@gmail.com",
  },
  {
    tag: "{{EVENT_SESSION_NAME}}",
    value: "name",
    description: "Headline of session from event",
    example: "The Cloud Bootcamp - Terraform",
  },
  {
    tag: "{{JOIN_LIVE_LINK}}",
    value: "joinUrl",
    description: "Event session link",
    example: "https://event.eventspro.com/event/example",
  },
  {
    tag: "{{REPLAY_LINK}}",
    value: "replayUrl",
    description: "Event session replay link",
    example: "https://event.eventspro.com/event/replay/example",
  },
  {
    tag: "{{EVENT_SESSION_DATE}}",
    value: "date",
    description: "Event session date",
    example: "01-03-2024",
  },
  {
    tag: "{{EVENT_SESSION_TIME}}",
    value: "date",
    description: "Event session time",
    example: "11:00 PM",
  },
  {
    tag: "{{EVENT_SESSION_TIMEZONE}}",
    value: "sessionSchedule.timezone",
    description: "Event timezone",
    example: "America/Sao_Paulo",
  },
  {
    tag: "{{UTM_CONTENT}}",
    value: "utm_content",
    description: "Utm content",
    example: "Utm content",
  },
  {
    tag: "{{UTM_SOURCE}}",
    value: "utm_source",
    description: "utm_source",
    example: "facebook",
  },
  {
    tag: "{{UTM_CAMPAIGN}}",
    value: "utm_campaign",
    description: "utm_campaign",
    example: "utm_campaign",
  },
  {
    tag: "{{UTM_TERM}}",
    value: "utm_term",
    description: "UTM_TERM",
    example: "UTM_TERM",
  },
  {
    tag: "{{UTM_MEDIUM}}",
    value: "utm_medium",
    description: "utm_medium",
    example: "utm_medium",
  },
  {
    tag: "{{CAMPAIGN}}",
    value: "campaign",
    description: "campaign",
    example: "campaign",
  },
  {
    tag: "{{LINK}}",
    value: "",
    description: "LINK (Whatsapp)",
    example: "https://event.eventspro.com/event/replay/example",
  },
  {
    tag: "{{EXPIRABLE_LINK}}",
    value: "",
    description: "Expirable LINK (Whatsapp)",
    example: "https://event.eventspro.com/event/replay/example",
  },
  {
    tag: "{{PARAM1}}",
    value: "param1",
    description: "param1",
    example: "param1",
  },
  {
    tag: "{{PARAM2}}",
    value: "param2",
    description: "param2",
    example: "param2",
  },
];

export default function HtmlEditor({
  valueChanged,
  setValueContent,
  valueContent,
}) {
  const quillRef = useRef(null);

  const handleChange = (content, delta, source, editor) => {
    setValueContent(content);
    valueChanged(content);
  };

  return (
    <Container>
      <div className="editorContainer" data-text-editor="name">
        <ReactQuill
          ref={quillRef}
          value={valueContent}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          theme="snow"
          bounds={`[data-text-editor="name"]`}
        />
      </div>
      <div className="shortCodesContainer">
        <h5>Short Codes</h5>
        {codes.map(
          (e) =>
            e.value && (
              <div
                onClick={() => {
                  const editor = quillRef.current.getEditor();
                  const range = editor.getSelection();
                  if (range) {
                    editor.insertText(range.index, e.tag);
                  } else {
                    const length = editor.getLength();
                    editor.insertText(length - 1, e.tag);
                  }
                }}
              >
                <p>
                  <b>{e.tag}</b>
                </p>
                <span>{e.description}</span>
              </div>
            )
        )}
      </div>
    </Container>
  );
}
