import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useTag } from "../../providers/tags";
import { useUser } from "../../providers/users";
import { Container } from "./styles";

interface IPropps {
  setModalUpdateTag: React.Dispatch<React.SetStateAction<boolean>>;

  item: any;
}

const ModalUpdateTag = ({
  setModalUpdateTag,

  item,
}: IPropps) => {
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const { editTag } = useTag();
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("field_max")),
      description: yup.string().max(128, t("field_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = (data: FormValues) => {
    editTag(item.id, data);
    setModalUpdateTag(false);
  };

  return (
    <Container>
      <div className="modalContainer">
        <form>
          <div className="modalHeader">
            <h3>Update Tag</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalUpdateTag(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          </div>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            defaultValue={item.name}
            label="Name"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />
          <TextField
            error={errors.description === undefined ? false : true}
            helperText={errors.description?.message?.toString()}
            id="standard-description"
            defaultValue={item.description}
            label="Description"
            type="description"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("description")}
          />
        </form>
      </div>
    </Container>
  );
};

export default ModalUpdateTag;
