import { useState } from "react";
import { IoCreateOutline } from "react-icons/io5";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEvent } from "../../providers/events";
import PageHeader from "../pageHeader";
const EventCreationForm = () => {
  const { apiLoading } = useUser();
  const { createEvent } = useEvent();
  const navigate = useNavigate();
  const [language, setLanguage] = useState("english");
  const [eventType, setEventType] = useState("unique");
  const { path } = useParams();
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  const handleChangeType = (event: any) => {
    setEventType(event.target.value);
  };
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await createEvent(data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <PageHeader
        title={t("new_event")}
        description={`${t("fill_create")} ${t("event")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("name")}
        />
        <TextField
          id="standard-description"
          error={errors.description === undefined ? false : true}
          helperText={errors.description?.message?.toString()}
          label={t("description")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("description")}
        />

        <FormControl>
          <InputLabel id="demo-simple-select-label">{t("language")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            value={language}
            label={t("language")}
            {...register("language")}
            onChange={handleChange}
          >
            <MenuItem value={"english"}>{t("english")}</MenuItem>
            <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
          </Select>
        </FormControl>

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              {t("create")}
            </Button>
            <Button
              sx={{ ml: 1, borderRadius: "25px" }}
              onClick={() => navigate("/" + path + "/events")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default EventCreationForm;
