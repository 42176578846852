import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;

  border-top: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .assistantContainer {
    display: flex;
    align-items: center;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
  }
  .assistantInfo {
    padding: 15px;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 5px;
    max-height: 250px;
  }
  .assistantItemHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .assistantName {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: capitalize;
  }

  .assistantDescription {
    color: grey;
  }
  .assistantLink {
    margin: 5px;
    position: relative;
    z-index: 1;
    display: flex;
    gap: 3px;
  }
  .assistantLink button:hover,
  .assistantLink input:hover {
    cursor: pointer;
    opacity: 1;
  }
  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
    .assistantInfo {
      flex-grow: 2;
    }
    .assistantLink {
      flex-direction: column;
    }
  }
  @media (min-width: 1100px) {
    img {
      max-height: 275px;
    }
  }
`;
