import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import EventSessionIntegrationForm from "../EventSessionIntegrationForm";
import EventSessionNotificationForm from "../EventSessionNotification";
import EventSessionReplayForm from "../EventSessionReplayForm";
import EventSessionScheduleForm from "../EventSessionScheduleForm";
import EventSessionSettingsForm from "../EventSessionSettingsForm";
import EventSessionUpdateLiveForm from "../EventSessionUpdateLive";
import WidgetSettingsForm from "../EventSessionWidgetForm";

export default function EventSessionCreationStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [eventSession, setEventSession] = React.useState<IEventSession | null>(
    null
  );
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const { t } = useTranslation();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const steps = [
    {
      name: t("settings"),
      component: (
        <EventSessionSettingsForm
          key={"settingsCreation"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
        />
      ),
    },
    {
      name: t("schedules"),
      component: (
        <EventSessionScheduleForm
          key={"settingsCreation"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
          handleBack={handleBack}
        />
      ),
    },
    {
      name: "Integrations",
      component: (
        <EventSessionIntegrationForm
          key={"settingsCreation"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
          handleBack={handleBack}
        />
      ),
    },
    {
      name: t("notifications"),
      component: (
        <EventSessionNotificationForm
          key={"settingsLive"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
          handleBack={handleBack}
        />
      ),
    },
    ...(eventSession?.sessionNumber === 1
      ? [
          {
            name: "Widget",
            component: (
              <WidgetSettingsForm
                key={"widgetStepper"}
                eventSession={eventSession}
                setEventSession={setEventSession}
                handleComplete={handleComplete}
                handleBack={handleBack}
              />
            ),
          },
        ]
      : []),
    {
      name: t("live"),
      component: (
        <EventSessionUpdateLiveForm
          key={"settingsLive"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
          handleBack={handleBack}
        />
      ),
    },
    {
      name: "Replay",
      component: (
        <EventSessionReplayForm
          key={"settingsReplay"}
          eventSession={eventSession}
          setEventSession={setEventSession}
          handleComplete={handleComplete}
          handleBack={handleBack}
        />
      ),
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label.name + index} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label.name}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          {steps[activeStep].component}
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleComplete} sx={{ mr: 1 }}>
              Next
            </Button>
          </Box> */}
        </React.Fragment>
      </div>
    </Box>
  );
}
