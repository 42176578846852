import { Button } from "@mui/material";
import { Container } from "./styles";

interface ModalFormBaseProps {
  title: string;
  children: React.ReactNode;
  setModal: (arg0: boolean) => void;
  onSubmit: any;
  apiLoading: boolean;
}

const ModalFormBase = ({
  title,
  children,
  setModal,
  onSubmit,
  apiLoading,
}: ModalFormBaseProps) => {
  return (
    <Container>
      <div className="modalContainer">
        <form className="formModal" onSubmit={onSubmit}>
          <div className="modalHeader">
            <h3>{title}</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                type="submit"
                onClick={onSubmit}
              >
                Save
              </Button>
            </div>
          </div>
          {children}
        </form>
      </div>
    </Container>
  );
};

export default ModalFormBase;
