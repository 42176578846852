import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  gap: 25px;
  flex-direction: column;
  .testDiv {
    display: flex;
  }
`;
