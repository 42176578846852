import * as yup from "yup";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useEffect, useState } from "react";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import { useTenant } from "../../providers/tenant";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
}

const SettingsEventSessionForm = ({
  eventSession,
  setEventSession,
  handleComplete,
}: IProps) => {
  const navigate = useNavigate();
  const { path, event_id } = useParams();
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const { createEventSession, editEventSession } = useEventSession();
  const [defaultValues, setDefaultValues] = useState<any>({
    liveType: "HOST",
    // Inicialize outros campos com valores padrão
  });
  const { tenant, getTenant } = useTenant();
  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, [tenant]);

  const formSchema = yup.object().shape({
    name: yup.string().required(t("name_required")).max(128, t("name_max")),
    title: yup.string().required(t("field_required")).max(128, t("field_max")),
    description: yup
      .string()
      .required(t("description_required"))
      .max(128, t("description_max")),
    liveType: yup
      .string()
      .required(t("field_required"))
      .oneOf(["REDIRECT", "HOST"]),
    liveRedirectLink: yup.string().when("liveType", {
      is: "REDIRECT",
      then: yup.string().required(t("field_required")).url(t("field_max")),
      otherwise: yup.string().notRequired(),
    }),
    videoUrl: yup.string().when("liveType", {
      is: "HOST",
      then: yup
        .string()
        .required(t("video_url_required"))
        .url(t("video_url_type")),
      otherwise: yup.string().notRequired(),
    }),
    hours: yup
      .number()
      .typeError(t("field_required"))
      .required(t("field_required"))
      .max(10),

    minutes: yup
      .number()
      .typeError(t("field_required"))
      .required(t("field_required"))
      .max(60),

    seconds: yup
      .number()
      .typeError(t("field_required"))
      .required(t("field_required"))
      .max(60),
  });

  type FormValues = {
    name: string;
    title: string;
    description: string;
    liveType: "REDIRECT" | "HOST";
    liveRedirectLink?: string;
    videoUrl?: string;
    hours?: number;
    minutes?: number;
    seconds?: number;
    videoDuration?: number;
    eventId: string;
  };

  const onSubmit = async (data: FormValues) => {
    data.videoDuration =
      (data.hours || 0) * 3600 + (data.minutes || 0) * 60 + (data.seconds || 0);

    delete data.hours;
    delete data.minutes;
    delete data.seconds;
    data.eventId = event_id!;

    if (eventSession) {
      // Update existing session
      const updatedEvent = await editEventSession(eventSession.id, data);
      setEventSession(updatedEvent);
    } else {
      // Create new session
      const newEvent = await createEventSession(data);
      setEventSession(newEvent);
    }
    handleComplete();
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const watchLiveType = watch("liveType");

  useEffect(() => {
    if (eventSession) {
      const newDefaultValues = {
        liveType: eventSession.liveType || "HOST",
        // Adicione outros campos do eventSession aqui
      };
      setDefaultValues(newDefaultValues);
      reset(newDefaultValues); // Isso irá redefinir o formulário com os novos valores padrão
    }
  }, [eventSession, reset]);

  return (
    <Container>
      {eventSession && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>{t("general_settings")}</h3>
          <TextField
            error={!!errors.name}
            helperText={errors.name?.message}
            id="standard-name"
            label="Internal name"
            variant="filled"
            defaultValue={eventSession && eventSession.name}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />
          <TextField
            id="standard-title"
            error={!!errors.title}
            helperText={errors.title?.message}
            label={t("title")}
            variant="filled"
            defaultValue={eventSession && eventSession.title}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("title")}
          />
          <TextField
            id="standard-description"
            error={!!errors.description}
            helperText={errors.description?.message}
            label={t("description")}
            variant="filled"
            defaultValue={eventSession && eventSession.description}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("description")}
          />

          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <InputLabel id="live-type-label">Live type</InputLabel>
            <Controller
              name="liveType"
              control={control}
              defaultValue={(eventSession && eventSession.liveType) || "HOST"}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="live-type-label"
                  label={"Live type"}
                  variant="filled"
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <MenuItem value="REDIRECT">Redirect</MenuItem>
                  <MenuItem value="HOST">Host</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          {watchLiveType === "REDIRECT" && (
            <TextField
              error={!!errors.liveRedirectLink}
              helperText={errors.liveRedirectLink?.message}
              label={"Live redirect"}
              variant="filled"
              defaultValue={eventSession && eventSession.liveRedirectLink}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("liveRedirectLink")}
            />
          )}

          {watchLiveType === "HOST" && (
            <>
              <h3>{t("video_configuration")}</h3>
              <TextField
                id="standard-videoUrl"
                error={!!errors.videoUrl}
                helperText={errors.videoUrl?.message}
                label={t("video_url")}
                variant="filled"
                defaultValue={eventSession && eventSession.videoUrl}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("videoUrl")}
              />{" "}
            </>
          )}
          <h3>{t("video_duration")}</h3>
          <div className="durationDiv">
            <TextField
              id="standard-hour"
              error={!!errors.hours}
              helperText={errors.hours?.message}
              label={t("hours")}
              variant="filled"
              type="number"
              defaultValue={
                eventSession && eventSession.videoDuration
                  ? Math.floor(eventSession.videoDuration / 3600)
                  : 0
              }
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("hours")}
            />
            <TextField
              id="standard-minutes"
              error={!!errors.minutes}
              helperText={errors.minutes?.message}
              label={t("minutes")}
              type="number"
              variant="filled"
              defaultValue={
                eventSession && eventSession.videoDuration
                  ? Math.floor((eventSession.videoDuration % 3600) / 60)
                  : 0
              }
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("minutes")}
            />
            <TextField
              id="standard-seconds"
              error={!!errors.seconds}
              helperText={errors.seconds?.message}
              label={t("seconds")}
              type="number"
              variant="filled"
              defaultValue={
                eventSession && eventSession.videoDuration
                  ? eventSession.videoDuration % 60
                  : 0
              }
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("seconds")}
            />
          </div>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              variant="contained"
              disabled={apiLoading}
              sx={{ mr: 1, borderRadius: "25px" }}
            >
              {eventSession ? "Save changes" : "Save and next"}
            </Button>
          </Box>
        </form>
      )}
    </Container>
  );
};

export default SettingsEventSessionForm;
