import AnalyticsContainer from "../../components/AnalyticsContainer";

const Analytics = () => {
  return (
    <>
      <AnalyticsContainer />{" "}
    </>
  );
};

export default Analytics;
