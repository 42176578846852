import styled from "styled-components";

export const Container = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    background-color: ${({ theme }) => theme.colors.background};
    width: 25%;
    box-shadow: rgba(3, 3, 3, 0.1) 2px 0px 10px;

    max-width: 220px;
    .sideBarFix {
      position: fixed;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 20%;
      max-width: 220px;
    }

    .navBarLogo {
      margin-top: 30px;
      width: 150px;
      background-color: none;
      margin-bottom: 20px;
      align-self: center;
    }

    .navBarLinksContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow-y: auto;
      min-height: 80vh;
      flex-direction: column;
    }

    .navBarLinks {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      margin-top: 20px;
    }
    .navBarLinks > p {
      color: black;
      padding-left: 15px;
      height: 38px;
      font-size: 0.9rem;
      display: flex;
      font-weight: 700;
      align-items: center;
      text-align: start;
      width: 100%;
    }
    .dropdownLink {
      color: ${({ theme }) => theme.colors.black};
      /* padding-left: 15px; */
      font-size: 0.9rem;
      display: flex;
      font-weight: 700;
      text-align: start;
      flex-direction: column;
      width: 100%;
    }
    .dropdownLink > div {
      display: flex;
      flex-direction: column;
    }
    .dropdownLink > div > p,
    .dropdownLink > p {
      display: flex;
      align-items: center;
      height: 38px;
      width: 100%;
      padding-left: 15px;
    }
    .dropdownLink > div > p {
      padding-left: 30px;
    }
    .navBarLinks > p:hover,
    .dropdownLink > div > p:hover,
    .dropdownLink > p:hover {
      color: ${({ theme }) => theme.colors.black};
      background-color: rgba(57, 57, 57, 0.1);
      cursor: pointer;
    }

    .linkActive {
      color: ${({ theme }) => theme.colors.black} !important;
      background-color: rgba(57, 57, 57, 0.1);
      cursor: pointer;
    }

    .navBarLinks > p > svg,
    .dropdownLink > p > svg,
    .dropdownLink > div > p > svg {
      margin-right: 10px;
    }
  }
  @media (min-width: 1100px) {
    .sideBarFix {
    }
    .navBarUser > p {
      font-size: 1rem;
    }
    .navBarLinks > p {
      font-size: 0.875rem;
      height: 38px;
    }
  }

  #animationOpen {
    -webkit-animation: conditionalOpen 0.1s normal forwards ease-in-out;
    -moz-animation: conditionalOpen 0.1s normal forwards ease-in-out;
    animation: conditionalOpen 0.1s normal forwards ease-in-out;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  #animationClose {
    -webkit-animation: conditionalClose 0.1s normal forwards ease-in-out;
    -moz-animation: conditionalClose 0.1s normal forwards ease-in-out;
    animation: conditionalClose 0.1s normal forwards ease-in-out;
    -webkit-transform-origin: 100% 0%;
    -moz-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  // Opening and closing motion for conditional fieldset
  @-webkit-keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-moz-keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  @-webkit-keyframes conditionalClose {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
  @-moz-keyframes conditionalClose {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
  @keyframes conditionalClose {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }

  @media (min-width: 1100px) {
    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(44, 48, 48, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(44, 48, 48, 0.4);
    }
  }
`;
