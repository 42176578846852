import styled from "styled-components";

export const Container = styled.p`
  .openAiMessage {
    color: ${({ theme }) => theme.colors.primary};
  }
  .openAiMessage:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
