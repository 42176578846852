import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineLink } from "react-icons/ai";
import { IoMdCart } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useEventSession } from "../../providers/eventSessions";
import {
  IEventSession,
  ILiveContent,
} from "../../providers/eventSessions/eventsSessions.interface";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { IAssistants } from "../../providers/salesCopilot/salesCopilot.interface";
import { useUser } from "../../providers/users";
import { ChatHistoryContainer } from "../ChatHistoryContainer";
import { formatTime } from "../EventSessionList";
import Loading from "../Loading";
import ModalAddLiveContent from "../ModalAddLiveContent";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import ModalUpdateLiveContent from "../ModalUpdateLiveContent";
import TitleWithRefreshIcon from "../TitleWithRefreshIcon";
import { Container } from "./styles";
interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}
const EventSessionUpdateLiveForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const { editEventSession, deleteLiveContent, gettAllProgrammedChats } =
    useEventSession();
  const navigate = useNavigate();
  const { path, event_id } = useParams();
  const { t } = useTranslation();
  const { getAllAssistants, allAssistants } = useSalesCopilot();
  const [chatHistory, setChatHistory] = useState(
    (eventSession && eventSession.saveChatHistory) || false
  );
  const [chatBox, setChatBox] = React.useState(
    (eventSession && eventSession.liveChatBox) || "enabled"
  );
  const [assistant, setAssistant] = useState(
    (eventSession && eventSession.assistantId) || ""
  );
  const [checked, setChecked] = React.useState(
    (eventSession && eventSession.liveIcon) || true
  );
  const [aiComments, setAIComments] = React.useState(
    (eventSession && String(eventSession.aIMessagePreApproval)) || true
  );
  const [dynamicAttendance, setDynamicAttendance] = useState(
    (eventSession && eventSession.dynamicAttendance) || true
  );
  const [aiModerator, setAIModerator] = React.useState(
    (eventSession && eventSession.enableAIModerator) || true
  );
  const [modalLiveContent, setModalAddLiveContent] = useState(false);
  const [modalUpdateLiveContent, setModalUpdateLiveContent] = useState(false);
  const [modalDeleteLiveContent, setModalDeleteLiveContent] = useState(false);
  const [liveContentToChange, setLiveContentToChange] =
    useState<ILiveContent | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { apiLoading } = useUser();
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const handleChangeAIModerator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    setAIModerator((event.target as HTMLInputElement).checked);
  };
  const handleChangeAssistant = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAssistant((event.target as HTMLInputElement).value);
  };
  const handleChangeChatBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatBox((event.target as HTMLInputElement).value);
  };

  const handleChangeDynamicAttendance = (event: any) => {
    setDynamicAttendance(event.target.value);
  };
  const handleChangeLiveAction = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    setChecked(event.target.checked);
  };

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    const size = Object.keys(data).length;
    if (size === 1 && data.fileInput.length === 0) {
      handleComplete();
    }

    data.dynamicAttendance = dynamicAttendance;
    data.dynamicAttendanceNumber = Number(data.dynamicAttendanceNumber);
    data.enableAIModerator = aiModerator;
    data.liveIcon = checked;
    data.liveChatBox = chatBox;
    data.saveChatHistory = chatHistory;
    data.aIMessagePreApproval = aiComments == "true" ? true : false;
    if (!eventSession) {
      toast.error("Something got wrong, please try again.");
      navigate(`/${path}/events/${event_id}/eventsessions`);
      return;
    }

    const response = await editEventSession(eventSession.id, data);

    setEventSession(response);
    handleComplete();
  };

  const handleChangeChatHistory = (event: any) => {
    setChatHistory(event.target.checked);
  };
  const handleChangeAIComments = (event: any) => {
    setAIComments(event.target.value);
  };
  useEffect(() => {
    if (!allAssistants) {
      getAllAssistants();
    }
    if (eventSession && !eventSession.programmedChats) {
      gettAllProgrammedChats(eventSession.id);
    }
  }, []);
  const formSchema = yup
    .object()
    .shape({
      actionText: yup
        .string()
        .required(t("name_required"))
        .max(128, t("name_max")),
      fileInput: yup.mixed().notRequired().nullable(),
      redirectLink: yup.string().url("Redirect link must be a valid url"),

      dynamicAttendanceNumber: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  return (
    <>
      <Container>
        {eventSession && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TitleWithRefreshIcon title={"Video play"} />

            <TextField
              error={errors.actionText === undefined ? false : true}
              helperText={errors.actionText?.message?.toString()}
              id="standard-actionText"
              label="Call to action"
              variant="filled"
              defaultValue={eventSession && eventSession.actionText}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("actionText")}
            />
            <TextField
              id="standard-redirectLink"
              error={errors.redirectLink === undefined ? false : true}
              helperText={errors.redirectLink?.message?.toString()}
              label={"Redirect link"}
              defaultValue={eventSession && eventSession.redirectLink}
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("redirectLink")}
            />
            <p>Image</p>
            <Button
              component="label"
              variant="outlined"
              startIcon={<AiOutlineLink />}
            >
              Upload Image
              <input
                {...register("fileInput")}
                className="inputNone"
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                onInput={handleFileUpload}
              />
            </Button>
            <p className="errosForm">
              {errors.fileInput && errors.fileInput.message?.toString()}
            </p>
            <Box>{file?.name}</Box>
            <div className="videoPlaySettings">
              {" "}
              <div>
                <span>Live Icon</span>
                <Switch
                  checked={checked}
                  onChange={handleChangeLiveAction}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <FormControl>
                <InputLabel id="timezone-label">Dynamic attendance</InputLabel>
                <Select
                  labelId="timezone-label"
                  id="demo-simple-select"
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    "& fieldset": { border: "none" },
                    backgroundColor: "#FFF",
                    width: 442,
                    mr: 2,
                  }}
                  value={dynamicAttendance}
                  label="Dynamic attendance"
                  {...register("dynamicAttendance")}
                  onChange={handleChangeDynamicAttendance}
                >
                  <MenuItem value={true as any}>True</MenuItem>
                  <MenuItem value={false as any}>False</MenuItem>
                </Select>
              </FormControl>
              <div>
                <TextField
                  error={
                    errors.dynamicAttendanceNumber === undefined ? false : true
                  }
                  helperText={errors.dynamicAttendanceNumber?.message?.toString()}
                  id="standard-dynamicAttendanceNumber"
                  label="Attendance"
                  variant="filled"
                  defaultValue={
                    eventSession && eventSession.dynamicAttendanceNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                  {...register("dynamicAttendanceNumber")}
                />{" "}
                Attendees
              </div>
            </div>
            <h3>Live Content</h3>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {/* <Box sx={{ flex: "1 1 auto" }} /> */}
              <Button
                onClick={() => setModalAddLiveContent(true)}
                variant="contained"
                sx={{ mr: 1, borderRadius: "25px" }}
              >
                Add Live Content
              </Button>
            </Box>
            <div className="liveContentContainer">
              {eventSession && eventSession.liveContents ? (
                eventSession.liveContents
                  .filter((e) => e.liveOrReplay === "live")
                  .map((e: any) => (
                    <div key={e.id} className="liveContent">
                      <div>
                        <h5>
                          <IoMdCart width={30} height={30} /> {e.name}
                        </h5>
                        <p>Start offer: {formatTime(e.startOffer)}</p>
                        <p>End offer: {formatTime(e.endOffer)}</p>
                      </div>
                      <div className="liveContentActions">
                        <AiOutlineEdit
                          onClick={() => {
                            setModalUpdateLiveContent(true);
                            setLiveContentToChange(e);
                          }}
                        />
                        <MdDelete
                          onClick={() => {
                            setModalDeleteLiveContent(true);
                            setLiveContentToChange(e);
                          }}
                        />
                      </div>
                    </div>
                  ))
              ) : (
                <></>
              )}
            </div>
            <div className="chatSettings">
              <div className="advancedSettings">
                <h3>Chat basic settings</h3>
                <div>
                  <div>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Live chat box
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={chatBox}
                        onChange={handleChangeChatBox}
                      >
                        <FormControlLabel
                          value="enabled"
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label="Disabled"
                        />
                        <FormControlLabel
                          value="questionBox"
                          control={<Radio />}
                          label="Display question box"
                        />
                      </RadioGroup>
                    </FormControl>{" "}
                  </div>
                  <div>
                    <span>Save chat history</span>
                    <Switch
                      checked={chatHistory}
                      onChange={handleChangeChatHistory}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </div>
              </div>

              <div className="advancedSettings">
                <h3>Chat ai settings</h3>
                <div>
                  <div>
                    <div>
                      <span>Enable AI Moderator?</span>
                      <Switch
                        checked={aiModerator}
                        onChange={handleChangeAIModerator}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                    <div>
                      {allAssistants && (
                        <FormControl>
                          <InputLabel id="timezone-label">
                            AI Moderator
                          </InputLabel>
                          <Select
                            labelId="timezone-label"
                            id="demo-simple-select"
                            sx={{
                              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                              "& fieldset": { border: "none" },
                              backgroundColor: "#FFF",
                              width: 442,
                              mr: 2,
                            }}
                            value={assistant}
                            label="AI Moderator"
                            {...register("assistantId")}
                            //@ts-ignore
                            onChange={handleChangeAssistant}
                          >
                            {allAssistants.map((e: IAssistants) => (
                              <MenuItem value={e.id} key={e.id}>
                                {e.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Enable AI-generated responses pre-approval?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={aiComments}
                        onChange={handleChangeAIComments}
                      >
                        <FormControlLabel
                          value={true as any}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false as any}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>{" "}
                  </div>
                </div>
              </div>
            </div>
            {eventSession &&
              (!eventSession.programmedChats && apiLoading ? (
                <Loading />
              ) : (
                <ChatHistoryContainer
                  programmedChats={eventSession.programmedChats}
                  eventSession={eventSession}
                />
              ))}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                onClick={handleBack}
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                disabled={apiLoading}
                variant="contained"
                sx={{ mr: 1, borderRadius: "25px" }}
              >
                Save & Next
              </Button>
            </Box>
          </form>
        )}
      </Container>
      {modalLiveContent && (
        <ModalAddLiveContent
          setModalAddLiveContent={setModalAddLiveContent}
          liveOrReplay="live"
        />
      )}
      {modalUpdateLiveContent && liveContentToChange && (
        <ModalUpdateLiveContent
          setModalUpdateLiveContent={setModalUpdateLiveContent}
          liveContent={liveContentToChange}
        />
      )}
      {modalDeleteLiveContent && liveContentToChange && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalDeleteLiveContent}
          deleteFunction={deleteLiveContent}
          deleteId={liveContentToChange.id}
        />
      )}
    </>
  );
};

export default EventSessionUpdateLiveForm;
