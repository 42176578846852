import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  gap: 25px;
  flex-direction: column;

  .suggestedShortcodes {
    color: #767676;
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
  }
  .testDiv {
    display: flex;
  }
`;
