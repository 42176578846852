import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import ModalFormBase from "../ModalFormBase";
interface IProps {
  setModalUpdateArrayProgrammedChats: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  chatIds: GridRowId[];
  setChatIds: any;
}
const ModalUpdateArrayProgrammedChats = ({
  setModalUpdateArrayProgrammedChats,
  chatIds,
  setChatIds,
}: IProps) => {
  const { apiLoading } = useUser();
  const { updateMultipleProgrammedChats } = useEventSession();
  const [approved, setApproved] = useState(true);
  const handleChangeApproved = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setApproved((event.target as HTMLInputElement).value);
  };
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    await updateMultipleProgrammedChats(chatIds, approved);
    setModalUpdateArrayProgrammedChats(false);
    setChatIds([]);
  };

  return (
    <ModalFormBase
      title="Update Programmed chats"
      setModal={setModalUpdateArrayProgrammedChats}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <FormControl>
        <InputLabel id="timezone-label">Approved</InputLabel>
        <Select
          labelId="timezone-label"
          id="demo-simple-select"
          required
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            "& fieldset": { border: "none" },
            backgroundColor: "#FFF",

            mr: 2,
          }}
          value={approved}
          label="Approved"
          {...register("approved")}
          //@ts-ignore
          onChange={handleChangeApproved}
        >
          <MenuItem value={true as any} key={"approved"}>
            Yes
          </MenuItem>

          <MenuItem value={false as any} key={"not_approved"}>
            No
          </MenuItem>
        </Select>
      </FormControl>
    </ModalFormBase>
  );
};

export default ModalUpdateArrayProgrammedChats;
