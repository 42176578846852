import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import Footer from "../Footer";

const LoginForm = () => {
  const { Login, apiLoading } = useUser();
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formSchema = yup
    .object()
    .shape({
      email: yup
        .string()
        .required(t("email_required"))
        .email(t("email_invalid")),
      password: yup.string().required(t("password_required")),
    })
    .required();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await Login(data);
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <div className="loginContainer">
        <h1>Login</h1>
        <TextField
          error={errors.email === undefined ? false : true}
          helperText={errors.email?.message?.toString()}
          id="standard-email"
          label={t("email")}
          autoComplete="email"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("email")}
        />
        <TextField
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message?.toString()}
          id="standard-password"
          label={t("password")}
          type={showPassword ? "text" : "password"}
          variant="filled"
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          autoComplete="current-password"
          {...register("password")}
        />
        <Button
          variant="text"
          className="registerRedirect"
          onClick={() => navigate(`/${path}/recovery/password`)}
          size="large"
        >
          {t("forgot_password")}
        </Button>
        {apiLoading ? (
          <Loading />
        ) : (
          <Button variant="contained" type="submit" size="large">
            {t("singin")}
          </Button>
        )}
        {/* 
        <p
          className="createAccount"
          onClick={() => navigate(`/${path}/register`)}
        >
          {t("create_account")}
        </p> */}
      </div>
    </Container>
  );
};

export default LoginForm;
