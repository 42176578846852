import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import * as yup from "yup";
import { useSalesCopilot } from "../../providers/salesCopilot";
import {
  IAssistantFile,
  IAssistants,
} from "../../providers/salesCopilot/salesCopilot.interface";
import { useUser } from "../../providers/users";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import ModalUpdateAiTrainingFile from "../ModalUpdateAiTrainingFile/index";
import { Container } from "./styles";
import { AiOutlineLink } from "react-icons/ai";
interface IProps {
  setModalUpdateAssistant: React.Dispatch<React.SetStateAction<boolean>>;
  assistant: IAssistants;
}
const ModalUpdateAssistant = ({
  setModalUpdateAssistant,
  assistant,
}: IProps) => {
  const { apiLoading } = useUser();
  const { updateAssistant, deleteAssistantFile } = useSalesCopilot();

  const [modalUpdateAiTrainingFile, setModalUpdateAiTrainingFile] =
    useState(false);

  const [modalConfirmFileDeletion, setModalConfirmFileDeletion] =
    useState(false);

  const [model, setModel] = useState(assistant.gptModel);
  const { t } = useTranslation();
  const [assistantProfileFile, setAssistantProfileFile] = useState<
    File[] | null
  >(null);
  const [file, setModalFile] = useState<IAssistantFile | null>(null);

  const handleAssistantProfileFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files;

    setAssistantProfileFile(Object.values(file));
  };

  const handleChange = (event: any) => {
    setModel(event.target.value);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    console.log(data);
    removeEmptyFields(data);

    await updateAssistant(assistant.id, data);
    setModalUpdateAssistant(false);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      chatName: yup
        .string()
        .required(t("name_required"))
        .max(128, t("name_max")),
      profanityFilter: yup.string(),
      triggerWords: yup.string(),
      responseDelay: yup
        .number()
        .nullable()
        .min(0)
        .max(29)
        .transform((_, val) => (val ? Number(val) : null)),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      model: yup.string().max(128, "Model deve ter menos de 128 caractéres. "),
      instructions: yup.string().required(t("instructions_required")),
      assistantProfileFile: yup.mixed(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <div className="modalContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modalHeader">
              <h3>Update Assistant</h3>
              <div className="modalButtonsHeader">
                <Button
                  variant="outlined"
                  size="large"
                  disabled={apiLoading}
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => setModalUpdateAssistant(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  disabled={apiLoading}
                  sx={{ mr: 1, borderRadius: "25px" }}
                  type="submit"
                >
                  update
                </Button>
              </div>
            </div>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              defaultValue={assistant.name}
              label={t("name")}
              variant="standard"
              {...register("name")}
            />
            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              defaultValue={assistant.description}
              variant="standard"
              {...register("description")}
            />
            <TextField
              id="standard-instructions"
              error={errors.instructions === undefined ? false : true}
              helperText={errors.instructions?.message?.toString()}
              label={t("instructions")}
              defaultValue={assistant.instructions}
              multiline
              variant="standard"
              {...register("instructions")}
            />
            <TextField
              id="standard-chatName"
              error={errors.chatName === undefined ? false : true}
              helperText={errors.chatName?.message?.toString()}
              label="AI Assistant chat name"
              defaultValue={assistant.chatName}
              multiline
              variant="standard"
              {...register("chatName")}
            />
            <Tooltip title="If not setted a default value will be used">
              <TextField
                id="standard-triggerWords"
                error={errors.triggerWords === undefined ? false : true}
                helperText={errors.triggerWords?.message?.toString()}
                label="AI Trigger Words"
                defaultValue={assistant.triggerWords}
                multiline
                variant="standard"
                {...register("triggerWords")}
              />
            </Tooltip>
            <TextField
              id="standard-profanityFilter"
              error={errors.profanityFilter === undefined ? false : true}
              helperText={errors.profanityFilter?.message?.toString()}
              label="AI Profanity filter"
              defaultValue={assistant.profanityFilter}
              multiline
              variant="standard"
              {...register("profanityFilter")}
            />
            <TextField
              id="standard-responseDelay"
              error={errors.responseDelay === undefined ? false : true}
              helperText={errors.responseDelay?.message?.toString()}
              label="AI Response Delay"
              defaultValue={assistant.responseDelay}
              InputProps={{ inputProps: { min: 0, max: 29 } }}
              type="number"
              variant="standard"
              {...register("responseDelay")}
            />
            <Button
              component="label"
              variant="outlined"
              startIcon={<AiOutlineLink />}
            >
              AI Assistant Profile Picture
              <input
                {...register("assistantProfileFile")}
                className="inputNone"
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                onChange={handleAssistantProfileFileUpload}
              />
            </Button>
            {assistant.image &&
              assistant.image.url &&
              !assistantProfileFile && (
                <a
                  className="imageLink"
                  href={assistant.image.url}
                  target="_blank"
                >
                  Click here to see image
                </a>
              )}
            <p className="errosForm">
              {errors.assistantProfileFile &&
                errors.assistantProfileFile.message?.toString()}
            </p>
            {assistantProfileFile &&
              assistantProfileFile.map((e) => (
                <Box key={e.name}>{e?.name}</Box>
              ))}
            <FormControl>
              <InputLabel sx={{ mt: 1 }} id="select-model">
                {t("model")}
              </InputLabel>
              <Select
                sx={{ mt: 1 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model}
                label={t("model")}
                {...register("model")}
                onChange={handleChange}
              >
                <MenuItem value={"gpt-4o"}>gpt-4o</MenuItem>
                <MenuItem value={"gpt-4o-mini"}>gpt-4o-mini</MenuItem>
                <MenuItem value={"gpt-4-turbo"}>gpt-4-turbo</MenuItem>
              </Select>
            </FormControl>
            {assistant.files.map((e) => (
              <div className="assistanFileDiv" key={e.id}>
                <p className="assistantFileName">{e.fileName}</p>
                <div className="assistantActions">
                  {e.question && (
                    <p
                      className="assistantTrash"
                      onClick={() => {
                        if (apiLoading) return;
                        setModalUpdateAiTrainingFile(true);
                        setModalFile(e);
                      }}
                    >
                      <MdEdit />
                    </p>
                  )}
                  <p
                    className="assistantTrash"
                    onClick={() => {
                      if (apiLoading) return;
                      setModalConfirmFileDeletion(true);
                      setModalFile(e);
                    }}
                  >
                    <FaRegTrashAlt color="red" />
                  </p>
                </div>
              </div>
            ))}
          </form>
        </div>
      </Container>

      {modalConfirmFileDeletion && file && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmFileDeletion}
          deleteId={file.id}
          parentId={assistant.id}
          deleteFunction={deleteAssistantFile}
        />
      )}

      {modalUpdateAiTrainingFile && file && (
        <ModalUpdateAiTrainingFile
          setModalUpdateAiTrainingFile={setModalUpdateAiTrainingFile}
          file={file}
          assistantId={assistant.id}
        />
      )}
    </>
  );
};

export default ModalUpdateAssistant;
