import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useUser } from "../../providers/users";

interface IProps {
  retrieveData: any;
  allData: any;
  propertyId?: string | null;
}

const DataLayout = ({ retrieveData, allData, propertyId = "" }: IProps) => {
  const { user } = useUser();
  const params = useParams();
  useEffect(() => {
    if ((user && !allData) || (user && allData.length === 0)) {
      if (propertyId) {
        retrieveData(params[propertyId]);
      } else {
        retrieveData();
      }
    }
  }, [user]);

  return <Outlet />;
};

export default DataLayout;
