import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 78px;
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  align-items: center;
  padding: 0px 15px;
  gap: 10px;
  .notificationInfo {
    display: flex;
    gap: 30px;
  }
  div {
    color: #030303;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 19px;
    width: 150px;
    text-align: center;
  }
  div > svg {
    width: 40px;
    height: 40px;
  }
  .notificationsActions > svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  p,
  div > p {
    text-transform: capitalize;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
