import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventSession } from "../../providers/eventSessions";
import { useState, useEffect } from "react";
import { useTag } from "../../providers/tags";
import NotificationTags from "../NotificationTags";
import ModalFormBase from "../ModalFormBase";
import HourRelationContainer from "../HoursRelationContainer";
import DynamicValuesContainer, {
  calculateExpirationTime,
} from "../DynamicValuesContainer";
import { IDynamicValues } from "../../providers/notifications/notifications.interface";
import { toast } from "react-toastify";
import { useNotification } from "../../providers/notifications";
interface IProps {
  setModalAddSMS: React.Dispatch<React.SetStateAction<boolean>>;
  hourRelation: string;
}
const ModalAddSMS = ({ setModalAddSMS, hourRelation }: IProps) => {
  const { apiLoading } = useUser();
  const { createSMSNotification } = useEventSession();
  const { sendSampleTestSMS } = useNotification();

  const { session_id } = useParams();
  const { t } = useTranslation();
  const [toRegistrants, setToRegistrants] = useState("all");
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues[]>([]);
  const [SMSTest, setSMSTest] = useState("");
  const formSchema = yup
    .object()
    .shape({
      message: yup
        .string()
        .required(t("field_required"))
        .max(256, t("field_max")),

      hoursInput: yup
        .number()
        .integer()
        .moreThan(-1)
        .typeError(t("field_required")),
      minutes: yup
        .number()
        .max(59)
        .integer()
        .moreThan(-1)
        .typeError(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    if (!allTags) {
      getAllTags();
    }
  }, []);
  const onSubmit = async (data: any) => {
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    delete data.hoursInput;
    delete data.minutes;
    delete data.seconds;

    if (hourRelation === "on") data.hours = 0;

    data.hourRelation = hourRelation;
    data.sessionId = session_id;
    data.enabled = true;
    data.dynamicValues = calculateExpirationTime(dynamicValues);
    await createSMSNotification(data);
    setModalAddSMS(false);
  };

  return (
    <ModalFormBase
      title="SMS Notification"
      setModal={setModalAddSMS}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <Container>
        <HourRelationContainer
          hourRelation={hourRelation}
          register={register}
          errors={errors}
          notificationType="sms"
        />

        <NotificationTags
          tags={tags}
          setTags={setTags}
          tagsExclude={tagsExclude}
          setTagsExclude={setTagsExclude}
          register={register}
          allTags={allTags}
          toRegistrants={toRegistrants}
          setToRegistrants={setToRegistrants}
        />
        <h3 className="smsSettings">SMS Settings</h3>

        <p>SMS Message</p>
        <span className="suggestedShortcodes">
          Sugested shortcodes: &#123;&#123;ATTENDEE_FIRST_NAME&#125;&#125;,
          &#123;&#123;JOIN_LIVE_LINK&#125;&#125;,
          &#123;&#123;REPLAY_LINK&#125;&#125;
        </span>
        <TextField
          id="standard-message"
          // error={errors.minutes === undefined ? false : true}
          // helperText={errors.minutes?.message?.toString()}
          label={"Enter your sms message here"}
          multiline
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            mt: 3,
            width: "100%",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("message")}
        />

        {!["on"].includes(hourRelation) && (
          <DynamicValuesContainer
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            hourRelation={hourRelation}
          />
        )}

        <div className="testDiv">
          <TextField
            id="standard-SMSFrom"
            label="Send test sms"
            placeholder="+000000000000"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            value={SMSTest}
            onChange={(e) => setSMSTest(e.target.value)}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              width: "100%",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            onClick={() => {
              if (!SMSTest) {
                return;
              }
              if (!getValues("message")) {
                toast.error("Message is required");
                return;
              }
              sendSampleTestSMS(SMSTest, getValues("message"));
            }}
            sx={{ borderRadius: "25px", width: 150, ml: 1 }}
            variant="contained"
            disabled={apiLoading || !SMSTest}
            size="small"
          >
            Send
          </Button>
        </div>
      </Container>
    </ModalFormBase>
  );
};

export default ModalAddSMS;
