import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid lightgrey;

  .assistantsList {
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  @media (min-width: 1100px) {
    .assistantsList {
      gap: 5px;
      justify-content: center;
    }
  }
`;
