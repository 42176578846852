import { MdPeople } from "react-icons/md";
import { ChatClient } from "../ChatContainer";
import { Container } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useTenant } from "../../providers/tenant";

const Chat = () => {
  const { session_id, path } = useParams();
  const { allEventSessions, editEventSession } = useEventSession();
  const navigate = useNavigate();
  const { chatUsers } = useTenant();
  const [eventSession, setEventSession] = useState<IEventSession | null>(null);
  useEffect(() => {
    if (allEventSessions) {
      const eventFromId = allEventSessions.find(
        (e: IEventSession) => e.id === session_id
      );
      if (eventFromId) {
        setEventSession(eventFromId);
      } else {
        navigate("/" + path + "/events");
      }
    }
  }, [allEventSessions, navigate, path, session_id]);

  return (
    <>
      <Container>
        <div className="header">
          <div>
            <h2>Live Chat</h2>
            {eventSession && eventSession.name}
          </div>

          <div className="attendanceNumber">
            <p>
              <MdPeople />
            </p>
            <p>{/* {chatUsers > 0 ? "Live Now" : ""} */}</p>
            <p> {chatUsers || 0}</p>
          </div>
        </div>

        {eventSession && <ChatClient eventSession={eventSession} />}
      </Container>
    </>
  );
};
export default Chat;
