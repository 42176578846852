import { useTranslation } from "react-i18next";
import { RxHome } from "react-icons/rx";
import PageHeader from "../../components/pageHeader";
import Dashboard from "../../components/Dashboard";
import { useTenant } from "../../providers/tenant";

const Home = () => {
  const { t } = useTranslation();
  const { dashboardData } = useTenant();
  return (
    <>
      <PageHeader
        title={t("dashboard")}
        description={t("dashboard")}
        icon={<RxHome />}
      />
      {dashboardData && <Dashboard />}
    </>
  );
};

export default Home;
