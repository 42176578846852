import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;

  .refreshIcon {
    padding: 2px 5px;
    border: 1px solid black;
  }
  .refreshIcon {
    padding: 2px 5px;
    border: 1px solid black;
  }
  .refreshIcon:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
