import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useEvent } from "../../providers/events";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { IEvent } from "../../providers/events/events.interface";
import { IoCreateOutline } from "react-icons/io5";
import PageHeader from "../pageHeader";

const EventUpdateForm = () => {
  const { apiLoading } = useUser();
  const [event, setEvent] = useState<IEvent | null>(null);
  const navigate = useNavigate();
  const [language, setLanguage] = useState("english");
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const { event_id, path } = useParams();
  const { allEvents, editEvent } = useEvent();
  useEffect(() => {
    if (allEvents) {
      const eventFromId = allEvents.find((e: IEvent) => e.id === event_id);
      if (eventFromId) {
        setEvent(eventFromId);
        setLanguage(eventFromId.language);
      } else {
        navigate("/" + path + "/events");
      }
    }
  }, [allEvents, navigate, path, event_id]);

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (!event) {
        navigate(`/${path}/events`);
      }
      //@ts-ignore
      if (data[key] === "" || data[key] == null || data[key] === event[key]) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    removeEmptyFields(data);

    await editEvent(event_id, data);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().max(128, t("name_max")),
      description: yup.string().max(128, t("description_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <PageHeader
          title={t("update_event")}
          description={`${t("fill_update")} ${t("event")}`}
          icon={<IoCreateOutline />}
        />

        {event && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="filled-name"
              label={t("name")}
              variant="filled"
              defaultValue={event?.name}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("name")}
            />

            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              variant="filled"
              defaultValue={event?.description}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("description")}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">
                {t("language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label={t("language")}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                }}
                {...register("language")}
                onChange={handleChange}
              >
                <MenuItem value={"english"}>{t("english")}</MenuItem>
                <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText> {t("no_fill")}</FormHelperText>
            {apiLoading ? (
              <Loading />
            ) : (
              <div className="buttonsDiv">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                >
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1, borderRadius: "25px" }}
                  onClick={() => navigate("/" + path + "/events")}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
    </>
  );
};

export default EventUpdateForm;
