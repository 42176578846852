import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5px;
  align-items: center;
`;
export const ContainerChat = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 5px;
  align-items: center;
`;
