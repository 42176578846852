import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import FindTenantForm from "../../components/FindTenantForm";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import Footer from "../../components/Footer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FindTenant = () => {
  const { isLogged, isActive, user, verifyRolePermission, roleRecruit } =
    useUser();
  const navigate = useNavigate();
  const query = useQuery();
  const { i18n } = useTranslation();
  useEffect(() => {
    const language = query.get("language");
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
    let pathToRedirect = window.localStorage.getItem("EPA-Session-Path");

    if (isLogged && isActive === true) {
      navigate(`/${pathToRedirect}/events`);
    }
  });

  return (
    <>
      <Container>
        <header>
          <img src={logo} alt="SPP Logo" className="tenantPageLogo" />
        </header>{" "}
        <FindTenantForm />{" "}
      </Container>
      <Footer />
    </>
  );
};

export default FindTenant;
