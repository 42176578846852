import EventSessionUpdateStepper from "../../components/EventSessionUpdateStepper";

const EventSessionUpdate = () => {
  return (
    <>
      <EventSessionUpdateStepper />
    </>
  );
};

export default EventSessionUpdate;
