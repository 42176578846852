import {
  FormControl,
  IconButton,
  InputAdornment,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  FaCheck,
  FaCheckDouble,
  FaEllipsisH,
  FaRegHourglass,
  FaRobot,
} from "react-icons/fa";
import { GoPerson } from "react-icons/go";
import { IoChevronDown, IoSend, IoStar } from "react-icons/io5";
import { MdEdit, MdOutlineAdminPanelSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as uuid from "uuid";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useTenant } from "../../providers/tenant";
import { useUser } from "../../providers/users";
import ModalEditAiMessage from "../ModalEditAiMessage";
import { Container } from "./styles";
import { ref } from "yup";
import ModalEditMessage from "../ModalEditMessage";
const URL = process.env.REACT_APP_WEBSOCKET;
interface MessageData {
  message: string;
  name: string;
  role: "Admin" | "User" | "AI Assistant"; // Assuming only "Admin" or "User" roles are allowed
  roomId: string;
  to: string; // Assuming "to" can be either user ID or "all"
  approved: boolean;
  id: string;
  deleted: boolean;
  tenantId: string;
  sessionId: string;
  registrantId?: string;
  toName: string;
}
interface IPropsChat {
  eventSession: IEventSession;
}
export const ChatClient = ({ eventSession }: IPropsChat) => {
  const { getChatUsersByRoom, setChatUsers } = useTenant();
  const socket = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [modalEditAiMessage, setModalEditAiMessage] = useState(false);
  const [modalEditMessage, setModalEditMessage] = useState(false);
  const [chatRows, setChatRows] = useState<MessageData[]>([]);
  const [messageToEdit, setMessageToEdit] = useState<MessageData | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [registrant, setRegistrant] = useState<any | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("everybody");
  const { user } = useUser();
  const [connectionId, setConnectionId] = useState<string | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === "everybody") {
      setRegistrant(null);
    }
    setMessage(event.target.value as string);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onEmojiSelect = (emoji: any) => {
    try {
      const currentMessage = getValues("message") || "";
      const textField = document.getElementById(
        "outlined-basic-email"
      ) as HTMLInputElement;

      // Simplifique a lógica para adicionar o emoji
      let newMessage = currentMessage;

      if (
        textField?.selectionStart !== undefined &&
        textField?.selectionEnd !== undefined
      ) {
        // Se temos uma seleção/posição do cursor, insira o emoji nessa posição
        const start = textField.selectionStart;
        const end = textField.selectionEnd;
        newMessage =
          currentMessage.slice(0, start) +
          emoji.native +
          currentMessage.slice(end);
      } else {
        // Se não temos posição do cursor, apenas adicione no final
        newMessage = currentMessage + emoji.native;
      }

      // Atualize o valor no formulário
      setValue("message", newMessage, {
        shouldValidate: true,
        shouldDirty: true,
      });

      // Foque o campo e posicione o cursor após o emoji
      setTimeout(() => {
        if (textField) {
          textField.focus();
          const newPosition = newMessage.length;
          textField.setSelectionRange(newPosition, newPosition);
        }
      }, 0);

      // Feche o picker após selecionar
      setShowEmojiPicker(false);
    } catch (error) {
      console.error("Erro ao adicionar emoji:", error);
    }
  };
  useEffect(() => {
    if (!isConnected || !socket) {
      onConnect();
    }
  }, [isConnected, socket]);
  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
      // window.scrollTo({
      //   top: document.body.scrollHeight,
      //   behavior: "smooth",
      // });
    }, 2);
  };
  useEffect(() => {
    // scrollToBottom();
  }, [isConnected, chatRows]);
  const onSocketOpen = useCallback(() => {
    socket.current?.send(
      JSON.stringify({
        action: "enterRoom",
        roomId: eventSession.chatRoom,
        connectionType: "admin",
      })
    );

    setIsConnected(true);
  }, []);

  const onSocketClose = useCallback(() => {
    setIsConnected(false);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getChatUsersByRoom(eventSession.chatRoom, connectionId);
    }, 30 * 1000);

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [getChatUsersByRoom, eventSession.chatRoom, connectionId]);

  const onSocketMessage = useCallback(
    (dataStr: any) => {
      const data = JSON.parse(dataStr);
      if (data.connectionId) {
        setConnectionId(data.connectionId);
      }
      if (!data.message) return;

      // Modificação para adicionar links
      data.message = data.message.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" class="chatLink" target="_blank">$1</a>'
      );

      setChatRows((oldArray) => removeDuplicatesById([...oldArray, data]));
    },
    [chatRows]
  );
  const onConnect = useCallback(() => {
    if (!URL) {
      console.log("Websocket URL is not configured");
      return;
    }
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(URL);
      socket.current.addEventListener("open", onSocketOpen);
      socket.current.addEventListener("close", onSocketClose);
      socket.current.addEventListener("message", (event) => {
        onSocketMessage(event.data);
      });
    }
  }, []);

  useEffect(() => {
    setChatUsers(0);
    return () => {
      socket.current?.close();
    };
  }, []);
  useEffect(() => {
    if (connectionId) {
      getChatUsersByRoom(eventSession.chatRoom, connectionId);
    }
  }, [connectionId]);
  const onDisconnect = useCallback(() => {
    if (isConnected) {
      socket.current?.close();
    }
  }, [isConnected]);

  const onSubmit = async (data: any) => {
    if (!data.message || !eventSession) {
      return;
    }
    reset();

    socket.current?.send(
      JSON.stringify({
        action: "onMessage",
        message: JSON.stringify({
          message: data.message,
          name: "The Cloud Bootcamp Team",
          role: "Admin",
          roomId: eventSession.chatRoom,
          to: registrant ? registrant.id : "all",
          approved: true,
          id: uuid.v4(),
          deleted: false,
          tenantId: eventSession.tenantId,
          sessionId: eventSession.id,
          from: user.id,
          toName: registrant ? registrant.name : "",
        }),
      })
    );
    // scrollToBottom();
  };
  const sendMessage = (item: MessageData) => {
    socket.current?.send(
      JSON.stringify({
        action: "onMessage",
        message: JSON.stringify(item),
      })
    );
    setModalEditAiMessage(false);
    // scrollToBottom();
  };

  return (
    <>
      <Container>
        {" "}
        {eventSession && (
          <>
            <IconComponent />
            <div className="chat-container">
              <ul
                style={{
                  paddingTop: 20,
                  paddingLeft: 44,
                  paddingRight: 44,
                  listStyleType: "none",
                  overflowY: "auto",
                  minHeight: "600px",
                  width: "100%",
                }}
              >
                {chatRows.map((item: MessageData, i) => {
                  // const isUserMessage = item.name.startsWith(
                  //   `The Cloud Bootcamp Team`
                  // );
                  if (item.roomId !== eventSession.chatRoom) {
                    return;
                  }
                  //@ts-ignore
                  const name = registrant && `${registrant.name.repeat(1)}`;
                  return item.role === "Admin" ? (
                    <AdminMessage
                      item={item}
                      key={item.id}
                      setModalEditMessage={setModalEditMessage}
                      setMessageToEdit={setMessageToEdit}
                      sendMessage={sendMessage}
                    />
                  ) : item.role === "AI Assistant" ? (
                    <AIMessage
                      item={item}
                      key={item.id}
                      sendMessage={sendMessage}
                      setMessageToEdit={setMessageToEdit}
                      setModalEditAiMessage={setModalEditAiMessage}
                    />
                  ) : (
                    <UserMessage
                      item={item}
                      sendMessage={sendMessage}
                      setRegistrant={setRegistrant}
                      changeSelect={setMessage}
                      key={item.id}
                    />
                  );
                })}
                <IconButton
                  className="scroll-to-bottom"
                  onClick={scrollToBottom}
                  aria-label="scroll to bottom"
                >
                  <IoChevronDown />
                </IconButton>
                <div ref={messagesEndRef} />
              </ul>{" "}
            </div>
            {isConnected && connectionId && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={message}
                    sx={{
                      "& fieldset": { border: "none" },
                      backgroundColor: "#FFF",
                    }}
                    onChange={handleChange}
                  >
                    {message && message !== "everybody" && (
                      <MenuItem value={message}>{message}</MenuItem>
                    )}
                    <MenuItem value={"everybody"}>To everybody</MenuItem>
                  </Select>
                </FormControl>
                <div className="input-container">
                  <TextField
                    id="outlined-basic-email"
                    placeholder="Type Something"
                    fullWidth
                    autoComplete="off"
                    sx={{
                      boxShadow: "2px 0px 10px rgba(3,3,3,0.1)",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      "& fieldset": { border: "none" },
                    }}
                    {...register("message")}
                    value={watch("message") || ""}
                    onChange={(e) => setValue("message", e.target.value)}
                    onSelect={(e) => {
                      // Força a atualização da posição do cursor quando o campo é selecionado
                      const target = e.target as HTMLInputElement;
                      if (target) {
                        target.selectionStart = target.selectionStart;
                        target.selectionEnd = target.selectionEnd;
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowEmojiPicker(!showEmojiPicker);
                            }}
                            sx={{ marginRight: 1 }}
                          >
                            <span
                              role="img"
                              aria-label="emoji"
                              style={{ fontSize: "1.2rem" }}
                            >
                              😊
                            </span>
                          </IconButton>
                          <IconButton
                            aria-label="toggle password visibility"
                            type="submit"
                          >
                            <IoSend />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {showEmojiPicker && (
                    <div
                      className="emoji-picker-container"
                      onClick={(e) => e.stopPropagation()} // Previne que cliques no picker fechem ele
                    >
                      <Picker
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                        theme="light"
                        set="native"
                        showPreview={false}
                        showSkinTones={false}
                        emojiSize={20}
                        perLine={8}
                        title=""
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          </>
        )}
      </Container>
      {modalEditAiMessage && messageToEdit && eventSession.assistantId && (
        <ModalEditAiMessage
          item={messageToEdit}
          sendMessage={sendMessage}
          setModalEditAiMessage={setModalEditAiMessage}
          assistantId={eventSession.assistantId}
        />
      )}
      {modalEditMessage && messageToEdit && (
        <ModalEditMessage
          item={messageToEdit}
          sendMessage={sendMessage}
          setModalEditMessage={setModalEditMessage}
        />
      )}
    </>
  );
};

const styles = {
  Icon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "40px",
    top: "218px",
    left: "322px",
    width: "40px",
    height: "40px",
    marginTop: "20px",
    marginLeft: "44px",
  },
};

const IconComponent = () => (
  <svg style={styles.Icon} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"></path>
    <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18z"></path>
  </svg>
);
interface IProps {
  item: MessageData;
  setModalEditMessage: any;
  setMessageToEdit: any;
  sendMessage: (item: MessageData) => void;
}
interface IPropsWithMessage {
  item: MessageData;
  sendMessage: (item: MessageData) => void;
  setMessageToEdit: any;
  setModalEditAiMessage: any;
}
interface IPropsUser {
  item: MessageData;
  sendMessage: (item: MessageData) => void;
  setRegistrant: any;
  changeSelect: any;
}
const AdminMessage = ({
  item,
  setMessageToEdit,
  setModalEditMessage,
  sendMessage,
}: IProps) => {
  const deleted = item.deleted;
  return (
    <li key={item.id} style={{ paddingBottom: 9 }}>
      <div className="message-header">
        <MdOutlineAdminPanelSettings /> <span>{item.name}</span> <IoStar />
        {item.to !== "all" && (
          <span className="toRegistrantMessage">to {item.toName}</span>
        )}
      </div>
      <div className="message-body">
        {" "}
        <div
          className={deleted ? "messageDeleted" : ""}
          dangerouslySetInnerHTML={{
            __html: deleted ? "Message deleted" : item.message,
          }}
        />
        {/* <div> edit</div> */}
        <BasicMenu
          deleteFunction={sendMessage}
          item={item}
          setModalEditMessage={setModalEditMessage}
          setMessageToEdit={setMessageToEdit}
        />
      </div>
    </li>
  );
};

const AIMessage = ({
  item,
  sendMessage,
  setMessageToEdit,
  setModalEditAiMessage,
}: IPropsWithMessage) => {
  const deleted = item.deleted;
  return (
    <li key={item.id} style={{ paddingBottom: 9 }}>
      <div className="message-header">
        <FaRobot /> <span>{item.name} </span>{" "}
        {item.to !== "all" && (
          <span className="toRegistrantMessage">
            to {(" " + item.toName).slice(1)}
          </span>
        )}
      </div>
      <div className="message-body">
        {" "}
        <div>
          <div
            className={deleted ? "messageDeleted" : ""}
            dangerouslySetInnerHTML={{
              __html: deleted ? "Message deleted" : item.message,
            }}
          />
          {item.approved ? <FaCheckDouble /> : <FaRegHourglass />}{" "}
        </div>
        <div>
          {!item.approved && (
            <div className="aiButtons">
              <FaCheck
                onClick={() =>
                  sendMessage({
                    ...item,
                    approved: true,
                  })
                }
              />{" "}
              <MdEdit
                onClick={() => {
                  setModalEditAiMessage(true);
                  setMessageToEdit(item);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

const UserMessage = ({
  item,
  sendMessage,
  setRegistrant,
  changeSelect,
}: IPropsUser) => {
  const deleted = item.deleted;
  return (
    <li key={item.id} style={{ paddingBottom: 9 }}>
      <div className="message-header">
        <GoPerson /> <span>{item.name}</span>
      </div>
      <div className="message-body">
        {" "}
        <div
          className={deleted ? "messageDeleted" : ""}
          dangerouslySetInnerHTML={{
            __html: deleted ? "Message deleted" : item.message,
          }}
        />
        {!deleted && (
          <BasicMenu
            deleteFunction={sendMessage}
            item={item}
            setRegistrant={setRegistrant}
            changeSelect={changeSelect}
          />
        )}
      </div>
    </li>
  );
};

function removeDuplicatesById(array: MessageData[]): MessageData[] {
  const uniqueObjects: { [id: string]: MessageData } = {};
  array.forEach((obj) => {
    if (obj.deleted === true) {
      if (uniqueObjects[obj.id]) {
        uniqueObjects[obj.id].deleted = true;
      } else {
        uniqueObjects[obj.id] = { ...obj, deleted: true };
      }
    } else {
      uniqueObjects[obj.id] = obj;
    }
  });
  return Object.values(uniqueObjects);
}

export default function BasicMenu({
  setRegistrant,
  item,
  deleteFunction,
  changeSelect,
  setModalEditMessage,
  setMessageToEdit,
}: any) {
  const { getRegistrantById } = useEventSession();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRegistrant = async () => {
    if (!item.from) {
      setAnchorEl(null);
      return;
    }
    const registrant = await getRegistrantById(item.from);
    setRegistrant(registrant);
    changeSelect(registrant.name);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deleteFunction({ ...item, deleted: true });
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setModalEditMessage(true);
    setMessageToEdit(item);
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FaEllipsisH />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        {setRegistrant && <MenuItem onClick={handleRegistrant}>Reply</MenuItem>}
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
      </Menu>
    </div>
  );
}
