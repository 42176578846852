import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineBarChart } from "react-icons/ai";
import {
  CartesianGrid,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Container } from "./styles";
import { Select, MenuItem } from "@mui/material";

const Chart = ({ registrations, timePeriod }: any) => {
  const [chartRegistrantEvents, setChartRegistrantEvents] = useState<any>([]);
  // const [timePeriod, setTimePeriod] = useState<string>("Last 7 days");
  const { t } = useTranslation();
  const processRegistrantEvents = (registrations: any, period: string) => {
    const periodArray: any[] = [];
    const today = new Date();
    let periodStart: Date;

    if (period === "Last 12 months") {
      periodStart = new Date(today.getFullYear() - 1, today.getMonth() + 1, 1);
      for (let i = 0; i < 12; i++) {
        const month = ((periodStart.getMonth() + i) % 12) + 1;
        const year =
          periodStart.getFullYear() +
          Math.floor((periodStart.getMonth() + i) / 12);
        const auxObj: any = { x: `${month}/${year}`, registrations: 0 };
        periodArray.push(auxObj);
      }
    } else if (period === "Last 7 days") {
      periodStart = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
      for (let i = 0; i < 7; i++) {
        const date = new Date(periodStart.getTime() + i * 24 * 60 * 60 * 1000);
        const auxObj: any = {
          x: `${date.getDate()}/${date.getMonth() + 1}`,
          registrations: 0,
        };
        periodArray.push(auxObj);
      }
    } else if (period === "Last 30 days") {
      periodStart = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
      for (let i = 0; i < 30; i++) {
        const date = new Date(periodStart.getTime() + i * 24 * 60 * 60 * 1000);
        const auxObj: any = {
          x: `${date.getDate()}/${date.getMonth() + 1}`,
          registrations: 0,
        };
        periodArray.push(auxObj);
      }
    }

    const dateKeys = Object.keys(registrations);

    for (let dateKey of dateKeys) {
      const date = new Date(`${dateKey}T00:00:00`);
      console.log(date);
      for (let i = 0; i < periodArray.length; i++) {
        if (
          (period === "Last 12 months" &&
            `${date.getMonth() + 1}/${date.getFullYear()}` ===
              periodArray[i].x) ||
          (period === "Last 7 days" &&
            `${date.getDate()}/${date.getMonth() + 1}` === periodArray[i].x) ||
          (period === "Last 30 days" &&
            `${date.getDate()}/${date.getMonth() + 1}` === periodArray[i].x)
        ) {
          periodArray[i].registrations += registrations[dateKey];
        }
      }
    }

    setChartRegistrantEvents(periodArray);
  };

  useEffect(() => {
    processRegistrantEvents(registrations, timePeriod);
  }, [registrations, timePeriod]);

  return (
    <>
      <Container>
        <div className="chartsContainer">
          <div className="chartsTitle">
            Registrations
            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
                ml: 2,
              }}
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <MenuItem value="Last 7 days">Last 7 days</MenuItem>
              <MenuItem value="Last 30 days">Last 30 days</MenuItem>
              <MenuItem value="Last 12 months">Last 12 months</MenuItem>
            </Select> */}
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              data={chartRegistrantEvents}
            >
              <Bar
                dataKey="registrations"
                name="Registrations"
                fill="#fff" // Preto
              />
              {/* <CartesianGrid stroke="#fff" /> */}
              cinza escuro
              <XAxis dataKey="x" stroke="#fff" />
              <YAxis
                domain={[0, (dataMax: number) => Math.max(dataMax, 50)]}
                stroke="#fff"
              />
              <Tooltip
                contentStyle={{ backgroundColor: "#000", color: "#fff" }} // Fundo preto, texto branco
                itemStyle={{ color: "#fff" }} // Texto branco
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Container>
    </>
  );
};

export default Chart;
