import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;
export const Container = styled.form`
  animation: ${fadeAnimation} 1s;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .registerContainer {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    max-width: 500px;
    min-width: 320px;
    width: 90%;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 2px;
    box-shadow: rgba(3, 3, 3, 0.1) 2px 0px 10px;
  }

  .registerRedirect {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  .registerRedirect:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    .registerContainer {
      padding: 27px 24px;
    }
  }
`;
