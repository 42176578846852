import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import AccountMenu from "../AccountMenu";

interface IProps {
  verifyRole?: boolean;
  roleToVerify?: string[];
  title: string;
  description: string;
  buttonPath?: string;
  buttonContent?: string;
  icon: React.ReactNode;
  showButton?: boolean;
}

const PageHeader = ({
  verifyRole = false,
  roleToVerify,
  title,
  description,
  buttonPath,
  buttonContent,
  icon,
  showButton,
}: IProps) => {
  const { verifyRolePermission, apiLoading } = useUser();
  const navigate = useNavigate();
  const { path } = useParams();
  const buttonshow =
    (verifyRole && verifyRolePermission(roleToVerify)) || showButton;
  return (
    <Container buttonshow={buttonshow ? 1 : 0}>
      {" "}
      <div className="headerTitleAndSubtitle">
        <h2 className="headerTitle">{title}</h2>
        {/* <p className="headerDescription">{description}</p> */}
      </div>
      <div className="headerButtons">
        <AccountMenu />
        {buttonshow ? (
          <Button
            variant="contained"
            className="pageHeaderButton"
            disabled={apiLoading}
            size="large"
            sx={{ borderRadius: "25px" }}
            onClick={() => navigate(`/${path}/${buttonPath}`)}
          >
            {buttonContent}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};

export default PageHeader;
