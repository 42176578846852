import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useTranslation } from "react-i18next";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GeneralSettings from "../GeneralSettings";
import { UsersContainer } from "../UsersContainer";
import APISettings from "../APISettings";
import IntegrationSettingsContainer from "../IntegrationSettingsContainer";
import { WhatsappTemplateContainer } from "../WhatsappTemplates";
import { AudioFilesContainer } from "../AudioFilesContainer";
import ToolsSettingsContainer from "../ToolsSettingsContainer";
export default function SettingsContainer() {
  const [value, setValue] = React.useState("0");

  const { t } = useTranslation();
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    {
      name: "General",
      component: <GeneralSettings />,
    },
    {
      name: "Users",
      component: <UsersContainer />,
    },
    {
      name: "Integrations",
      component: <IntegrationSettingsContainer />,
    },
    {
      name: "Tools",
      component: <ToolsSettingsContainer />,
    },
    {
      name: "Whatsapp Templates",
      component: <WhatsappTemplateContainer />,
    },
    {
      name: "Audio Files",
      component: <AudioFilesContainer />,
    },
    {
      name: "API",
      component: <APISettings />,
    },
    {
      name: "Billing",
      component: (
        <div>
          <h3>Billing</h3>
          <p>Coming soon</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            color: "black",
            mb: 0,
          }}
        >
          <TabList
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {tabs.map((tab: ITab, index: number) => (
              <Tab
                value={index.toString()}
                label={tab.name}
                key={index + tab.name}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <div>
        <React.Fragment>{tabs[Number(value)].component}</React.Fragment>
      </div>
    </>
  );
}

interface ITab {
  component: React.ReactNode;
  name: string;
}
