import styled from "styled-components";

export const Container = styled.div`
  .addDynamicLink {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .dynamicValueList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    width: 100%;
    gap: 10px;
  }

  .dynamicValueList > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .typeValueDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    max-width: 50%;
    gap: 5px;
  }
  .dynamicValueList > div > div > p {
    white-space: pre-wrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  .hourExpirationDiv {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .redirectCloakingDiv {
    display: flex;
    align-items: center !important;
    gap: 5px;
  }
`;
