import { useState } from "react";
import { FaSms } from "react-icons/fa";
import { IoIosCall, IoMdClose } from "react-icons/io";
import { MdEdit, MdEmail } from "react-icons/md";
import { useNotification } from "../../providers/notifications";
import {
  IAudioNotification,
  IEmailNotification,
  ISMSNotification,
  IWhatsappNotification,
} from "../../providers/notifications/notifications.interface";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import ModalEditAudio from "../ModalEditAudio";
import ModalEditEmail from "../ModalEditEmail";
import ModalEditSMS from "../ModalEditSMS";
import { Container } from "./styles";
import { IoLogoWhatsapp } from "react-icons/io5";
import ModalEditWhatsapp from "../ModalEditWhatsapp";

interface IProps {
  notification:
    | IEmailNotification
    | ISMSNotification
    | IAudioNotification
    | IWhatsappNotification;
  type: "email" | "audio" | "SMS" | "whatsapp";
}
const NotificationCard = ({ notification, type }: IProps) => {
  const {
    deleteAudioNotification,
    deleteEmailNotification,
    deleteSMSNotification,
    deleteWhatsappNotification,
  } = useNotification();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalEditEmail, setModalEditEmail] = useState(false);
  const [modalEditSMS, setModalEditSMS] = useState(false);
  const [modalEditAudio, setModalEditAudio] = useState(false);
  const [modalEditWhatsapp, setModalEditWhatsapp] = useState(false);

  const [whatsappToEdit, setWhatsappToEdit] =
    useState<IWhatsappNotification | null>(null);
  const [audioToEdit, setAudioToEdit] = useState<IAudioNotification | null>(
    null
  );
  const [smsToEdit, setSMSToEdit] = useState<ISMSNotification | null>(null);
  const [emailToEdit, setEmailToEdit] = useState<IEmailNotification | null>(
    null
  );

  const notificationItem = {
    email: {
      label: "Subject",
      value: type === "email" && notification.subject,
      icon: <MdEmail />,
      deleteFunction: deleteEmailNotification,
      modal: setModalEditEmail,
      setEdit: setEmailToEdit,
    },
    SMS: {
      label: "Preview",
      value: notification.message,
      icon: <FaSms />,
      deleteFunction: deleteSMSNotification,
      modal: setModalEditSMS,
      setEdit: setSMSToEdit,
    },
    audio: {
      label: "Audio Message",
      value: notification.audioFile ? notification.audioFile.name : "",
      icon: <IoIosCall />,
      deleteFunction: deleteAudioNotification,
      modal: setModalEditAudio,
      setEdit: setAudioToEdit,
    },
    whatsapp: {
      label: "Whatsapp Template",
      value: notification.template ? notification.template.name : "",
      icon: <IoLogoWhatsapp />,
      deleteFunction: deleteWhatsappNotification,
      modal: setModalEditWhatsapp,
      setEdit: setWhatsappToEdit,
    },
  };
  return (
    <>
      <Container>
        <section className="notificationInfo">
          <div>{notificationItem[type].icon}</div>
          <div>
            <p>
              <b>Notification:</b>
            </p>
            <p> {type}</p>
          </div>
        </section>
        <div>
          <p>
            <b>When:</b>
          </p>
          <div>
            {notification.hourRelation !== "on" ? (
              <p>
                {" "}
                {formatMilisseconds(notification.hours)}{" "}
                {notification.hourRelation === "AfterSignup"
                  ? "after sign up"
                  : notification.hourRelation}
              </p>
            ) : (
              "Immediately "
            )}
          </div>
        </div>
        <div>
          <p>
            <b>{notificationItem[type].label}</b>
          </p>
          <p>{notificationItem[type].value}</p>
        </div>
        <div className="notificationsActions">
          <MdEdit
            size={28}
            onClick={() => {
              notificationItem[type].setEdit(notification);
              notificationItem[type].modal(true);
            }}
          />
          <IoMdClose
            size={28}
            onClick={() => {
              setModalConfirmDeletion(true);
              setDeleteId(notification.id);
            }}
          />
        </div>
      </Container>

      {deleteId && modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={notificationItem[type].deleteFunction}
        />
      )}
      {modalEditSMS && smsToEdit && (
        <ModalEditSMS sms={smsToEdit} setModalEditSMS={setModalEditSMS} />
      )}
      {modalEditEmail && emailToEdit && (
        <ModalEditEmail
          email={emailToEdit}
          setModalEditEmail={setModalEditEmail}
        />
      )}
      {modalEditAudio && audioToEdit && (
        <ModalEditAudio
          notification={audioToEdit}
          setModalEditAudio={setModalEditAudio}
        />
      )}

      {modalEditWhatsapp && whatsappToEdit && (
        <ModalEditWhatsapp
          notification={whatsappToEdit}
          setModalEditWhatsapp={setModalEditWhatsapp}
        />
      )}
    </>
  );
};

export function formatMilisseconds(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000); // Convertendo milissegundos para segundos

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString =
    hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"}` : "";
  const minutesString =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "minute" : "minutes"}` : "";
  const secondsString =
    remainingSeconds > 0
      ? `${remainingSeconds} ${remainingSeconds === 1 ? "second" : "seconds"}`
      : "";

  const timeArray = [hoursString, minutesString, secondsString].filter(Boolean);

  return timeArray.join(", ") || "0 seconds";
}

export default NotificationCard;
