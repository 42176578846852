import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";
import React, { useEffect } from "react";
import { ITag } from "../../providers/tags/tags.interface";

interface IProps {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  toRegistrants: string;
  setToRegistrants: React.Dispatch<React.SetStateAction<string>>;
  allTags: ITag[];
  register: UseFormRegister<FieldValues>;
  tagsExclude: string[];
  setTagsExclude: React.Dispatch<React.SetStateAction<string[]>>;
}

const NotificationTags: React.FC<IProps> = ({
  tags,
  setTags,
  toRegistrants,
  setToRegistrants,
  allTags,
  register,
  tagsExclude,
  setTagsExclude,
  ...props
}: IProps) => {
  const handleChangeTo = (event: SelectChangeEvent<string>) => {
    setToRegistrants(event.target.value);
  };
  const handleChangeTag = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setTags(typeof value === "string" ? value.split(",") : (value as string[]));
  };
  const handleChangeTagExclude = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setTagsExclude(
      typeof value === "string" ? value.split(",") : (value as string[])
    );
  };
  return (
    <>
      <FormControl>
        <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
          Send To
        </InputLabel>
        <Select
          labelId="demo-simple-select-roles"
          id="demo-simple-select-roles"
          required
          label={"Send To"}
          value={toRegistrants}
          {...register("to")}
          onChange={handleChangeTo}
        >
          <MenuItem value={"all"}>All Registrants</MenuItem>
          <MenuItem value={"with"}>Registrant with any of this tags</MenuItem>

          <MenuItem value={"without"}>
            Registrants without any of this tags
          </MenuItem>
          <MenuItem value={"withAndWithout"}>
            Registrant with any of this tags and without any of this tags
          </MenuItem>
        </Select>
      </FormControl>
      {toRegistrants !== "all" && (
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
            Tags
          </InputLabel>
          <Select
            labelId="demo-simple-select-tags"
            id="demo-simple-select-tags"
            multiple
            value={tags}
            required
            label="Tags"
            {...register("tags")}
            onChange={handleChangeTag}
          >
            {allTags &&
              allTags.map((e: any) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {toRegistrants === "withAndWithout" && (
        <FormControl>
          <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
            Tags Exclude
          </InputLabel>
          <Select
            labelId="demo-simple-select-tags"
            id="demo-simple-select-tags"
            multiple
            value={tagsExclude}
            label="Tags Exclude"
            {...register("tagsExclude")}
            onChange={handleChangeTagExclude}
          >
            {allTags &&
              allTags.map((e: any) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default NotificationTags;
