import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { MdOutlineAnalytics } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useEvent } from "../../providers/events";
import { IEvent } from "../../providers/events/events.interface";
import { useRegistrantEvent } from "../../providers/registrantEvents";
import { IRegistrantEvent } from "../../providers/registrantEvents/registrantEvents.interface";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { useForm } from "react-hook-form";
import AnalyticsItem from "../AnalyticsItem";

type Value = any;
const AnalyticsContainer = () => {
  const {
    allRegistrantEvents,
    getAllRegistrantEvents,
    setAllRegistrantEvents,
  } = useRegistrantEvent();
  const { allEvents } = useEvent();
  const { allEventSessions } = useEventSession();
  const { apiLoading } = useUser();
  const { t } = useTranslation();
  const [datePicked, changeDatePicked] = useState<Value>([null, null]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [eventSessionId, setEventSessionId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { path } = useParams();

  function handleChange(e: any) {
    const {
      target: { value },
    } = e;
    setEventId(value);
    setAllRegistrantEvents(null);
  }
  function handleChangeEventSession(e: any) {
    const {
      target: { value },
    } = e;
    setEventSessionId(value);
    setAllRegistrantEvents(null);
  }
  const onSubmit = async () => {
    if (!eventSessionId) {
      navigate("/events");
    }
    await getAllRegistrantEvents(eventSessionId);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const filteredRegistrantEvents =
    allRegistrantEvents && allRegistrantEvents.length > 0 && datePicked
      ? allRegistrantEvents.filter((e: IRegistrantEvent) => {
          let beginFirstDate = new Date(datePicked[0]);
          beginFirstDate.setHours(0);
          beginFirstDate.setMinutes(0);
          beginFirstDate.setSeconds(0);
          let endLastDate = new Date(datePicked[1]);
          endLastDate.setHours(23);
          endLastDate.setMinutes(59);
          beginFirstDate.setSeconds(59);
          return (
            new Date(e.date) <= endLastDate &&
            new Date(e.date) >= beginFirstDate
          );
        })
      : [];
  return (
    <Container>
      <PageHeader
        title={"Analytics"}
        description={"Analytics"}
        icon={<MdOutlineAnalytics />}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="datePickerContainer custom-select"
      >
        <DateRangePicker
          className="datePicker"
          required
          onChange={changeDatePicked}
          // @ts-nocheck
          value={datePicked}
        />
        <FormControl id="select-label">
          <InputLabel id="demo-simple-select-events">{t("events")}</InputLabel>
          <Select
            labelId="demo-simple-select-events"
            id="demo-simple-select-events"
            value={eventId}
            required
            label={t("events")}
            placeholder="Choose a event"
            onChange={handleChange}
          >
            {allEvents &&
              allEvents.map((e: IEvent) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl id="select-label">
          <InputLabel id="demo-simple-select-events">Event session</InputLabel>
          <Select
            labelId="demo-simple-select-events"
            id="demo-simple-select-events"
            value={eventSessionId}
            sx={{ mt: 0 }}
            required
            label="Event session"
            placeholder="Choose a session"
            onChange={handleChangeEventSession}
          >
            {eventId &&
              allEventSessions
                .filter((e: IEventSession) => e.eventId === eventId)
                .map((e: IEventSession) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          disabled={!eventId || !eventSessionId || !datePicked}
        >
          Search
        </Button>
      </form>
      {apiLoading ? (
        <Loading />
      ) : allRegistrantEvents && filteredRegistrantEvents.length === 0 ? (
        <h3>No data found for the filter selected.</h3>
      ) : filteredRegistrantEvents.length === 0 ? (
        <></>
      ) : (
        eventId &&
        eventSessionId && (
          <AnalyticsItem
            registrantEvents={filteredRegistrantEvents}
            session={allEventSessions.find(
              (e: IEventSession) => e.id === eventSessionId
            )}
          />
        )
      )}
    </Container>
  );
};

export default AnalyticsContainer;
