import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../providers/tenant";
import { FormHelperText } from "@material-ui/core";
import { useState } from "react";
import { useNotification } from "../../providers/notifications";
const VoiceUpdateForm = () => {
  const { apiLoading } = useUser();
  const { updateIntegrations, tenant } = useTenant();
  const { t } = useTranslation();
  const { sendTestAudio } = useNotification();
  const [AudioTest, setAudioTest] = useState("");
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await updateIntegrations({ twilioAudio: data });
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("field_required")).max(128, t("field_max")),
      accountSid: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
      authToken: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
      from: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max"))
        .matches(/^\d+$/, "Only numbers"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Twilio</h3>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          defaultValue={
            tenant.integrations.twilioAudio
              ? tenant.integrations.twilioAudio.name
              : ""
          }
          id="standard-name"
          label={t("name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("name")}
        />
        <TextField
          id="standard-accountSid"
          error={errors.accountSid === undefined ? false : true}
          helperText={errors.accountSid?.message?.toString()}
          defaultValue={
            tenant.integrations.twilioAudio
              ? tenant.integrations.twilioAudio.accountSid
              : ""
          }
          label="Account SID"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("accountSid")}
        />

        <TextField
          id="standard-authToken"
          error={errors.authToken === undefined ? false : true}
          helperText={errors.authToken?.message?.toString()}
          defaultValue={
            tenant.integrations.twilioAudio
              ? tenant.integrations.twilioAudio.authToken
              : ""
          }
          label="Auth token"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("authToken")}
        />
        <TextField
          id="standard-from"
          error={errors.from === undefined ? false : true}
          helperText={errors.from?.message?.toString()}
          defaultValue={
            tenant.integrations.twilioAudio
              ? tenant.integrations.twilioAudio.from
              : ""
          }
          label="From number"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("from")}
        />

        {tenant.integrations.twilioAudio && (
          <div className="testDiv">
            <TextField
              id="standard-AudioFrom"
              label="Send test Audio"
              placeholder="+000000000000"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              value={AudioTest}
              onChange={(e) => setAudioTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={() => {
                if (!AudioTest) {
                  return;
                }

                sendTestAudio(AudioTest);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              disabled={apiLoading}
              size="small"
            >
              Send
            </Button>
          </div>
        )}

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              save
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default VoiceUpdateForm;
