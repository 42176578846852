import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useRegistrantEvent } from "../../providers/registrantEvents";
import { IRegistrant } from "../../providers/registrantEvents/registrantEvents.interface";
import { IRole } from "../../providers/roles/roles.interface";
import { useTag } from "../../providers/tags";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
interface IProps {
  setModalChangeTags: React.Dispatch<React.SetStateAction<boolean>>;

  registrant: IRegistrant;
}
const ModalChangeTags = ({ setModalChangeTags, registrant }: IProps) => {
  const [tags, setTags] = useState<any>([]);
  const { apiLoading } = useUser();

  const { allTags, getAllTags } = useTag();
  const { t } = useTranslation();
  const { updateRegistrantTags } = useRegistrantEvent();
  useEffect(() => {
    if (!allTags) {
      getAllTags();
    }
    if (tags.length === 0) {
      const tags = registrant.tags ? registrant.tags.map((e: any) => e.id) : [];
      setTags(tags);
    }
  }, []);

  const handleChangeTag = (event: any): any => {
    const {
      target: { value },
    } = event;

    setTags(typeof value === "string" ? value.split(",") : value);
  };
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    if (!data.tags) {
      setModalChangeTags(false);
      return;
    }
    data.registrantId = registrant.id;
    const changed = await updateRegistrantTags(data);

    setModalChangeTags(false);
  };
  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalChangeTags(false);
            }}
          >
            <IoMdClose size={20} />
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              Tags
            </InputLabel>
            <Select
              labelId="demo-simple-select-roles"
              id="demo-simple-select-roles"
              multiple
              value={tags}
              required
              label="Tags"
              {...register("tags")}
              onChange={handleChangeTag}
            >
              {allTags &&
                allTags.map((e: IRole) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {apiLoading ? (
            <Loading />
          ) : (
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              {t("update")}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalChangeTags;
