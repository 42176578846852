import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { useRegistrantEvent } from "../../providers/registrantEvents";
import { useEventSession } from "../../providers/eventSessions";
import { StyledModalBox } from "./styles";
import { formatTime } from "../EventSessionList";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Loading from "../Loading";
dayjs.extend(utc);
dayjs.extend(timezone);

interface ModalListRegistrantEventsProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface EventSession {
  id: string;
  eventId: string;
  name: string;
  sessionSchedule: {
    timezone: string;
  };
}

const ModalListRegistrantEvents: React.FC<ModalListRegistrantEventsProps> = ({
  id,
  open,
  setOpen,
}) => {
  const { getAllRegistrantEventsFromRegistrant } = useRegistrantEvent();
  const { allEventSessions, getAllEventSessions } = useEventSession();
  const [registrantEvents, setRegistrantEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionsLoaded, setSessionsLoaded] = useState<boolean>(false);
  const hasFetched = useRef<boolean>(false);

  useEffect(() => {
    if (!allEventSessions) {
      getAllEventSessions(false);
    }
  }, []);
  useEffect(() => {
    const fetchRegistrantEvents = async () => {
      setLoading(true);

      const events: any[] = await getAllRegistrantEventsFromRegistrant(id);

      const enrichedEvents =
        allEventSessions &&
        events &&
        events
          .map((event) => {
            const session = allEventSessions
              ? allEventSessions.find((s: any) => s.id === event.sessionId)
              : null;
            const timezone =
              session && session.sessionSchedule
                ? session.sessionSchedule.timezone
                : "UTC";
            return {
              ...event,
              sessionName: session ? session.name : "N/A",
              timezone: timezone,
              watchedTimeFormatted: event.watchedTime
                ? formatTime(event.watchedTime)
                : null,
              watchedReplayTimeFormatted: event.watchedReplayTime
                ? formatTime(event.watchedReplayTime)
                : null,
              dateFormatted: formatDateWithTimezone(event.date, timezone),
            };
          })
          .sort((a, b) => a.date - b.date);

      setRegistrantEvents(enrichedEvents);
      setSessionsLoaded(true);
      setLoading(false);
    };

    if (open && !hasFetched.current && allEventSessions) {
      fetchRegistrantEvents();
      hasFetched.current = true;
    }
  }, [
    open,
    id,
    getAllRegistrantEventsFromRegistrant,
    allEventSessions,
    getAllEventSessions,
  ]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <StyledModalBox>
        <Typography variant="h6" gutterBottom>
          Registered sessions
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <>
            {registrantEvents &&
            registrantEvents.length === 0 &&
            sessionsLoaded ? (
              <Typography variant="body1">No sessions found.</Typography>
            ) : (
              <List>
                {registrantEvents &&
                  registrantEvents.map((event) => (
                    <ListItem key={event.id}>
                      <ListItemText
                        primary={`Session: ${event.sessionName}`}
                        secondary={
                          <>
                            <div>{`Date: ${event.dateFormatted} (Local time)`}</div>
                            {event.watchedTimeFormatted && (
                              <div>{`Watched Time: ${event.watchedTimeFormatted}`}</div>
                            )}
                            {event.watchedReplayTimeFormatted && (
                              <div>{`Watched Replay Time: ${event.watchedReplayTimeFormatted}`}</div>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            )}
          </>
        )}
      </StyledModalBox>
    </Modal>
  );
};

export default ModalListRegistrantEvents;

const formatDateWithTimezone = (date: number): string => {
  return new Date(date).toLocaleString();
};
