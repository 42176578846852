import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from "../../providers/users";
import { useRegistrantEvent } from "../../providers/registrantEvents";
import { useTenant } from "../../providers/tenant";
import ModalFormBase from "../ModalFormBase";
import dayjs from "dayjs";
import { Container } from "./styles";

interface IProps {
  setModalAddParticipant: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: string;
}

const ModalAddParticipant = ({ setModalAddParticipant, eventId }: IProps) => {
  const { apiLoading } = useUser();
  const { registerParticipant } = useRegistrantEvent();
  const { t } = useTranslation();
  const { tenant, getTenant } = useTenant();
  const timezoneList = Intl.supportedValuesOf("timeZone");
  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, []);
  const formSchema = yup.object().shape({
    firstName: yup.string().required(t("field_required")),
    email: yup.string().email(t("invalid_email")).required(t("field_required")),
    phone: yup.string().required(t("field_required")),
    baseEventDate: yup.date().required(t("field_required")),
    timezone: yup.string().required(t("field_required")),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: any) => {
    const selectedDate = dayjs(data.baseEventDate);

    const registrationData = {
      email: data.email,
      phone: data.phone,
      first_name: data.firstName,
      base_event_date: selectedDate.format("YYYY-MM-DD"),
      base_event_time: selectedDate.format("HH:mm"),
      base_event_timezone: data.timezone,
      tenantId: tenant.id,
    };

    try {
      await registerParticipant(eventId as string, registrationData);
      setModalAddParticipant(false);
    } catch (error) {
      console.error("Error registering participant:", error);
    }
  };

  return (
    <ModalFormBase
      title={t("register_participant")}
      setModal={setModalAddParticipant}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="baseEventDate"
            control={control}
            render={({ field }) => (
              <>
                <MobileDateTimePicker
                  label={t("select_event_date")}
                  value={field.value}
                  autoFocus
                  onChange={(newValue) => field.onChange(newValue)}
                  slotProps={{
                    textField: {
                      variant: "filled",
                      fullWidth: true,
                      error: !!errors.baseEventDate,
                      InputProps: {
                        disableUnderline: true,
                      },
                      sx: {
                        backgroundColor: "#FFF",
                        boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
                        "& fieldset": { border: "none" },
                      },
                    },
                  }}
                />
                {errors.baseEventDate && (
                  <span className="error-message">
                    {errors.baseEventDate.message?.toString()}
                  </span>
                )}
              </>
            )}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="timezone-label">Timezone</InputLabel>
          <Select
            labelId="timezone-label"
            id="timezone-select"
            variant="filled"
            error={!!errors.timezone}
            {...register("timezone")}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          >
            {timezoneList.map((timezone: string) => (
              <MenuItem value={timezone} key={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
          {errors.timezone && (
            <span className="error-message">
              {errors.timezone.message?.toString()}
            </span>
          )}
        </FormControl>

        <TextField
          label={t("first_name")}
          variant="filled"
          fullWidth
          error={!!errors.firstName}
          helperText={errors.firstName?.message?.toString()}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            backgroundColor: "#FFF",
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
            "& fieldset": { border: "none" },
          }}
          {...register("firstName")}
        />

        <TextField
          label={t("email")}
          type="email"
          variant="filled"
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message?.toString()}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            backgroundColor: "#FFF",
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
            "& fieldset": { border: "none" },
          }}
          {...register("email")}
        />

        <TextField
          label={t("phone_number")}
          type="tel"
          variant="filled"
          fullWidth
          error={!!errors.phone}
          helperText={errors.phone?.message?.toString()}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            backgroundColor: "#FFF",
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
            "& fieldset": { border: "none" },
          }}
          {...register("phone")}
        />
      </Container>
    </ModalFormBase>
  );
};

export default ModalAddParticipant;
