import styled, { keyframes } from "styled-components";
import { fadeInLeft } from "react-animations";
const fadeAnimation = keyframes`${fadeInLeft}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: 0.5s ${fadeAnimation};
  overflow-y: auto;

  .headerBurguerMenu {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0 15px;
    color: ${({ theme }) => theme.colors.black};
  }

  .navBurguerImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navBurguerImage > img {
    width: 250px;
    margin: 15px 0;
    text-align: center;
  }
  .navBurguerUser > p {
    color: black;
    margin-top: 5px;
    text-transform: capitalize;
    font-size: 1.5rem;
    text-align: center;
  }
  .navLinksContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .navBurguerLinks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 25px;
    gap: 15px;
    margin: 10px auto;
  }
  .navBurguerLinks > p {
    color: black;
    margin-top: 15px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    text-align: start;
    width: 100%;
    /* 
    border-bottom: 1px solid black; */
  }
  /* .navBurguerLinks > p::after {
    border-bottom: 1px solid black;
  } */
  .navBurguerLinks > p > svg {
    margin-right: 10px;
  }
`;
