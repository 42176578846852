import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { IDynamicValues } from "../../providers/notifications/notifications.interface";
import { Container } from "./styles";

interface DynamicValuesProps {
  dynamicValues: IDynamicValues[];
  setDynamicValues: React.Dispatch<React.SetStateAction<IDynamicValues[]>>;
  hourRelation: string;
  addNew?: boolean;
}

const DynamicValuesContainer = ({
  dynamicValues,
  setDynamicValues,
  hourRelation,
  addNew = true,
}: DynamicValuesProps) => {
  const [currentLink, setCurrentLink] = useState("");
  const [expirationTimes, setExpirationTimes] = useState<{
    [key: number]: number;
  }>({});

  const addDynamicLink = () => {
    if (currentLink) {
      const newDynamicValues = [
        ...dynamicValues,
        {
          type: `{{EXPIRABLE_LINK_${
            dynamicValues.filter((e) => e.type.includes("EXPIRABLE")).length + 1
          }}}`,
          value: currentLink,
          example: "https://example.com",
          expiration: true,
          expirationTime: 0, // Add expirationTime default value
          cloaking: false, // Add cloaking default value
          redirectLink: "", // Add redirectLink default value
          hours: 0, // Add hours default value
          minutes: 0, // Add minutes default value
        },
      ];
      setDynamicValues(newDynamicValues);
      setExpirationTimes({
        ...expirationTimes,
        [dynamicValues.length]: 0,
      });
      setCurrentLink("");
    }
  };

  const handleExpirationChangeHours = (
    index: number,
    hours: number | string
  ) => {
    const parsedHours = parseInt(hours as string, 10) || 0;
    const newDynamicValues = [...dynamicValues];
    newDynamicValues.filter((e) => e.type.includes("EXPIRABLE"))[index].hours =
      parsedHours;
    setDynamicValues(newDynamicValues);
  };

  const handleExpirationChangeMinutes = (
    index: number,
    minutes: number | string
  ) => {
    const parsedMinutes = parseInt(minutes as string, 10) || 0;
    const newDynamicValues = [...dynamicValues];
    newDynamicValues.filter((e) => e.type.includes("EXPIRABLE"))[
      index
    ].minutes = parsedMinutes;
    setDynamicValues(newDynamicValues);
  };

  const handleCloakingChange = (index: number, cloaking: boolean) => {
    const newDynamicValues = [...dynamicValues];
    newDynamicValues.filter((e) => e.type.includes("EXPIRABLE"))[
      index
    ].cloaking = cloaking;
    setDynamicValues(newDynamicValues);
  };

  const handleRedirectLinkChange = (index: number, link: string) => {
    const newDynamicValues = [...dynamicValues];
    newDynamicValues.filter((e) => e.type.includes("EXPIRABLE"))[
      index
    ].redirectLink = link;
    setDynamicValues(newDynamicValues);
  };

  const removeDynamicLink = (type: string) => {
    const newDynamicValues = dynamicValues.filter((e) => e.type !== type);
    setDynamicValues(newDynamicValues);
  };
  const getHours = (ms: number = 0) => Math.trunc(ms / 3600000);
  const getMinutes = (ms: number = 0) => Math.trunc((ms % 3600000) / 60000);

  return (
    <Container>
      {addNew && (
        <div className="addDynamicLink">
          <TextField
            id="standard-link-input"
            label="Enter Link"
            variant="filled"
            value={currentLink}
            type="url"
            onChange={(e) => setCurrentLink(e.target.value)}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              width: "100%",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            variant="contained"
            sx={{ ml: 1, maxWidth: 100 }}
            onClick={addDynamicLink}
          >
            Add Link
          </Button>
        </div>
      )}

      {dynamicValues
        .filter((e) => e.type.includes("EXPIRABLE"))
        .map((dynamicValue, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div className="dynamicValueList" key={index}>
              <div>
                <div className="typeValueDiv">
                  <p>
                    <b>Type:</b> {dynamicValue.type}
                  </p>
                  <p>
                    <b>Value:</b> {dynamicValue.value}
                  </p>
                </div>
                <div className="hourExpirationDiv">
                  <TextField
                    id="standard-hour"
                    label="hh"
                    variant="filled"
                    type="number"
                    required
                    defaultValue={getHours(dynamicValue.expirationTime)}
                    onChange={(e) =>
                      handleExpirationChangeHours(index, Number(e.target.value))
                    }
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                      maxWidth: 80,
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      "& fieldset": { border: "none" },
                    }}
                  />{" "}
                  and
                  <TextField
                    id="standard-minutes"
                    label="mm"
                    type="number"
                    required
                    defaultValue={getMinutes(dynamicValue.expirationTime)}
                    onChange={(e) =>
                      handleExpirationChangeMinutes(
                        index,
                        Number(e.target.value)
                      )
                    }
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                      ml: 1,
                      maxWidth: 80,
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      "& fieldset": { border: "none" },
                    }}
                  />
                  {hourRelation} the session
                </div>
              </div>
              <div className="redirectCloakingDiv">
                <TextField
                  id="standard-redirect-link"
                  label="Redirect Link"
                  variant="filled"
                  value={dynamicValue.redirectLink}
                  type="url"
                  onChange={(e) =>
                    handleRedirectLinkChange(index, e.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    flexGrow: 1,
                    mr: 1,
                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                />

                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>Cloaking</InputLabel>
                  <Select
                    value={dynamicValue.cloaking ? "true" : "false"}
                    onChange={(e) =>
                      handleCloakingChange(index, e.target.value === "true")
                    }
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <Button
              variant="outlined"
              sx={{ ml: 1, borderRadius: "25px" }}
              disabled={!addNew}
              onClick={() => removeDynamicLink(dynamicValue.type)}
            >
              Remove
            </Button>
          </div>
        ))}
    </Container>
  );
};

export default DynamicValuesContainer;

export const calculateExpirationTime = (dynamicValues: IDynamicValues[]) => {
  dynamicValues.forEach((dynamicValue) => {
    if (
      dynamicValue.expiration &&
      (dynamicValue.hours || dynamicValue.minutes)
    ) {
      if (!dynamicValue.hours) {
        dynamicValue.hours = 0;
      }
      if (!dynamicValue.minutes) {
        dynamicValue.minutes = 0;
      }
      const totalExpirationTime =
        dynamicValue.hours * 3600000 + dynamicValue.minutes * 60000;

      dynamicValue.expirationTime = totalExpirationTime;
    } else {
      dynamicValue.hours = 0;
      dynamicValue.minutes = 0;
      dynamicValue.expirationTime = 0;
    }
  });

  return dynamicValues;
};
