import styled from "styled-components";
import { borderRadius } from "@mui/system";

export const Container = styled.div`
  min-height: 600px;
  background-color: "#fafafa";
  border-radius: "2px";
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  position: relative; // Added to establish stacking context

  .message-header {
    color: #000000;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
  }

  .message-header > svg {
    width: 20px;
    height: 20px;
  }

  form {
    width: 100%;
    position: relative; // Added to establish stacking context
  }

  ul {
    overflow: auto;
    max-height: 500px;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 100%;
  }

  .message-body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    padding-right: 10px;
  }

  .message-body > div {
    max-width: 600px;
    display: flex;
    align-items: flex-start;
  }

  .message-body > div > svg {
    width: 50px;
    align-self: flex-end;
  }

  .messageDeleted {
    text-decoration: line-through;
    color: "lightgray";
  }

  .messageReference {
    background-color: "#fff";
    padding: 5px;
  }

  .toRegistrantMessage {
    background-color: #fafafa;
    border-radius: 26px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    color: #a9a9a9;
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
    padding: 5px;
  }

  .aiButtons > svg:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .chatLink {
    text-decoration: underline;
    color: black;
  }

  .chat-container {
    position: relative;
  }

  .scroll-to-bottom {
    position: absolute;
    bottom: 20px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .emoji-picker-container {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 10px;
    z-index: 999999;
    background-color: white; // Added background color
    border-radius: 8px; // Added border radius
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); // Enhanced shadow

    // Ensure the picker is always on top
    & > div {
      position: relative;
      z-index: 999999;
    }

    // Target the Picker component
    [data-emoji-mart="true"] {
      position: relative;
      z-index: 999999;
    }
  }

  // Make sure the form stays above other content
  .MuiFormControl-root {
    position: relative;
    z-index: 1;
  }

  @media (max-width: 800px) {
    .message-body > div {
      flex-direction: column;
    }

    .message-body > div > svg {
      width: 30px;
      height: 30px;
    }

    .emoji-picker-container {
      right: 0;
      left: 0;
      margin: 0 auto;
      width: fit-content;
    }
  }
`;
