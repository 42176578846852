import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IEvent } from "../../providers/events/events.interface";
import CopyToClipboard from "react-copy-to-clipboard";
import { Container } from "./styles";
import { useState } from "react";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { useEvent } from "../../providers/events";
import { useUser } from "../../providers/users";
import { useNavigate, useParams } from "react-router-dom";
import ModalConfirmClone from "../ModalConfirmClone";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { toast } from "react-hot-toast";
import ModalAddParticipant from "../ModalRegisterParticipant";
interface IProps {
  currentItems: IEvent[];
}

const EventsList = ({ currentItems }: IProps) => {
  const { t } = useTranslation();
  const [cloneId, setCloneId] = useState<string | null>(null);
  const [eventId, setEventId] = useState<string | null>(null);
  const [modalConfirmClone, setModalConfirmClone] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalAddParticipant, setModalAddParticipant] = useState(false);
  const { deleteEvent, cloneEvent } = useEvent();
  const { allEventSessions } = useEventSession();
  const { apiLoading } = useUser();
  const { path } = useParams();
  const navigate = useNavigate();
  return (
    <Container>
      {currentItems && currentItems.length === 0 ? (
        <div className="eventList">
          <h4> {t("no_event_found")}</h4>
        </div>
      ) : (
        <div className="eventList">
          {currentItems &&
            currentItems.map((item, index: number) => (
              <div key={item.id} className="eventContainer">
                <div className="eventInfos">
                  <p className="eventTitle">{item.name}</p>
                  <p className="eventType">
                    {t("description")}: {item.description}
                  </p>
                  <p className="eventType">
                    {t("language")}: {t(item.language)}
                  </p>
                  <p className="eventType">
                    {t("type")}:{" "}
                    {allEventSessions &&
                      formatDuration(
                        allEventSessions.filter(
                          (e: IEventSession) => e.eventId === item.id
                        ).length
                      )}
                  </p>
                </div>
                <div className="buttonGroup">
                  <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    disabled={apiLoading}
                    onClick={() =>
                      navigate(`/${path}/events/${item.id}/update`)
                    }
                  >
                    {t("edit")}
                  </Button>
                  <Button
                    sx={{ borderRadius: "25px" }}
                    disabled={apiLoading}
                    variant="contained"
                    onClick={() => {
                      setCloneId(item.id);
                      setModalConfirmClone(true);
                    }}
                  >
                    {t("clone")}
                  </Button>

                  <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    disabled={apiLoading}
                    onClick={() =>
                      navigate(`/${path}/events/${item.id}/eventsessions`)
                    }
                  >
                    {t("view_sessions")}
                  </Button>
                  {/* <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    disabled={apiLoading}
                  >
                    {t("view_chat_log")}
                  </Button> */}

                  <>
                    <CopyToClipboard
                      onCopy={() => toast.success(t("copied"))}
                      text={`${item.id}`}
                    >
                      <Button
                        sx={{
                          borderRadius: "25px",
                        }}
                        variant="contained"
                      >
                        copy id
                      </Button>
                    </CopyToClipboard>
                  </>
                  <Button
                    sx={{ borderRadius: "25px" }}
                    disabled={apiLoading}
                    variant="contained"
                    onClick={() => {
                      setEventId(item.id);
                      setModalAddParticipant(true);
                    }}
                  >
                    {t("register_participant")}
                  </Button>
                  <Button
                    sx={{
                      borderRadius: "25px",
                    }}
                    variant="outlined"
                    disabled={apiLoading}
                    onClick={() => {
                      setDeleteId(item.id);
                      setModalConfirmDeletion(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </div>
              </div>
            ))}
        </div>
      )}
      {modalConfirmDeletion && deleteId && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteEvent}
        />
      )}
      {modalConfirmClone && cloneId && (
        <ModalConfirmClone
          cloneId={cloneId}
          setModalConfirmClone={setModalConfirmClone}
          cloneFunction={cloneEvent}
        />
      )}
      {modalAddParticipant && eventId && (
        <ModalAddParticipant
          eventId={eventId}
          setModalAddParticipant={setModalAddParticipant}
        />
      )}
    </Container>
  );
};

export default EventsList;

function formatDuration(numberOfDays: number): string {
  if (numberOfDays === 1) {
    return "Single-day";
  } else if (numberOfDays > 1) {
    return `Multi-day(${numberOfDays} days)`;
  } else if (numberOfDays === 0) {
    return `No session created.`;
  } else {
    return "Invalid number of days";
  }
}
