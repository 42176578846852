import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
interface IProps {
  email: string;
}
const PasswordRecoveryForm = ({ email }: IProps) => {
  const { resetPassword, apiLoading, Logout, sendPasswordToken } = useUser();

  const { t } = useTranslation();
  const [countResend, setCountResent] = useState<number>(
    JSON.parse(window.localStorage.getItem("countTimer") as any) === true
      ? 60
      : 0
  );
  useEffect(() => {
    if (countResend === 60) {
      setTimeout((event) => {
        window.localStorage.removeItem("resendCount");
      }, 61000);
    }
  }, []);
  const sendSubmit = async (event: any) => {
    await sendPasswordToken({ email });
    window.localStorage.setItem("countTimer", true as any);
    setCountResent(60);
    setTimeout((event) => {
      window.localStorage.removeItem("resendCount");
    }, 61000);
  };
  useEffect(() => {
    const timer =
      countResend > 0 &&
      setInterval(() => setCountResent(countResend - 1), 1000);
    return () => clearInterval(timer as any);
  }, [countResend]);

  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    data.email = email;
    await resetPassword(data);
  };

  const formSchema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required(t("password_required"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
          t("password_matches")
        ),
      confirm: yup
        .string()
        .required(t("confirm_required"))
        .oneOf([yup.ref("password"), null], t("confirm_mathces")),
      code: yup.string().required(t("recovery_code_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className=" passwordRecoveryHeader">
        <h2>{t("password_recovery")}</h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message?.toString()}
          id="standard-senha"
          label={t("password")}
          variant="standard"
          type="password"
          {...register("password")}
        />
        <TextField
          id="standard-confirm"
          error={errors.confirm === undefined ? false : true}
          helperText={errors.confirm?.message?.toString()}
          label={t("confirm_password")}
          variant="standard"
          type="password"
          {...register("confirm")}
        />
        <TextField
          id="standard-code"
          error={errors.code === undefined ? false : true}
          helperText={errors.code?.message?.toString()}
          label={t("recovery_code")}
          variant="standard"
          type="text"
          {...register("code")}
        />
        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("confirm")}
            </Button>
            <Button
              sx={{ mx: 1 }}
              variant={"contained"}
              disabled={countResend > 0 ? true : false}
              id="btnResend"
              onClick={(event) => sendSubmit(event)}
              className="resend"
            >
              {t("resend_code")}{" "}
              {countResend > 0 ? "(" + countResend + ")" : ""}
            </Button>

            <p className="logoutLink" onClick={() => Logout()}>
              {t("login_different_account")}
            </p>
          </div>
        )}
      </form>
    </Container>
  );
};

export default PasswordRecoveryForm;
