import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 5px;

  .activationMessage {
    font-size: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
  }
  .activation {
    background: ${({ theme }) => theme.colors.primary};
  }

  /* .buttonDisabled {
    background: gray;
  } */
  .activation:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
  /* .buttonDisabled:hover {
    background-color: gray !important;
    cursor: not-allowed;
  } */
  .logoutLink {
    color: ${({ theme }) => theme.colors.gray};
    text-decoration: underline;
    text-align: center;
    margin-top: 10px;
  }
  .logoutLink:hover {
    cursor: pointer;
    color: grey;
  }
  @media (min-width: 1100px) {
    button {
      width: 80%;
      height: 40px;
    }
  }
`;
