import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { Container } from "./styles";

import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { useNotification } from "../../providers/notifications";
import { IWhatsappTemplate } from "../../providers/notifications/notifications.interface";
import ModalCreateWhatsappTemplate from "../ModalAddWhatsappTemplate";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalShowWhatsappMessage from "../ModalShowWhatsappMessage/index";

export const WhatsappTemplateContainer = () => {
  const [deleteId, setDeleteId] = useState("");
  const { apiLoading } = useUser();
  const [WhatsappTemplateName, setWhatsappTemplateName] = useState("");
  const [modalCreateWhatsappTemplate, setModalCreateWhatsappTemplate] =
    useState(false);

  const [modalMessage, setModalMessage] = useState<IWhatsappTemplate | null>(
    null
  );
  const [modalShowWhatsappMessage, setModalShowWhatsappMessage] =
    useState(false);

  const {
    getAllWhatsappTemplates,
    allWhatsappTemplates,
    deleteWhatsappTemplate,
  } = useNotification();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const filteredWhatsappTemplates: IWhatsappTemplate[] = allWhatsappTemplates
    ? allWhatsappTemplates.filter(
        (e: IWhatsappTemplate) =>
          e.name && e.name.includes(WhatsappTemplateName)
      )
    : [];

  useEffect(() => {
    if (
      !allWhatsappTemplates ||
      allWhatsappTemplates.some((e: IWhatsappTemplate) =>
        ["received", "pending"].includes(e.status)
      )
    ) {
      getAllWhatsappTemplates();
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Template Name",
      width: 300,
    },
    {
      field: "content_type",
      headerName: "Content Types",
      width: 160,
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
    },
    {
      field: "language",
      headerName: "Language",
      width: 160,
    },
    {
      field: "message",
      headerName: "Message",
      width: 160,

      renderCell: (params: GridRenderCellParams<any>) => (
        <p
          className="messageClick"
          onClick={() => {
            setModalMessage(params.row);
            setModalShowWhatsappMessage(true);
          }}
        >
          {params.formattedValue}
        </p>
      ),
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt.split("T")[0],
      width: 160,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 160,

      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="userActions">
          <IoMdClose
            size={28}
            onClick={() => {
              setDeleteId(params.row.id);
              setModalConfirmDeletion(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Container>
        <section className="filterSection">
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search event">
                    {<FaSearch />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="standard-search"
            label="Search for WhatsappTemplate"
            type={"text"}
            value={WhatsappTemplateName}
            onChange={(e) => setWhatsappTemplateName(e.target.value)}
            variant="filled"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              width: 332,
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            variant="outlined"
            sx={{ borderRadius: 25 }}
            disabled={apiLoading}
            onClick={() => setModalCreateWhatsappTemplate(true)}
          >
            Add Whatsapp Template
          </Button>
        </section>
        {apiLoading ? (
          <Loading />
        ) : (
          <>
            {allWhatsappTemplates && (
              <DataGrid
                rows={filteredWhatsappTemplates}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                sx={{
                  mt: 3,
                  border: 0,
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
                  padding: 1,
                  maxHeight: 1000,
                }}
                pageSizeOptions={[5, 15, 50, 100]}
                disableRowSelectionOnClick
              />
            )}
          </>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteFunction={deleteWhatsappTemplate}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={deleteId}
        />
      )}
      {modalCreateWhatsappTemplate && (
        <ModalCreateWhatsappTemplate
          setModalCreateWhatsappTemplate={setModalCreateWhatsappTemplate}
        />
      )}

      {modalShowWhatsappMessage && modalMessage && (
        <ModalShowWhatsappMessage
          template={modalMessage}
          setModalShowWhatsappMessage={setModalShowWhatsappMessage}
        />
      )}
    </>
  );
};
