import styled from "styled-components";

export const Container = styled.div`
  .eventContainer {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: space-evenly;
    padding: 15px;
    min-height: 156px;
    border-radius: 2px;
    box-shadow: rgba(3, 3, 3, 0.1) 2px 0px 10px;
  }
  .eventInfos {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .eventTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-transform: capitalize;
  }
  .filterSection {
    display: flex;
    flex-direction: column;
    max-width: 343px;
    gap: 10px;
  }
  .eventType {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  .buttonGroup {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .eventList {
    margin-top: 15px;
    min-height: 60vh;
  }
  @media (min-width: 800px) {
    .eventTitle {
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .eventType {
      font-size: 16px;
    }
  }
`;
