import styled from "styled-components";

export const Container = styled.div`
  margin-top: 25px;

  section {
    padding: 20px;
    margin-top: 10px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid lightgray;
    border-radius: 10px;
  }
  section > p > svg {
    margin-right: 8px;
  }
  section > p {
    display: flex;
    align-items: center;
  }
`;
