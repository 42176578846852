import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services";
import { useUser } from "../users";
import { useLocation, useNavigate } from "react-router-dom";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { ICreateEvent, IEvent, IUpdateEvent } from "./events.interface";
import { useTenant } from "../tenant";
import { useEventSession } from "../eventSessions";

export const EventContext = createContext<any | null>(null);

export const EventProvider = ({ children }: IProviderProps) => {
  const [allEvents, setAllEvents] = useState<IEvent[] | null>(null);
  const navigate = useNavigate();
  const { getAllEventSessions } = useEventSession();
  const { token, setApiLoading, setPageMounting } = useUser();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const getAllEvents = () => {
    setPageMounting(true);
    api
      .get("/events", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllEvents(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        setPageMounting(false);
        console.log(err.response.data.message);
      });
  };
  const createEvent = async (data: ICreateEvent) => {
    setApiLoading(true);

    await api
      .post(
        "/events",
        {
          name: data.name,
          description: data.description,
          language: data.language,
          eventType: data.eventType,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        getAllEvents();
        setApiLoading(false);
        navigate(`/${path}/events/${res.data.id}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };

  const cloneEvent = async (id: string) => {
    setApiLoading(true);

    await api
      .post(
        "/events/clone/" + id,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        getAllEvents();
        getAllEventSessions(false);
        setApiLoading(false);
        navigate(`/${path}/events`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };

  const editEvent = async (id: string, data: IUpdateEvent) => {
    setApiLoading(true);

    await api
      .patch(`/events/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllEvents();
        setApiLoading(false);
        navigate(`/${path}/events`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteEvent = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/events/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllEvents();
        navigate(`/${path}/events`);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  return (
    <EventContext.Provider
      value={{
        allEvents,
        setAllEvents,
        createEvent,
        editEvent,
        deleteEvent,
        getAllEvents,
        cloneEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => useContext(EventContext);
