import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { BsGraphUp, BsPeople } from "react-icons/bs";
import { IoSchoolOutline, IoSettingsOutline } from "react-icons/io5";
import {
  MdOutlineAnalytics,
  MdOutlineAttachMoney,
  MdOutlinePersonSearch,
  MdOutlineReceiptLong,
} from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/color-spp.png";
import { LANGUAGES } from "../../constants/index";
import { useUser } from "../../providers/users";
import Stars from "../Stars";
import { Container } from "./styles";
import { RiTeamLine } from "react-icons/ri";
import { TbReportMoney } from "react-icons/tb";
import { FaRobot } from "react-icons/fa";

interface IProps {
  setOpenNavBurguer: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigationBurguer = ({ setOpenNavBurguer }: IProps) => {
  const { user, Logout, verifyRolePermission, roleAdmin, roleFinance } =
    useUser();
  const navigate = useNavigate();
  const { path } = useParams();
  const { i18n, t } = useTranslation();
  const redirectAndClose = (toPath: string) => {
    setOpenNavBurguer(false);
    navigate(toPath);
  };
  const onChangeLang = (e: any) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  return (
    <Container>
      <div className="headerBurguerMenu">
        <AiOutlineClose onClick={() => setOpenNavBurguer(false)} />
      </div>
      <div className="navBurguerImage">
        <img src={logo} alt="SPP Logo" className="navBarLogo" />
      </div>

      <section className="navLinksContainer">
        <section className="navBurguerLinks">
          <FormControl>
            <InputLabel sx={{ ml: 2, mt: 2 }} id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              sx={{
                ml: 2,
                mt: 2,
              }}
              fullWidth={true}
              labelId="demo-simple-select-label-status"
              id="demo-simple-select"
              value={i18n?.language}
              onChange={onChangeLang}
              label={t("language")}
            >
              {LANGUAGES.map(({ code, label }) => (
                <MenuItem key={code} value={code}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p
            onClick={() => {
              redirectAndClose("/" + path + "/dashboard");
            }}
          >
            <AiOutlineHome /> {t("dashboard")}
          </p>
          <p onClick={() => redirectAndClose("/" + path + "/events")}>
            <MdOutlineAttachMoney /> {t("events")}
          </p>

          <p onClick={() => redirectAndClose("/" + path + "/analytics")}>
            <MdOutlineAnalytics /> Analytics
          </p>

          {user && verifyRolePermission(roleAdmin) && (
            <p onClick={() => redirectAndClose("/" + path + "/settings")}>
              <IoSettingsOutline /> {t("settings")}
            </p>
          )}
          <p onClick={() => Logout()}>
            <BiLogOut /> {t("logout")}
          </p>
        </section>
      </section>
    </Container>
  );
};

export default NavigationBurguer;
