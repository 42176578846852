import EventsContainer from "../../components/EventContainer";
import { useEvent } from "../../providers/events";

const Events = () => {
  const { allEvents } = useEvent();
  return (
    <>
      <EventsContainer />
    </>
  );
};

export default Events;
