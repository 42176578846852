import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField, IconButton, Box } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../providers/tenant";
import { Add, Remove } from "@mui/icons-material";

const RandomNamesListUpdateForm = () => {
  const { apiLoading } = useUser();
  const { updateTools, tenant } = useTenant();
  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    randomNamesList: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .required(t("field_required"))
            .max(128, t("name_too_long")),
          listNames: yup
            .string()
            .required(t("field_required"))
            .test("is-comma-separated", t("must_be_comma_separated"), (value) =>
              value.split(",").every((item) => item.trim() !== "")
            ),
        })
      )
      .required(),
  });

  type FormValues = yup.InferType<typeof formSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      randomNamesList: tenant.tools?.randomNamesList || [
        { name: "", listNames: "" },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "randomNamesList",
  });

  const onSubmit = async (data: FormValues) => {
    await updateTools({ randomNamesList: data.randomNamesList });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("Random Names List")}</h3>
        {fields.map((field, index) => (
          <Box key={field.id} mb={2} display="flex" flexDirection="column">
            <TextField
              error={!!errors.randomNamesList?.[index]?.name}
              helperText={errors.randomNamesList?.[index]?.name?.message}
              defaultValue={field.name}
              label={t("List Name")}
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
                marginBottom: 1,
              }}
              {...register(`randomNamesList.${index}.name`)}
            />
            <TextField
              error={!!errors.randomNamesList?.[index]?.listNames}
              helperText={errors.randomNamesList?.[index]?.listNames?.message}
              defaultValue={field.listNames}
              label={t("Names (comma-separated)")}
              variant="filled"
              multiline
              minRows={3}
              maxRows={10}
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
                marginBottom: 1,
              }}
              {...register(`randomNamesList.${index}.listNames`)}
            />
            <IconButton
              onClick={() => remove(index)}
              sx={{ alignSelf: "flex-end" }}
            >
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button
          onClick={() => append({ name: "", listNames: "" })}
          sx={{ marginBottom: "10px" }}
          variant="outlined"
          startIcon={<Add />}
        >
          {t("Add Random Names List")}
        </Button>

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              {t("save")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default RandomNamesListUpdateForm;
