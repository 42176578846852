import styled from "styled-components";

export const Container = styled.header`
  height: 12vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: "#fafafa";
  box-shadow: rgba(3, 3, 3, 0.1) 2px -2px 10px;
  text-align: center;
  p {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  a {
    margin-right: 5px;
    color: black;
    text-decoration: none;
  }
  @media (min-width: 800px) {
    /* display: none; */
  }
`;
