import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTenant } from "../../providers/tenant";
import path from "path";

const ChangePasswordForm = () => {
  const { forceChangePassword, apiLoading, Logout, user } = useUser();
  const { path } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const email = window.localStorage.getItem("EPA-USER-EMAIL") || "";
  const sessionToken =
    window.localStorage.getItem("EPA-PASSWORD-SESSION") || "";

  const { tenantByPath } = useTenant();
  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    if (!email) {
      navigate(`/${path}`);
      return;
    }
    data.tenantId = tenantByPath.id;
    data.email = email;
    data.sessionToken = sessionToken;
    await forceChangePassword(data);
  };

  const formSchema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required(t("password_required"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
          t("password_matches")
        ),
      confirm: yup
        .string()
        .required(t("confirm_required"))
        .oneOf([yup.ref("password"), null], t("confirm_mathces")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className="passwordRecoveryHeader">
        <h2>Change your password</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message?.toString()}
          id="standard-senha"
          label={t("password")}
          variant="standard"
          type="password"
          {...register("password")}
        />
        <TextField
          id="standard-confirm"
          error={errors.confirm === undefined ? false : true}
          helperText={errors.confirm?.message?.toString()}
          label={t("confirm_password")}
          variant="standard"
          type="password"
          {...register("confirm")}
        />

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("confirm")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default ChangePasswordForm;
