import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Tab,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import toast from "react-hot-toast"; // Make sure to install react-hot-toast
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useTenant } from "../../providers/tenant";
import ExpirableLinks from "../ModalCreateExpirableLink";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(2),
}));

const LinkActionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: theme.spacing(1),
}));

interface ModalSessionLinksProps {
  session: IEventSession;
  setModalSessionLinks: (open: boolean) => void;
}

const ModalSessionLinks: React.FC<ModalSessionLinksProps> = ({
  session,
  setModalSessionLinks,
}) => {
  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState("0");
  const { tenant, getTenant } = useTenant();

  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, [tenant, getTenant]);

  const handleClose = () => {
    setOpen(false);
    setModalSessionLinks(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const directLiveLink = tenant
    ? `${tenant.settings.domainName}/event/direct/${session.id}`
    : "";

  const directReplayLink = tenant
    ? `${tenant.settings.domainName}/event/direct/replay/${session.id}`
    : "";

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success("Link copied to clipboard");
    });
  };

  const handleOpenLink = (link: string) => {
    console.log(link);
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const LinkItem = ({ title, link }: { title: string; link: string }) => (
    <StyledListItem>
      <ListItemText
        primary={title}
        secondary={link}
        secondaryTypographyProps={{ style: { wordBreak: "break-all" } }}
      />
      <LinkActionsContainer>
        <Button
          startIcon={<ContentCopyIcon />}
          onClick={() => handleCopyLink(link)}
          size="small"
        >
          Copy
        </Button>
        {/* <a href={link} target="_blank">
          <Button startIcon={<OpenInNewIcon />} size="small">
            Open
          </Button>
        </a> */}
      </LinkActionsContainer>
    </StyledListItem>
  );

  const tabs = [
    {
      name: "Direct Live",
      component: (
        <List>
          <LinkItem title="Direct Live Link" link={directLiveLink} />
        </List>
      ),
    },
    {
      name: "Direct Replay",
      component: (
        <List>
          <LinkItem title="Direct Replay Link" link={directReplayLink} />
        </List>
      ),
    },
    {
      name: "Expirable Links",
      component: (
        <ExpirableLinks
          sessionId={session.id}
          StyledListItem={StyledListItem}
          LinkItem={LinkItem}
        />
      ),
    },
  ];

  return (
    <StyledDialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <StyledDialogTitle>
        Session Links
        <IconButton aria-label="close" onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent dividers>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="session links tabs">
              {tabs.map((tab, index) => (
                <Tab label={tab.name} value={index.toString()} key={index} />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel value={index.toString()} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
    </StyledDialog>
  );
};

export default ModalSessionLinks;
