import styled from "styled-components";

export const Container = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 90%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.colors.background};
  .tenantFindMessage {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    .tenantFindMessage {
      font-size: 1rem;
    }
  }
`;
