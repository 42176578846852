import { useTranslation } from "react-i18next";
import { FaRobot } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import { Container } from "./styles";

const OpenAIMessage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { path } = useParams();
  return (
    <Container>
      <PageHeader
        title={t("assistants")}
        description={t("assistants")}
        icon={<FaRobot />}
      />
      <p>
        {t("sales_copilot_not_setted_admin")}{" "}
        <span
          className="openAiMessage"
          onClick={() => navigate(`/${path}/settings`)}
        >
          {t("click_here")}
        </span>
      </p>
    </Container>
  );
};

export default OpenAIMessage;
