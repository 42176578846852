import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 5px;
  .bigBox {
    width: 100%;
    display: flex;
    gap: 15px;
  }
  .leftDiv {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 20px;
  }
  .rightDiv {
    width: 40%;
  }
  .automatedEvents {
    width: 40%;
    height: 192px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 15px;
    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  }
  .automatedEvents > p {
    color: #030303;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 18px;
  }
  .automatedEvents > div {
    display: flex;
    gap: 15px;
  }
  .automatedEvents > div > svg {
    width: 72px;
    height: 75px;
    background-color: #030303;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 2px;
    color: #ffffff;
  }
  .automatedEvents > div > div > p {
    color: #030303;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 24px;
  }

  .automatedEvents > div > div > span {
    color: #030303;
    font-size: 48px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 48px;
  }
  .smallBoxes {
    display: flex;
    gap: 10px;
    width: 60%;
  }
  .smallBoxes > div {
    width: 50%;
    height: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px;
    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  }
  .smallBoxes > div > svg {
    width: 50px;
    height: 50px;
    background-color: #030303;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    color: #ffffff;
  }
  .smallBoxes > div > p {
    color: #030303;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 24px;
  }

  .smallBoxes > div > span {
    color: #030303;
    font-size: 48px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }

  .registrantionMetrics {
    width: 100%;
    display: flex;
    gap: 15px;
    margin-top: 24px;
  }
  .registrantionSources {
    width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 15px;
    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  }
  .registrantionSources > p {
    color: #030303;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 24px;
  }
  .sourceCards {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 16px;
    align-items: center;
  }
  .outlineBar {
    width: 65%;
    height: 20px;
    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  }
  .sourceCards > span {
    color: #030303;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 14px;
  }
  .sourceCards > p {
    white-space: nowrap;
    text-transform: capitalize;
    overflow: hidden;

    text-overflow: ellipsis;
    color: #030303;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 14px;
  }
  .inlineBar {
    height: 20px;
    background-color: #030303;
    border-radius: 2px;
  }
  .rightDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 20px;
  }
  .rightDiv > h3 {
    padding: 0px 10px 10px 10px;
  }
  .eventsList {
    /* min-height: 620px; */
    flex-grow: 1;
  }
  .eventItem {
    min-height: 100px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    margin-bottom: 24px;
  }
  .eventTileAndLogo {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .eventTileAndLogo > svg {
    width: 50px;
    height: 50px;
    background-color: #030303;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    color: #ffffff;
  }

  .eventTileAndLogo > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 8px;
  }
  .eventTileAndLogo > div > p {
    color: #030303;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }
  .eventTileAndLogo > div > span {
    color: #030303;
    font-size: 12px;
    font-family: "Roboto";
    line-height: 14px;
    text-transform: capitalize;
  }
  .eventItem > p {
    color: #030303;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  .teamMembersList {
    width: 100%;
    height: 200px;
    background-color: #111111;
    border-radius: 2px;
    padding: 0px 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
  }
  .teamMembersList > p {
    color: #ffffff;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 18px;
  }
  .membersList {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    width: 100%;
  }
  .membersList::-webkit-scrollbar {
    height: 8px;
    background-color: #444;
    border-radius: 4px;
  }

  .membersList::-webkit-scrollbar-thumb {
    background-color: #000;

    border-radius: 4px;
  }
  .userAvatar {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    flex-direction: column;
    width: 60px;
  }
  .userAvatar > svg {
    width: 60px;
    height: 60px;
    color: #fff;
  }
  .userAvatar > p {
    color: #ffffff;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.5;
    text-transform: capitalize;
  }

  .filterSection {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }
  .filterSection > div {
    display: flex;
    gap: 10px;
  }
  .filterSection > div > p:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  @media (max-width: 700px) {
    flex-direction: column;

    .automatedEvents,
    .rightDiv,
    .leftDiv,
    .smallBoxes {
      width: 100%;
    }
  }
`;
