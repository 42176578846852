import { EventSessionProvider } from "./eventSessions";
import { EventProvider } from "./events";
import { NotificationProvider } from "./notifications";
import { RegistrantEventProvider } from "./registrantEvents";
import { RoleProvider } from "./roles";
import { SalesCopilotProvider } from "./salesCopilot";
import { TagProvider } from "./tags";
import { TenantProvider } from "./tenant";
import { UserProvider } from "./users";

interface IProvidersProps {
  children: React.ReactNode;
}

const Providers = ({ children }: IProvidersProps) => {
  return (
    <UserProvider>
      <TenantProvider>
        <RoleProvider>
          <SalesCopilotProvider>
            <EventSessionProvider>
              <EventProvider>
                <RegistrantEventProvider>
                  <NotificationProvider>
                    <TagProvider>{children}</TagProvider>
                  </NotificationProvider>
                </RegistrantEventProvider>
              </EventProvider>
            </EventSessionProvider>
          </SalesCopilotProvider>
        </RoleProvider>
      </TenantProvider>
    </UserProvider>
  );
};

export default Providers;
