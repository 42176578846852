import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormHelperText,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../providers/tenant";
import { useState } from "react";
import { useNotification } from "../../providers/notifications";
import { Add, Remove } from "@mui/icons-material";

const WebhookUpdateForm = () => {
  const { sendTestSMS } = useNotification();
  const { apiLoading } = useUser();
  const { updateIntegrations, tenant } = useTenant();
  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    webhooks: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(t("field_required")),
          link: yup
            .string()
            .required(t("field_required"))
            .url(t("field_invalid_url")),
        })
      )
      .required(),
  });

  type FormValues = yup.InferType<typeof formSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      webhooks: tenant.integrations.webhooks || [{ name: "", link: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "webhooks",
  });

  const onSubmit = async (data: FormValues) => {
    await updateIntegrations({ webhooks: data.webhooks });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Webhook</h3>
        {fields.map((field, index) => (
          <Box key={field.id} mb={2} display="flex" alignItems="center">
            <TextField
              error={!!errors.webhooks?.[index]?.name}
              helperText={errors.webhooks?.[index]?.name?.message}
              defaultValue={field.name}
              label={t("Webhook Name")}
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
                marginRight: 1,
              }}
              {...register(`webhooks.${index}.name`)}
            />
            <TextField
              error={!!errors.webhooks?.[index]?.link}
              helperText={errors.webhooks?.[index]?.link?.message}
              defaultValue={field.link}
              label={t("Webhook Link")}
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
                marginRight: 1,
              }}
              {...register(`webhooks.${index}.link`)}
            />
            <IconButton onClick={() => remove(index)}>
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button
          onClick={() => append({ name: "", link: "" })}
          sx={{ marginBottom: "10px" }}
          variant="outlined"
          startIcon={<Add />}
        >
          {t("Add Webhook")}
        </Button>

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              {t("save")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default WebhookUpdateForm;
