import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;
