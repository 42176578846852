import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTenant } from "../../providers/tenant";
import { IUser } from "../../providers/users/user.interface";
import ModalChangeRole from "../ModalChangeRole";
import { Container } from "./styles";

import { FaSearch } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalCreateUser from "../ModalCreateUser";
export const UsersContainer = () => {
  const { allUsers, getTenantUsers, tenant, deleteUser } = useTenant();
  const { apiLoading } = useUser();
  const [modalChangeRole, setModalChangeRole] = useState(false);
  const [userToUpdateId, setUserToUpdateId] = useState("");
  const [userName, setUserName] = useState("");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const filteredUsers: IUser[] = allUsers
    ? allUsers.filter((e: IUser) => e.email.includes(userName))
    : [];

  useEffect(() => {
    if (!allUsers) {
      getTenantUsers();
    }
  }, []);
  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.roles.map((u: any) => u.name).join(", "),
      editable: false,
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt.split("T")[0],
      width: 250,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="userActions">
          {params.row.email !== tenant.email && (
            <>
              <MdEdit
                size={28}
                onClick={() => {
                  setUserToUpdateId(params.row.id);
                  setModalChangeRole(true);
                }}
              />
              <IoMdClose
                size={28}
                onClick={() => {
                  setModalConfirmDeletion(true);
                  setDeleteId(params.row.id);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Container>
        <section className="filterSection">
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search event">
                    {<FaSearch />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="standard-search"
            label="Search for user"
            type={"text"}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            variant="filled"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            sx={{
              borderRadius: "25px",
            }}
            variant="outlined"
            disabled={apiLoading}
            onClick={() => setModalCreateUser(true)}
          >
            Add User
          </Button>
        </section>
        {apiLoading ? (
          <Loading />
        ) : (
          <>
            {allUsers && (
              <DataGrid
                rows={filteredUsers}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                sx={{
                  mt: 3,
                  border: 0,
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
                  padding: 1,
                  maxHeight: 1000,
                }}
                pageSizeOptions={[5, 15, 50, 100]}
                disableRowSelectionOnClick
              />
            )}
          </>
        )}
      </Container>
      {modalCreateUser && (
        <ModalCreateUser setModalCreateUser={setModalCreateUser} />
      )}
      {modalChangeRole && (
        <ModalChangeRole
          ModalChangeRole={modalChangeRole}
          setModalChangeRole={setModalChangeRole}
          userToUpdateId={userToUpdateId}
        />
      )}
      {deleteId && modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteUser}
        />
      )}
    </>
  );
};
