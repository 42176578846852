import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;
