import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { IAssistantFile } from "../../providers/salesCopilot/salesCopilot.interface";
interface IProps {
  setModalUpdateAiTrainingFile: React.Dispatch<React.SetStateAction<boolean>>;
  file: IAssistantFile;
  assistantId: string;
}
const ModalUpdateAiTrainingFile = ({
  setModalUpdateAiTrainingFile,
  file,
  assistantId,
}: IProps) => {
  const { apiLoading } = useUser();
  const { updateAiTrainingFile } = useSalesCopilot();

  const { t } = useTranslation();

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);
    await updateAiTrainingFile(file.id, assistantId, data);
    setModalUpdateAiTrainingFile(false);
  };
  const formSchema = yup
    .object()
    .shape({
      question: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
      answer: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <Container>
        <div className="modalContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modalHeader">
              <h3>Update AI Training File</h3>
              <div className="modalButtonsHeader">
                <Button
                  variant="outlined"
                  size="large"
                  disabled={apiLoading}
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => setModalUpdateAiTrainingFile(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  disabled={apiLoading}
                  sx={{ mr: 1, borderRadius: "25px" }}
                  type="submit"
                >
                  save
                </Button>
              </div>
            </div>
            <TextField
              error={errors.question === undefined ? false : true}
              helperText={errors.question?.message?.toString()}
              id="standard-question"
              defaultValue={file.question}
              label="Question"
              variant="standard"
              {...register("question")}
            />
            <TextField
              id="standard-answer"
              error={errors.answer === undefined ? false : true}
              helperText={errors.answer?.message?.toString()}
              label="Answer"
              defaultValue={file.answer}
              variant="standard"
              {...register("answer")}
            />
          </form>
        </div>
      </Container>
    </>
  );
};

export default ModalUpdateAiTrainingFile;
