import { TagsContainer } from "../../components/TagsList";

const TagsPage = () => {
  return (
    <>
      {" "}
      <TagsContainer />{" "}
    </>
  );
};

export default TagsPage;
