import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import "dayjs/locale/pt-br";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
const UserUpdateForm = () => {
  const { user, apiLoading, updateUser } = useUser();
  const { path } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null || data[key] === user[key]) {
        delete data[key];
      }
    });
  }

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);

    await updateUser(data);
  };

  const formSchema = yup
    .object()
    .shape({
      first_name: yup.string().max(128, t("first_name_max")),
      last_name: yup.string().max(128, t("last_name_max")),
      email: yup.string().email(t("email_invalid")),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,

      email: user.email,
    },
  });
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  return (
    <Container>
      <PageHeader
        title={t("update_user")}
        description={`${t("fill_update")} ${t("profile")}`}
        icon={<IoCreateOutline />}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.first_name === undefined ? false : true}
          helperText={errors.first_name?.message?.toString()}
          id="standard-name"
          label={t("name")}
          variant="standard"
          defaultValue={user.address?.country}
          {...register("first_name")}
        />

        <TextField
          defaultValue={user.last_name}
          error={errors.last_name === undefined ? false : true}
          helperText={errors.last_name?.message?.toString()}
          id="standard-last_name"
          label={t("last_name")}
          variant="standard"
          {...register("last_name")}
        />

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button type="submit" variant="contained">
              {t("confirm")}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => navigate("/" + path + "/profile")}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default UserUpdateForm;
