import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useTranslation } from "react-i18next";
import RandomNamesListUpdateForm from "../RandomNamesListUpdateForm";
export default function ToolsSettingsContainer() {
  const [value, setValue] = React.useState("0");

  const { t } = useTranslation();
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    {
      name: "Random names list",
      component: <RandomNamesListUpdateForm />,
    },
  ];
  return (
    <>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            color: "black",
            mb: 5,
          }}
        >
          <TabList
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {tabs.map((tab: ITab, index: number) => (
              <Tab
                value={index.toString()}
                key={index + tab.name}
                label={tab.name}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <div>
        <React.Fragment>
          {tabs[Number(value)].component}
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleComplete} sx={{ mr: 1 }}>
              Next
            </Button>
          </Box> */}
        </React.Fragment>
      </div>
    </>
  );
}

interface ITab {
  component: React.ReactNode;
  name: string;
}
