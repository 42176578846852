import styled from "styled-components";

export const Container = styled.div`
  margin-top: 50px;
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  form > label {
    margin-top: 10px;
  }

  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  h3 {
    margin-bottom: 20px;
  }

  section {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 10px;
    }

    div {
      margin-bottom: 10px;
    }
  }
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .color-swatch {
    width: 36px;
    height: 36px;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #ccc;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;

export const ColorSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .color-picker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(33.33% - 14px);
    margin-bottom: 20px;

    @media (max-width: 768px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;
