import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useUser } from "../users";
import {
  IAssistants,
  ICreateAssistant,
  ICreateThread,
  IThread,
  ITrainAssistant,
  IUpdateAssistant,
} from "./salesCopilot.interface";
import { useTenant } from "../tenant";

export const SalesCopilotContext = createContext<any | null>(null);

export const SalesCopilotProvider = ({ children }: IProviderProps) => {
  const [allAssistants, setAllAssistants] = useState<IAssistants[] | null>(
    null
  );
  const { getTenant } = useTenant();
  const { token, setApiLoading, setPageMounting } = useUser();
  const [loadingAI, setLoadingAI] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const getAllAssistants = async () => {
    setLoadingAI(true);
    getTenant();
    await api
      .get("/assistants", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllAssistants(res.data);
        setLoadingAI(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setLoadingAI(false);
      });
  };

  const createAssistant = async (data: ICreateAssistant) => {
    setApiLoading(true);
    let dataFile: File[];
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = Object.values(data.fileInput);
    }
    delete data.fileInput;

    setApiLoading(true);
    let dataProfileFile: FormData;
    if (data.assistantProfileFile && data.assistantProfileFile.length !== 0) {
      dataProfileFile = new FormData();
      dataProfileFile.append("file", data.assistantProfileFile[0]);
    }
    delete data.assistantProfileFile;

    const created = await api
      .post("/assistants", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        for (let i = 0; i < dataFile.length; i++) {
          await uploadFileAssistants(res.data.id, dataFile[i]);
        }
        if (dataFile) {
          await api
            .post(`/assistants/${res.data.id}/image`, dataProfileFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res.data)
            .catch((err) => {
              setApiLoading(false);
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            });
        }

        setApiLoading(false);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });
    if (created) {
      getAllAssistants();
    }
    return;
  };

  const uploadFileAssistants = async (
    assistantId: string,
    data: File,
    reload: boolean = false
  ) => {
    const dataFile = new FormData();
    dataFile.append("file", data);

    if (reload) setApiLoading(true);
    await api
      .post(`/assistants/${assistantId}/file`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        if (reload) {
          getAllAssistants();
          setApiLoading(false);
          toast.success(t("file_uploaded"));
        }
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const updateAssistant = async (id: string, data: IUpdateAssistant) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.assistantProfileFile && data.assistantProfileFile.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.assistantProfileFile[0]);
    }
    delete data.assistantProfileFile;
    await api
      .patch(`/assistants/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/assistants/${id}/image`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res.data)
            .catch((err) => {
              setApiLoading(false);
              console.log(err);
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            });
        }
        getAllAssistants();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteAssistant = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/assistants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllAssistants();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const deleteAssistantFile = async (assistant_id: string, file_id: string) => {
    setApiLoading(true);
    await api
      .delete(`/assistants/${assistant_id}/${file_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        // getAllAssistants();
        if (allAssistants) {
          setAllAssistants(
            allAssistants.map((assistant: any) => {
              assistant.files = assistant.files.filter((file: any) => {
                return file.id !== file_id;
              });
              return assistant;
            })
          );
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const trainAssistant = async (id: string, data: ITrainAssistant) => {
    await api
      .post(`/assistants/train/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success("Question sent to Assistant to improve the answers");
        if (allAssistants) {
          setAllAssistants(
            allAssistants.map((i: any) => {
              if (i.id === res.data.assistantId) {
                i.files = [...i.files, { ...res.data }];
                return i;
              } else {
                return i;
              }
            })
          );
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const updateAiTrainingFile = async (
    id: string,
    assistantId: string,
    data: ITrainAssistant
  ) => {
    setApiLoading(true);
    await api
      .post(`/assistants/train/${assistantId}/file/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allAssistants) {
          setAllAssistants(
            allAssistants.map((assistant: any) => {
              assistant.files = assistant.files.map((file: any) => {
                if (file.id === id) {
                  return res.data;
                } else {
                  return file;
                }
              });
              return assistant;
            })
          );
        }
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  return (
    <SalesCopilotContext.Provider
      value={{
        allAssistants,
        setAllAssistants,
        getAllAssistants,
        deleteAssistantFile,
        deleteAssistant,
        updateAssistant,
        createAssistant,
        uploadFileAssistants,
        loadingAI,
        trainAssistant,
        updateAiTrainingFile,
      }}
    >
      {children}
    </SalesCopilotContext.Provider>
  );
};

export const useSalesCopilot = () => useContext(SalesCopilotContext);
