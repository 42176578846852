import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  form > label {
    margin-top: 10px;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
  }

  @media (min-width: 1100px) {
  }
`;
