import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .modalContainer {
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .modalContainer > span {
    display: flex;
    justify-content: flex-end;
    margin: 15px;
    height: 12px;
  }
  .modalContainer > form {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
  }
  .modalContainer > span > button {
    background-color: ${({ theme }) => theme.colors.secondary};

    color: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 5px;
  }
  .modalContainer > form > button {
    /* margin: 16px auto; */
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    border: none;
    box-sizing: border-box;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.primary};
    padding: 10px;
    color: white;
    justify-content: center;
    font-size: 17px;
  }

  .modalContainer > form > button:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
