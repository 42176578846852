import AssistantsUpdateForm from "../../components/AssistantsUpdateForm";

const AssistantUpdatePage = () => {
  return (
    <>
      <AssistantsUpdateForm />
    </>
  );
};

export default AssistantUpdatePage;
