import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { IAssistants } from "../../providers/salesCopilot/salesCopilot.interface";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
const AssistantsUpdateForm = () => {
  const { apiLoading } = useUser();
  const {
    updateAssistant,
    allAssistants,
    deleteAssistant,
    deleteAssistantFile,
  } = useSalesCopilot();
  const navigate = useNavigate();
  const [assistant, setAssistant] = useState<IAssistants | null>(null);
  const { assistant_id, path } = useParams();
  const [model, setModel] = useState("gpt-4-1106-preview");
  const { t } = useTranslation();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalConfirmFileDeletion, setModalConfirmFileDeletion] =
    useState(false);
  const [fileId, setModalFileId] = useState<string | null>(null);
  useEffect(() => {
    if (allAssistants) {
      const assistantFromId = allAssistants.find(
        (e: IAssistants) => e.id === assistant_id
      );
      if (assistantFromId) {
        setAssistant(assistantFromId);
        setModel(assistantFromId.gptModel);
      } else {
        navigate("/" + path + "/assistants");
      }
    }
  }, [allAssistants, navigate, path, assistant_id]);
  const handleChange = (event: any) => {
    setModel(event.target.value);
  };
  function removeEmptyFields(data: any) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    removeEmptyFields(data);
    await updateAssistant(assistant_id, data);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      model: yup.string().max(128, "Model deve ter menos de 128 caractéres. "),
      instructions: yup.string().required(t("instructions_required")),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  return (
    <>
      <Container>
        <PageHeader
          title={t("update_assistant")}
          description={`${t("fill_update")} ${t("assistant")}`}
          icon={<IoCreateOutline />}
        />
        {assistant && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={errors.name === undefined ? false : true}
              helperText={errors.name?.message?.toString()}
              id="standard-name"
              defaultValue={assistant.name}
              label={t("name")}
              variant="standard"
              {...register("name")}
            />
            <TextField
              id="standard-description"
              error={errors.description === undefined ? false : true}
              helperText={errors.description?.message?.toString()}
              label={t("description")}
              defaultValue={assistant.description}
              variant="standard"
              {...register("description")}
            />
            <TextField
              id="standard-instructions"
              error={errors.instructions === undefined ? false : true}
              helperText={errors.instructions?.message?.toString()}
              label={t("instructions")}
              defaultValue={assistant.instructions}
              multiline
              variant="standard"
              {...register("instructions")}
            />
            <FormControl>
              <InputLabel sx={{ mt: 1 }} id="select-model">
                {t("model")}
              </InputLabel>
              <Select
                sx={{ mt: 1 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model}
                label={t("model")}
                {...register("model")}
                onChange={handleChange}
              >
                <MenuItem value={"gpt-4o"}>gpt-4o</MenuItem>
                <MenuItem value={"gpt-4-turbo"}>gpt-4-turbo</MenuItem>
              </Select>
            </FormControl>
            {assistant.files.map((e) => (
              <div className="assistanFileDiv" key={e.id}>
                <p className="assistantFileName">{e.fileName}</p>
                <p
                  className="assistantTrash"
                  onClick={() => {
                    if (apiLoading) return;
                    setModalConfirmFileDeletion(true);
                    setModalFileId(e.id);
                  }}
                >
                  <FaRegTrashAlt color="red" />
                </p>
              </div>
            ))}
            {apiLoading ? (
              <Loading />
            ) : (
              <div>
                <Button type="submit" variant="contained">
                  {t("confirm")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => navigate(`/${path}/settings`)}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setModalConfirmDeletion(true)}
                  variant="outlined"
                  color="error"
                >
                  {t("delete")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Container>
      {modalConfirmDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteId={assistant_id!}
          deleteFunction={deleteAssistant}
        />
      )}
      {modalConfirmFileDeletion && (
        <ModalConfirmDeletion
          setModalConfirmDeletion={setModalConfirmFileDeletion}
          deleteId={fileId!}
          parentId={assistant_id!}
          deleteFunction={deleteAssistantFile}
        />
      )}
    </>
  );
};
export default AssistantsUpdateForm;
