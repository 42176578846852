import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */
  h2 {
    text-transform: capitalize;
  }
  .passwordRecoveryHeader {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    margin: 10 auto;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
