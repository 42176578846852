import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import logo from "../../assets/logo-removebg-preview.svg";
import RegisterForm from "../../components/RegisterForm";
import Footer from "../../components/Footer";

const RegisterPage = () => {
  const { isLogged, isActive, user } = useUser();
  const navigate = useNavigate();
  const { path } = useParams();

  useEffect(() => {
    if (isLogged && isActive === true) {
      navigate(`/${path}/events`);
    }
  }, [user]);

  return (
    <>
      <Container>
        <header>
          <img src={logo} alt="SPP Logo" className="registerLogo" />
        </header>
        <RegisterForm />
      </Container>
      <Footer />
    </>
  );
};

export default RegisterPage;
