import * as yup from "yup";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import { useParams } from "react-router-dom";
import { ILiveContent } from "../../providers/eventSessions/eventsSessions.interface";
interface IProps {
  setModalUpdateLiveContent: React.Dispatch<React.SetStateAction<boolean>>;
  liveContent: ILiveContent;
}
const ModalUpdateLiveContent = ({
  setModalUpdateLiveContent,

  liveContent,
}: IProps) => {
  const { apiLoading } = useUser();
  const { session_id } = useParams();
  const [file, setFile] = useState<File | null>(null);
  const { updateLiveContent } = useEventSession();
  const [hideCountdown, setHideCountdown] = useState(
    liveContent.hideCountdown || false
  );
  console.log(liveContent.hideCountdown);
  const handleChangeHide = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideCountdown((event.target as HTMLInputElement).value);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const { t } = useTranslation();

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    data.startOffer =
      data.hoursStart * 3600 + data.minutesStart * 60 + data.secondsStart;
    delete data.hoursStart;
    delete data.minutesStart;
    delete data.secondsStart;
    data.endOffer =
      data.hoursEnd * 3600 + data.minutesEnd * 60 + data.secondsEnd;
    delete data.hoursEnd;
    delete data.minutesEnd;
    delete data.secondsEnd;

    await updateLiveContent(liveContent.id, data);
    setModalUpdateLiveContent(false);
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      buttonLink: yup.string().required(t("field_required")),
      textAboveButton: yup.string().required(t("field_required")),
      buttonText: yup.string().required(t("field_required")),
      hideCountdown: yup.boolean(),

      hoursStart: yup
        .number()
        .max(10)
        .typeError(t("field_required"))
        .required(t("field_required")),
      minutesStart: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
      secondsStart: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),

      hoursEnd: yup
        .number()
        .max(10)
        .typeError(t("field_required"))
        .required(t("field_required")),
      minutesEnd: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
      secondsEnd: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Product offer</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalUpdateLiveContent(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                type="submit"
              >
                SAVE
              </Button>
            </div>
          </div>
          <TextField
            id="standard-name"
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            label={t("name")}
            defaultValue={liveContent.name}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />{" "}
          <TextField
            id="standard-buttonLink"
            error={errors.buttonLink === undefined ? false : true}
            helperText={errors.buttonLink?.message?.toString()}
            label="Button link"
            variant="filled"
            defaultValue={liveContent.buttonLink}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("buttonLink")}
          />{" "}
          <TextField
            id="standard-buttonText"
            error={errors.buttonText === undefined ? false : true}
            helperText={errors.buttonText?.message?.toString()}
            label="Button text"
            defaultValue={liveContent.buttonText}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("buttonText")}
          />{" "}
          <TextField
            id="standard-textAboveButton"
            error={errors.textAboveButton === undefined ? false : true}
            helperText={errors.textAboveButton?.message?.toString()}
            label="Text above button"
            defaultValue={liveContent.textAboveButton}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("textAboveButton")}
          />{" "}
          <p>Image</p>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AiOutlineLink />}
          >
            Upload Image
            <input
              {...register("fileInput")}
              className="inputNone"
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              onInput={handleFileUpload}
            />
          </Button>
          <p className="errosForm">
            {errors.fileInput && errors.fileInput.message?.toString()}
          </p>
          <Box>{file?.name}</Box>
          <h3>Start offer</h3>
          <div className="durationDiv">
            <TextField
              id="standard-hour"
              error={errors.hours === undefined ? false : true}
              helperText={errors.hours?.message?.toString()}
              label={t("hours")}
              variant="filled"
              type="number"
              defaultValue={Math.floor(liveContent.startOffer / 3600)}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("hoursStart")}
            />
            <TextField
              id="standard-minutes"
              error={errors.minutes === undefined ? false : true}
              helperText={errors.minutes?.message?.toString()}
              label={t("minutes")}
              type="number"
              variant="filled"
              defaultValue={Math.floor((liveContent.startOffer % 3600) / 60)}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("minutesStart")}
            />
            <TextField
              id="standard-seconds"
              error={errors.seconds === undefined ? false : true}
              helperText={errors.seconds?.message?.toString()}
              label={t("seconds")}
              type="number"
              variant="filled"
              defaultValue={liveContent.startOffer % 60}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("secondsStart")}
            />
          </div>
          <h3>End offer</h3>
          <div className="durationDiv">
            <TextField
              id="standard-hour"
              error={errors.hours === undefined ? false : true}
              helperText={errors.hours?.message?.toString()}
              label={t("hours")}
              variant="filled"
              type="number"
              defaultValue={Math.floor(liveContent.endOffer / 3600)}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("hoursEnd")}
            />
            <TextField
              id="standard-minutes"
              error={errors.minutes === undefined ? false : true}
              helperText={errors.minutes?.message?.toString()}
              label={t("minutes")}
              defaultValue={Math.floor((liveContent.endOffer % 3600) / 60)}
              type="number"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("minutesEnd")}
            />
            <TextField
              id="standard-seconds"
              error={errors.seconds === undefined ? false : true}
              helperText={errors.seconds?.message?.toString()}
              label={t("seconds")}
              defaultValue={liveContent.endOffer % 60}
              type="number"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("secondsEnd")}
            />
          </div>
          <FormControl>
            <InputLabel id="timezone-label">Hide Countdown</InputLabel>
            <Select
              labelId="timezone-label"
              id="demo-simple-select"
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
                width: 442,
                mr: 2,
              }}
              value={hideCountdown}
              label="hide Countdown"
              {...register("hideCountdown")}
              //@ts-ignore
              onChange={handleChangeHide}
            >
              <MenuItem value={false as any} key={"false777"}>
                False
              </MenuItem>

              <MenuItem value={true as any} key={"true777"}>
                True
              </MenuItem>
            </Select>
          </FormControl>
        </form>
      </div>
    </Container>
  );
};

export default ModalUpdateLiveContent;
