import { RegistrantsContainer } from "../../components/RegistrantsContainer";
import { useRegistrantEvent } from "../../providers/registrantEvents";

const RegistrantsPage = () => {
  const { allRegistrants } = useRegistrantEvent();

  return (
    <>
      {" "}
      <RegistrantsContainer />{" "}
    </>
  );
};

export default RegistrantsPage;
