import { Container } from "./styles";

import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useTenant } from "../../providers/tenant";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalAddAudio from "../ModalAddAudio";
import ModalAddEmail from "../ModalAddEmail";
import ModalAddSMS from "../ModalAddSMS";
import ModalAddWhatsapp from "../ModalAddWhatsappNotification";
import NotificationCard from "../NotificationCard";
import TitleWithRefreshIcon from "../TitleWithRefreshIcon";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}
const EventSessionNotificationForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const [modalEmail, setModalAddEmail] = useState(false);
  const [modalSMS, setModalAddSMS] = useState(false);
  const [modalAudio, setModalAddAudio] = useState(false);
  const [modalWhatsapp, setModalAddWhatsapp] = useState(false);

  const [hourRelation, setHourRelation] = useState("before");
  type EventType = "pre-event" | "post-event";

  const [event, setEvent] = useState<EventType>("pre-event");
  const onSubmit = async () => {
    handleComplete();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const eventNotification = {
    "pre-event": {
      component: (
        <>
          <TitleWithRefreshIcon title={"Pre-event notifications"} />
          <div className="notificationDiv">
            <div>
              <h3>Sign Up Confirmation</h3>
              <div className="notificationButtonsDiv">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("on");
                    setModalAddEmail(true);
                  }}
                >
                  Add Email
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("on");
                    setModalAddSMS(true);
                  }}
                >
                  Add SMS
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("on");
                    setModalAddWhatsapp(true);
                  }}
                >
                  Add Whatsapp
                </Button>
              </div>{" "}
            </div>
            {eventSession &&
              eventSession.emailNotifications
                .filter((e) => e.hourRelation === "on")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"email"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.audioNotifications
                .filter((e) => e.hourRelation === "on")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"audio"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.SMSNotifications.filter(
                (e) => e.hourRelation === "on"
              )
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard key={e.id} type={"SMS"} notification={e} />
                ))}
            {eventSession &&
              eventSession.whatsappNotifications
                .filter((e) => e.hourRelation === "on")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"whatsapp"}
                    notification={e}
                  />
                ))}
          </div>
          <div className="notificationDiv">
            <div>
              <h3>After Sign Up Confirmation</h3>
              <div className="notificationButtonsDiv">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("afterSignup");
                    setModalAddEmail(true);
                  }}
                >
                  Add Email
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("afterSignup");
                    setModalAddSMS(true);
                  }}
                >
                  Add SMS
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("afterSignup");
                    setModalAddWhatsapp(true);
                  }}
                >
                  Add Whatsapp
                </Button>
              </div>{" "}
            </div>
            {eventSession &&
              eventSession.emailNotifications
                .filter((e) => e.hourRelation === "afterSignup")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"email"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.audioNotifications
                .filter((e) => e.hourRelation === "afterSignup")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"audio"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.SMSNotifications.filter(
                (e) => e.hourRelation === "afterSignup"
              )
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard key={e.id} type={"SMS"} notification={e} />
                ))}
            {eventSession &&
              eventSession.whatsappNotifications
                .filter((e) => e.hourRelation === "afterSignup")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"whatsapp"}
                    notification={e}
                  />
                ))}
          </div>
          <div className="notificationDiv">
            <div className="notificationButtonsDiv">
              <h3>Pre-event reminders</h3>{" "}
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("before");
                    setModalAddEmail(true);
                  }}
                >
                  Add Email
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("before");
                    setModalAddSMS(true);
                  }}
                >
                  Add SMS
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("before");
                    setModalAddWhatsapp(true);
                  }}
                >
                  Add Whatsapp
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("before");
                    setModalAddAudio(true);
                  }}
                >
                  Add Call
                </Button>
              </div>{" "}
            </div>
            {eventSession &&
              eventSession.emailNotifications
                .filter((e) => e.hourRelation === "before")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"email"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.audioNotifications
                .filter((e) => e.hourRelation === "before")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"audio"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.SMSNotifications.filter(
                (e) => e.hourRelation === "before"
              )
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard key={e.id} type={"SMS"} notification={e} />
                ))}
            {eventSession &&
              eventSession.whatsappNotifications
                .filter((e) => e.hourRelation === "before")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"whatsapp"}
                    notification={e}
                  />
                ))}
          </div>
        </>
      ),
    },
    "post-event": {
      component: (
        <>
          <h3>Post-event notifications</h3>
          <div className="notificationDiv">
            <div className="notificationButtonsDiv">
              <h3>Post-event Follow Ups</h3>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("after");
                    setModalAddEmail(true);
                  }}
                >
                  Add Email
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("after");
                    setModalAddSMS(true);
                  }}
                >
                  Add SMS
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("after");
                    setModalAddWhatsapp(true);
                  }}
                >
                  Add Whatsapp
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, borderRadius: "25px" }}
                  onClick={() => {
                    setHourRelation("after");
                    setModalAddAudio(true);
                  }}
                >
                  Add Call
                </Button>
              </div>
            </div>
            {eventSession &&
              eventSession.emailNotifications
                .filter((e) => e.hourRelation === "after")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"email"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.audioNotifications
                .filter((e) => e.hourRelation === "after")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"audio"}
                    notification={e}
                  />
                ))}
            {eventSession &&
              eventSession.SMSNotifications.filter(
                (e) => e.hourRelation === "after"
              )
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard key={e.id} type={"SMS"} notification={e} />
                ))}
            {eventSession &&
              eventSession.whatsappNotifications
                .filter((e) => e.hourRelation === "after")
                .sort((a, b) => a.hours - b.hours)
                .map((e) => (
                  <NotificationCard
                    key={e.id}
                    type={"whatsapp"}
                    notification={e}
                  />
                ))}
          </div>
        </>
      ),
    },
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="buttonsDiv">
            <Button
              variant="outlined"
              sx={{ mr: 1, borderRadius: "25px" }}
              onClick={() => setEvent("pre-event")}
            >
              Pre-event
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 1, borderRadius: "25px" }}
              onClick={() => setEvent("post-event")}
            >
              {" "}
              Post-event
            </Button>
          </div>
          {apiLoading ? <Loading /> : eventNotification[event].component}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1, borderRadius: "25px" }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              variant="contained"
              sx={{ mr: 1, borderRadius: "25px" }}
              onClick={() => handleComplete()}
            >
              Save & Next
            </Button>
          </Box>
        </form>
      </Container>
      {modalSMS && (
        <ModalAddSMS
          hourRelation={hourRelation}
          setModalAddSMS={setModalAddSMS}
        />
      )}
      {modalEmail && (
        <ModalAddEmail
          hourRelation={hourRelation}
          setModalAddEmail={setModalAddEmail}
        />
      )}
      {modalAudio && (
        <ModalAddAudio
          hourRelation={hourRelation}
          setModalAddAudio={setModalAddAudio}
        />
      )}
      {modalWhatsapp && (
        <ModalAddWhatsapp
          hourRelation={hourRelation}
          setModalAddWhatsapp={setModalAddWhatsapp}
        />
      )}
    </>
  );
};

export default EventSessionNotificationForm;
