import styled from "styled-components";

export const Container = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 90%;
  max-width: 450px;
  background-color: "#fafafa";
  border-radius: "2px";
  box-shadow: 2px 0px 10px rgbargba(3, 3, 3, 0.1);
  .loginContainer {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    max-width: 500px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 2px;
    box-shadow: rgba(3, 3, 3, 0.1) 2px 0px 10px;
  }
  .registerRedirect {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  .registerRedirect:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .showPasswordContainer {
    display: flex;
    gap: 5px;
  }
  .createAccount {
    color: ${({ theme }) => theme.colors.primary};
    padding: 5px 0;
    font-size: 0.8rem;
    text-align: center;
    font-size: 18px;
  }
  .createAccount:hover {
    opacity: 0.4;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    .showPasswordContainer {
      width: 80%;
      display: flex;
      gap: 5px;
    }
    .loginContainer {
      padding: 27px 24px;
      min-height: 368px;
    }
  }
`;
