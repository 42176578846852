import { createContext, useContext } from "react";
import { useUser } from "../users";
import api from "../../services";
import { toast } from "react-toastify";
import { useTenant } from "../tenant";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { IUpdateAdminRole } from "./roles.interface";
export const RoleContext = createContext<any | null>(null);

export const RoleProvider = ({ children }: IProviderProps) => {
  const { token, setApiLoading } = useUser();
  const { getTenantUsers } = useTenant();
  const adminUpdateRole = async (
    setModalChangeRole: React.Dispatch<React.SetStateAction<boolean>>,
    data: IUpdateAdminRole
  ) => {
    setApiLoading(true);
    const updated = await api
      .patch(`/roles/user`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        setApiLoading(false);
        setModalChangeRole(false);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        return false;
      });

    if (updated) {
      setTimeout(async () => {
        await getTenantUsers();
      }, 100);
    }
  };
  return (
    <RoleContext.Provider value={{ adminUpdateRole }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
