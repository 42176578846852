import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications/index";
import {
  IDynamicValues,
  ISMSNotification,
} from "../../providers/notifications/notifications.interface";
import { useTag } from "../../providers/tags/index";
import { useUser } from "../../providers/users";
import DynamicValuesContainer, {
  calculateExpirationTime,
} from "../DynamicValuesContainer";
import HourRelationContainer from "../HoursRelationContainer";
import ModalFormBase from "../ModalFormBase";
import NotificationTags from "../NotificationTags";
import { Container } from "./styles";
import { toast } from "react-toastify";
interface IProps {
  setModalEditSMS: React.Dispatch<React.SetStateAction<boolean>>;
  sms: ISMSNotification;
}
const ModalEditSMS = ({ setModalEditSMS, sms }: IProps) => {
  const { apiLoading } = useUser();
  const { updateSMSNotification, sendSampleTestSMS } = useNotification();
  const { session_id } = useParams();
  const { t } = useTranslation();
  const [toRegistrants, setToRegistrants] = useState(sms.to);
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues[]>(
    sms.dynamicValues
  );
  const [SMSTest, setSMSTest] = useState("");
  const [isSending, setIsSending] = useState(false);
  const formSchema = yup
    .object()
    .shape({
      hoursInput: yup.number().typeError(t("field_required")),
      minutes: yup
        .number()
        .max(60)

        .typeError(t("field_required")),
      hoursExpiration: yup.number().integer().moreThan(-1),
      minutesExpiration: yup.number().integer().moreThan(-1).max(59),
    })
    .required();

  useEffect(() => {
    if (!allTags) {
      getAllTags();
    }

    if (tags.length === 0 && sms.tags) {
      setTags(sms.tags);
    }
    if (tagsExclude.length === 0 && sms.tagsExclude) {
      setTagsExclude(sms.tagsExclude);
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: any) => {
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    delete data.hoursInput;
    delete data.minutes;
    delete data.seconds;
    if (sms.hourRelation === "on") data.hours = 0;
    data.hourRelation = sms.hourRelation;
    data.sessionId = session_id;
    data.enabled = true;

    if (tags.length > 0) {
      data.tags = tags;
    }
    data.tagsExclude = tagsExclude;
    data.dynamicValues = calculateExpirationTime(dynamicValues);
    await updateSMSNotification(sms.id, data);
    setModalEditSMS(false);
  };
  return (
    <ModalFormBase
      title="SMS Notification"
      setModal={setModalEditSMS}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <Container>
        <HourRelationContainer
          hourRelation={sms.hourRelation}
          register={register}
          errors={errors}
          hours={sms.hours}
          notificationType="sms"
        />

        <NotificationTags
          tags={tags}
          setTags={setTags}
          tagsExclude={tagsExclude}
          setTagsExclude={setTagsExclude}
          register={register}
          allTags={allTags}
          toRegistrants={toRegistrants}
          setToRegistrants={setToRegistrants}
        />
        <h3 className="smsSettings">SMS Settings</h3>

        <p>SMS Message</p>
        <TextField
          id="standard-message"
          error={errors.message === undefined ? false : true}
          helperText={errors.message?.message?.toString()}
          label={"Enter your sms message here"}
          multiline
          defaultValue={sms.message}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            mt: 3,
            width: "100%",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("message")}
        />

        {!["on"].includes(sms.hourRelation) && (
          <DynamicValuesContainer
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            hourRelation={sms.hourRelation}
          />
        )}

        <div className="testDiv">
          <TextField
            id="standard-SMSFrom"
            label="Send test sms"
            placeholder="+000000000000"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            value={SMSTest}
            onChange={(e) => setSMSTest(e.target.value)}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              width: "100%",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            onClick={async () => {
              if (!SMSTest) {
                return;
              }
              if (!getValues("message")) {
                toast.error("Message is required");
                return;
              }
              setIsSending(true);
              await sendSampleTestSMS(SMSTest, getValues("message"));
              setIsSending(false);
            }}
            sx={{ borderRadius: "25px", width: 150, ml: 1 }}
            variant="contained"
            disabled={apiLoading || !SMSTest}
            size="small"
          >
            Send
          </Button>
        </div>
      </Container>
    </ModalFormBase>
  );
};

export default ModalEditSMS;
