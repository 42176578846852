import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  gap: 25px;
  flex-direction: column;

  .error-message {
    color: #d32f2f;
    font-size: 0.75rem;
    margin-top: 3px;
    margin-left: 14px;
  }
`;
