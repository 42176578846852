import { useEffect, useState } from "react";
import EventsSessionsContainer from "../../components/EventSessionContainer";
import { useEvent } from "../../providers/events";
import { IEvent } from "../../providers/events/events.interface";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";

const EventSessions = () => {
  const { event_id, path } = useParams();
  const navigate = useNavigate();
  const { allEvents, editEvent } = useEvent();
  const [event, setEvent] = useState<IEvent | null>(null);
  useEffect(() => {
    if (allEvents) {
      const eventFromId = allEvents.find((e: IEvent) => e.id === event_id);
      if (eventFromId) {
        setEvent(eventFromId);
      } else {
        navigate("/" + path + "/events");
      }
    }
  }, [allEvents, navigate, path, event_id]);

  return <>{event && <EventsSessionsContainer event={event} />}</>;
};

export default EventSessions;
