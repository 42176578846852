import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .profileButtons {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 8px;
  }
  .panel {
    box-shadow: none;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 3px;
  }
  .panelHeader {
    border-bottom: 0;
  }
  .panelHeader > h4 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }

  .panelBody *:first-child {
    margin-top: 0;
  }
  .panel-footer {
    border-top: 0;
  }
  .bodyItem {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
  .bodyItem > p > strong {
    color: ${({ theme }) => theme.colors.primary};
  }

  /**
 * Profile
 */
  /*** Profile: Header  ***/
  .profileAvatar {
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
  }

  .profileAvatar > img {
    width: 100%;
    height: auto;
  }
  .profileHeader {
    overflow: hidden;
    text-transform: capitalize;
  }
  .profileHeader p {
    margin: 20px 0;
  }

  @media (min-width: 768px) {
    .profileAvatar {
      width: 100px;
      height: 100px;
    }
    .profileButtons {
      flex-direction: row;
    }
    .lastItem {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .profileAvatar {
      width: 100px;
      height: 100px;
    }
    .profileButtons {
      flex-direction: row;
    }
    .lastItem {
      width: 500px;
    }
  }
`;
