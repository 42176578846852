import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
interface IProps {
  setModalConfirmClone: React.Dispatch<React.SetStateAction<boolean>>;
  cloneFunction: any;
  cloneId: string;
}

const ModalConfirmClone = ({
  setModalConfirmClone,
  cloneFunction,
  cloneId,
}: IProps) => {
  const { apiLoading } = useUser();
  const { t } = useTranslation();

  const onClickClone = async () => {
    setModalConfirmClone(false);

    await cloneFunction(cloneId);
    setModalConfirmClone(false);
  };

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalDelete"
            onClick={() => {
              setModalConfirmClone(false);
            }}
          >
            <IoMdClose />
          </button>
        </span>
        <p> Are you sure you want to clone?</p>

        {apiLoading ? (
          <Loading />
        ) : (
          <div className="deleteButtonsDiv">
            <Button
              sx={{ m: 2, borderRadius: "25px" }}
              variant={"contained"}
              size="large"
              type="submit"
              onClick={async () => await onClickClone()}
            >
              {t("confirm")}
            </Button>
            <Button
              sx={{ m: 2, borderRadius: "25px" }}
              variant="contained"
              size="large"
              type="submit"
              onClick={() => setModalConfirmClone(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ModalConfirmClone;
