import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import { IRole } from "../roles/roles.interface";
import { useUser } from "../users";
import {
  ILog,
  IPathProps,
  IProviderProps,
  ITenant,
  ITenantByPath,
  ITenantSettings,
  IUpdateTenantIntegrations,
  IUpdateTenantSettings,
  IUpdateTenantTools,
} from "./tenant.interfaces";

export const TenantContext = createContext<any | null>(null);

export const TenantProvider = ({ children }: IProviderProps) => {
  const navigate = useNavigate();
  const {
    token,
    roleAdmin,
    verifyRolePermission,
    setPageMounting,
    setApiLoading,
  } = useUser();
  const [tenant, setTenant] = useState<ITenant | null>(null);
  const [tenantSettings, setTenantSettings] = useState<ITenantSettings | null>(
    null
  );
  const [dashboardData, setDashboardData] = useState<any | null>(null);
  const [tenantByPath, setTenantByPath] = useState<ITenantByPath | null>(null);
  const [allUsers, setAllUsers] = useState("");
  const [chatUsers, setChatUsers] = useState(0);
  const [allLogs, setAllLogs] = useState<ILog[] | null>(null);
  const [tenantTerms, setTenantTerms] = useState<ITenantSettings | null>(null);
  const [tenantRoles, setTenantRoles] = useState<IRole[]>([]);
  const location = useLocation();
  let path = location.pathname.split("/")[1];
  const { t } = useTranslation();

  const getTenant = async () => {
    setApiLoading(true);
    await api
      .get("/tenant", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTenant(res.data);
        if (verifyRolePermission(roleAdmin)) {
          setTenantSettings(res.data.tenantSettings);
        }
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setApiLoading(false);
      });
  };
  const getDashboardData = async (
    timestamp: number,
    mounting: boolean = true
  ) => {
    if (!timestamp) {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      timestamp = sevenDaysAgo.getTime();
    }

    if (mounting) {
      setPageMounting(true);
    } else {
      setApiLoading(true);
    }
    await api
      .get(`/dashboard/${timestamp}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDashboardData(res.data);
        setApiLoading(false);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setApiLoading(false);
        setPageMounting(false);
      });
  };
  const getTenantUsers = async (mounting = false) => {
    if (mounting) {
      setPageMounting(true);
    } else {
      setApiLoading(true);
    }
    await api
      .get("/tenant/users", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllUsers(res.data);
        setPageMounting(false);
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
        setApiLoading(false);
      });
  };
  const getTenantByPath = async (pathParam: IPathProps) => {
    if (pathParam) {
      path = pathParam.path.toLowerCase();
    }
    if (!path) {
      return navigate("/");
    }
    setApiLoading(true);

    await api
      .get(`/tenant/path/${path}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        window.localStorage.setItem("EPA-Session-Path", path);
        setTenantByPath(res.data);
        if (pathParam) {
          navigate(`/${pathParam.path}`);
        }
      })
      .catch((err) => {
        setApiLoading(false);
        if (pathParam) {
          toast.error(t("tenant_path_not_found"));
        }
        navigate("/");
      });
  };

  const getTenantRoles = () => {
    setApiLoading(true);
    api
      .get(`/roles`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        setTenantRoles(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const updateSettings = async (data: IUpdateTenantSettings) => {
    setApiLoading(true);
    api
      .patch(`/settings/tenant`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getTenant();
        toast.success("Settings updated with success");
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const updateIntegrations = async (data: IUpdateTenantIntegrations) => {
    setApiLoading(true);
    api
      .patch(`/integrations/tenant`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        toast.success("Integrations updated with success");
        getTenant();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const updateTools = async (data: IUpdateTenantTools) => {
    setApiLoading(true);
    api
      .patch(`/tools/tenant`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        toast.success("Tools updated with success");
        getTenant();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const deleteUser = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getTenantUsers();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const getChatUsersByRoom = async (roomId: string, connectionId: string) => {
    await api
      .post(
        `/tenant/room`,
        { roomId, connectionId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setChatUsers(res.data);
      })
      .catch((err) => {});
  };

  return (
    <TenantContext.Provider
      value={{
        updateSettings,
        updateIntegrations,
        getTenant,
        tenant,
        deleteUser,
        getTenantRoles,
        tenantRoles,
        tenantSettings,
        getTenantByPath,
        tenantByPath,
        chatUsers,
        getChatUsersByRoom,
        tenantTerms,
        getTenantUsers,
        allUsers,
        setChatUsers,
        allLogs,
        getDashboardData,
        dashboardData,
        updateTools,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
