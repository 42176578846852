import * as yup from "yup";
import { Container } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import { useParams } from "react-router-dom";
interface IProps {
  setModalCreateProgrammedChat: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalCreateProgrammedChat = ({
  setModalCreateProgrammedChat,
}: IProps) => {
  const { apiLoading } = useUser();
  const { session_id } = useParams();

  const { createProgrammedChats } = useEventSession();
  const [role, setRole] = useState("Attendee");
  const { t } = useTranslation();

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    data.sessionId = session_id;
    data.mode = "Text";

    await createProgrammedChats(data);
    setModalCreateProgrammedChat(false);
  };
  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole((event.target as HTMLInputElement).value);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      role: yup.string().required(t("field_required")).max(128, t("field_max")),

      message: yup.string().required(t("field_required")),
      hour: yup
        .number()
        .max(10)
        .typeError(t("field_required"))
        .required(t("field_required")),
      minute: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
      second: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Programmed Chats</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalCreateProgrammedChat(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                // type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          </div>
          <TextField
            id="standard-name"
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            label={t("name")}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />{" "}
          <FormControl>
            <InputLabel id="timezone-label">Role</InputLabel>
            <Select
              labelId="timezone-label"
              id="demo-simple-select"
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
                width: 442,
                mr: 2,
              }}
              value={role}
              label="Role"
              {...register("role")}
              //@ts-ignore
              onChange={handleChangeRole}
            >
              <MenuItem value={"Attendee"} key={"attendee"}>
                Attendee
              </MenuItem>

              <MenuItem value={"Admin"} key={"admin"}>
                Admin
              </MenuItem>
              <MenuItem value={"AI Assistant"} key={"ai"}>
                AI Assistant
              </MenuItem>
            </Select>
          </FormControl>
          <div className="durationDiv">
            <TextField
              id="standard-hour"
              error={errors.hour === undefined ? false : true}
              helperText={errors.hour?.message?.toString()}
              label={t("hour")}
              variant="filled"
              type="number"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("hour")}
            />
            <TextField
              id="standard-minute"
              error={errors.minute === undefined ? false : true}
              helperText={errors.minute?.message?.toString()}
              label={t("minute")}
              type="number"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("minute")}
            />
            <TextField
              id="standard-second"
              error={errors.second === undefined ? false : true}
              helperText={errors.second?.message?.toString()}
              label={t("second")}
              type="number"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("second")}
            />
          </div>
          <TextField
            id="standard-message"
            error={errors.message === undefined ? false : true}
            helperText={errors.message?.message?.toString()}
            label="Message"
            variant="filled"
            multiline
            minRows={3}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("message")}
          />{" "}
        </form>
      </div>
    </Container>
  );
};

export default ModalCreateProgrammedChat;
