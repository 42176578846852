import { styled } from "styled-components";
interface IProps {
  buttonshow: number;
}

export const Container = styled.div<IProps>`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  align-items: ${(props) => (props.buttonshow ? "start" : "center")};
  .headerTitle {
    display: flex;
    align-items: start;
    font-size: 28px;
  }
  .headerTitle > svg {
    margin-right: 5px;
  }
  .headerDescription {
    margin-left: 30px;
    color: ${({ theme }) => theme.colors.black};
  }
  .pageHeaderButton {
    align-self: start;
  }
  .headerButtons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  @media (min-width: 800px) {
    .headerDescription {
      margin-left: 39px;
    }
  }
`;
