import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { Container } from "./styles";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import ModalFormBase from "../ModalFormBase/index";

interface IPropps {
  setModalChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalChangePassword = ({ setModalChangePassword }: IPropps) => {
  const { updatePassword, apiLoading } = useUser();
  const { t } = useTranslation();

  const formSchema = yup
    .object()
    .shape({
      old_password: yup.string().required(t("password_required")),
      password: yup
        .string()
        .required(t("password_required"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
          t("password_matches")
        ),
      confirm: yup
        .string()
        .required(t("confirm_required"))
        .oneOf([yup.ref("password"), null], t("confirm_mathces")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = (data: FormValues) => {
    updatePassword(data);
  };

  return (
    <ModalFormBase
      title="Update password"
      apiLoading={apiLoading}
      onSubmit={handleSubmit(onSubmit)}
      setModal={setModalChangePassword}
    >
      <Container>
        <TextField
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message?.toString()}
          id="standard-senha"
          label={t("old_password")}
          variant="standard"
          type="password"
          {...register("old_password")}
        />
        <TextField
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message?.toString()}
          id="standard-senha"
          label={t("password")}
          variant="standard"
          type="password"
          {...register("password")}
        />
        <TextField
          id="standard-confirm"
          error={errors.confirm === undefined ? false : true}
          helperText={errors.confirm?.message?.toString()}
          label={t("confirm_password")}
          variant="standard"
          type="password"
          {...register("confirm")}
        />{" "}
      </Container>
    </ModalFormBase>
  );
};

export default ModalChangePassword;
