import Chat from "../../components/AIChat";
import EventSessionUpdateStepper from "../../components/EventSessionUpdateStepper";

const EventSessionChat = () => {
  return (
    <>
      <Chat />
    </>
  );
};

export default EventSessionChat;
