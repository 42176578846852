import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SendPasswordRecoveryForm from "../../components/SendPasswordRecoveryCode";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import PasswordRecoveryForm from "../../components/PasswordRecoveryForm";
import logo from "../../assets/logo-removebg-preview.svg";
import ChangePasswordForm from "../../components/ChangePasswordForm/index";

const ChangePassword = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const { path } = useParams();
  const forceChangePassword =
    window.localStorage.getItem("EPA-FORCE-CHANGE-PASSWORD") || "";

  useEffect(() => {
    if (!forceChangePassword) {
      navigate(`/${path}`);
    }
  }, [forceChangePassword]);
  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="loginLogo" />
      </header>
      <main>
        <ChangePasswordForm />
      </main>
    </Container>
  );
};

export default ChangePassword;
