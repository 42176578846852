import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useUser } from "../users";

export const TagContext = createContext<any | null>(null);

export const TagProvider = ({ children }: IProviderProps) => {
  const { token, setApiLoading, setPageMounting } = useUser();
  const location = useLocation();

  const [allTags, setAllTags] = useState<any[] | null>(null);

  const getAllTags = () => {
    api
      .get("/tags", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllTags(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const createTag = async (data: any) => {
    setApiLoading(true);

    await api
      .post("/tags", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllTags();
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };

  const editTag = async (id: string, data: any) => {
    setApiLoading(true);

    await api
      .patch(`/tags/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getAllTags();
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteTag = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/tags/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllTags();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  return (
    <TagContext.Provider
      value={{
        allTags,
        setAllTags,
        getAllTags,
        editTag,
        createTag,
        deleteTag,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};

export const useTag = () => useContext(TagContext);
