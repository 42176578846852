import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalFormBase from "../ModalFormBase";
import { useUser } from "../../providers/users";
import { toast } from "react-toastify";
import { useTenant } from "../../providers/tenant";

interface IProps {
  setModalCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalCreateUser = ({ setModalCreateUser }: IProps) => {
  const { apiLoading, createUser } = useUser();
  const { getTenantUsers } = useTenant();
  const { t } = useTranslation();

  const formSchema = yup
    .object()
    .shape({
      first_name: yup
        .string()
        .required(t("first_name_required"))
        .max(128, t("first_name_max")),
      last_name: yup
        .string()
        .required(t("last_name_required"))
        .max(128, t("last_name_max")),
      email: yup
        .string()
        .email(t("email_invalid"))
        .required(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  const onSubmit = async (data: any) => {
    await createUser(data);
    getTenantUsers();
    setModalCreateUser(false);
  };

  return (
    <ModalFormBase
      title={"Create user"}
      setModal={setModalCreateUser}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <TextField
        fullWidth
        margin="normal"
        label={t("first_name")}
        variant="standard"
        error={!!errors.firstName}
        helperText={errors.firstName?.message?.toString()}
        {...register("first_name")}
      />
      <TextField
        fullWidth
        variant="standard"
        margin="normal"
        label={t("last_name")}
        error={!!errors.lastName}
        helperText={errors.lastName?.message?.toString()}
        {...register("last_name")}
      />
      <TextField
        fullWidth
        variant="standard"
        margin="normal"
        label={t("email")}
        error={!!errors.email}
        helperText={errors.email?.message?.toString()}
        {...register("email")}
      />
    </ModalFormBase>
  );
};

export default ModalCreateUser;
