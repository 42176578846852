import { MdOutlineCopyright } from "react-icons/md";
import { Container } from "./styles";
const Footer = () => {
  return (
    <Container>
      <p>
        <MdOutlineCopyright /> 2024 EventsProAi All Rights Reserved.
      </p>
      <p>
        <a href="">Terms of service </a> <a href=""> Privacy Police</a>
      </p>
    </Container>
  );
};

export default Footer;
