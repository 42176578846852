import styled from "styled-components";

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    background-color: "#fafafa";
    border-radius: "2px";
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    div {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }

  .attendanceNumber > p > svg {
    width: 40px;
    height: 40px;
  }
  .attendanceNumber > p {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
`;
