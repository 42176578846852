import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import ModalFormBase from "../ModalFormBase";
import { useTenant } from "../../providers/tenant";
interface IProps {
  setModalGenerateNamesToProgrammedChats: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  chatIds: GridRowId[];
  setChatIds: any;
  sessionId: string;
}
const ModalGenerateNamesToProgrammedChats = ({
  setModalGenerateNamesToProgrammedChats,
  chatIds,
  setChatIds,
  sessionId,
}: IProps) => {
  const { apiLoading } = useUser();
  const { tenant, getTenant } = useTenant();
  const { generateNamesToProgrammedChats } = useEventSession();
  const [listName, setList] = useState("");
  const handleChangeList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setList((event.target as HTMLInputElement).value);
  };
  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, []);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    if (!listName) return;
    await generateNamesToProgrammedChats(chatIds, listName, sessionId);
    setModalGenerateNamesToProgrammedChats(false);
    setChatIds([]);
  };

  return (
    <ModalFormBase
      title="Update Programmed chats"
      setModal={setModalGenerateNamesToProgrammedChats}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <FormControl>
        <InputLabel id="timezone-label">Names list</InputLabel>
        <Select
          labelId="timezone-label"
          id="demo-simple-select"
          required
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            "& fieldset": { border: "none" },
            backgroundColor: "#FFF",

            mr: 2,
          }}
          value={listName}
          label="Names list"
          {...register("listName")}
          //@ts-ignore
          onChange={handleChangeList}
        >
          {tenant &&
            tenant.tools.randomNamesList.map((e: any) => (
              <MenuItem value={e.name} key={"english"}>
                {e.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </ModalFormBase>
  );
};

export default ModalGenerateNamesToProgrammedChats;
