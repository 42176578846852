import { Navigate, useLocation, useParams } from "react-router-dom";
import { useUser } from "../providers/users";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Logout from "@mui/icons-material/Logout";
interface IPrivateRoutesProps {
  rolesArray?: string[];
  redirect?: string;
  redirectRecruit?: boolean;
  children: React.ReactNode;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PrivateRoutes = ({
  rolesArray,
  redirect = "dashboard",
  redirectRecruit = true,
  children,
}: IPrivateRoutesProps) => {
  const { path } = useParams();
  const { user, token, verifyRolePermission, isActive, Logout } = useUser();
  const { i18n } = useTranslation();
  const query = useQuery();
  const forceChangePassword =
    window.localStorage.getItem("EPA-FORCE-CHANGE-PASSWORD") || "";

  const language = query.get("language");
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
  }, [i18n, language]);
  if (!path || path == "null") {
    Logout();
  }
  if (forceChangePassword) {
    return <Navigate to={`/${path}/changepassword`} />;
  }

  if (!token) {
    return <Navigate to={`/${path}`} />;
  }

  if (!isActive && user) {
    return <Navigate to={`/${path}`} />;
  }

  if (!user) {
    return <></>;
  }

  if (user) {
    if (!rolesArray) {
      return <>{children}</>;
    }
    const canAccess = verifyRolePermission(rolesArray);
    if (canAccess) {
      return <>{children}</>;
    }
  }
  return <Navigate to={`/${path}/${redirect}`} />;
};
export default PrivateRoutes;
