import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { IAssistants } from "../../providers/salesCopilot/salesCopilot.interface";
import { useTenant } from "../../providers/tenant";
import { AiOutlineFileAdd } from "react-icons/ai";
import ModalCreateAssistant from "../ModalCreateAssistant";
import ModalUpdateAssistant from "../ModalUpdateAssistant";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
const OpenAISettings = () => {
  const { apiLoading } = useUser();
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const [modalCreateAssistant, setModalCreateAssistant] = useState(false);
  const [modalUpdateAssistant, setModalUpdateAssistant] = useState(false);
  const [modalDeleteAssistant, setModalDeleteAssistant] = useState(false);
  const [assistantToChange, setAssistantToChange] =
    useState<IAssistants | null>(null);
  const [assistantName, setAssistantName] = useState("");
  const {
    getAllAssistants,
    allAssistants,
    uploadFileAssistants,
    deleteAssistant,
  } = useSalesCopilot();
  const { tenant, updateIntegrations } = useTenant();
  const filteredAssistants: IAssistants[] = allAssistants
    ? allAssistants.filter((e: IAssistants) => e.name.includes(assistantName))
    : [];

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await updateIntegrations({ openai: data });
  };
  const filesAccepted = ["text/html", "application/pdf", "text/plain"];
  const handleFileUpload = async (e: any) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];
    console.log(file);
    if (!filesAccepted.includes(file.type)) {
      toast.error(t("file_accept", { file_types: ".pdf, .txt, .html" }));
      resetFileInput(e.target);
      return;
    }
    if (file.size >= 5 * 1000000) {
      // mb to bytes
      toast.error("File must be less than 5MB");
      resetFileInput(e.target);
      return;
    }
    await uploadFileAssistants(e.target.id, file, true);
    resetFileInput(e.target);
  };
  const resetFileInput = (input: HTMLInputElement) => {
    // Create a new FileList object (which is empty)
    const dataTransfer = new DataTransfer();
    // Set the file input's files to this new empty FileList
    input.files = dataTransfer.files;
    // Trigger change event
    const event = new Event("change", { bubbles: true });
    input.dispatchEvent(event);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("field_required")).max(128, t("field_max")),
      apiKey: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "AI Moderator name",
      width: 300,
      editable: false,
    },
    {
      field: "files",
      headerName: "Files",
      width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.files.map((u: any) => u.fileName).join(", "),
      editable: false,
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt.split("T")[0],
      width: 250,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="AssistantActions">
          <IconButton
            onClick={() => {
              setAssistantToChange(params.row);
              setModalUpdateAssistant(true);
            }}
            color="primary"
            disabled={apiLoading}
            className="no-redirect"
            component="label"
          >
            <MdEdit size={28} />
          </IconButton>
          <IconButton
            onClick={() => {
              setAssistantToChange(params.row);
              setModalDeleteAssistant(true);
            }}
            color="primary"
            disabled={apiLoading}
            className="no-redirect"
            component="label"
          >
            <IoMdClose size={28} />
          </IconButton>
          <IconButton
            color="primary"
            disabled={apiLoading}
            className="no-redirect"
            component="label"
          >
            <AiOutlineFileAdd />
            <input
              className="inputNone"
              type="file"
              accept=".pdf, .txt, .html"
              required
              onChange={handleFileUpload}
              id={params.row.id}
              name="assistant"
            />
          </IconButton>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getAllAssistants();
  }, []);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>OpenAI</h3>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            defaultValue={
              tenant.integrations.openai && tenant.integrations.openai.name
            }
            id="standard-name"
            label={t("name")}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />
          <TextField
            id="standard-apiKey"
            error={errors.apiKey === undefined ? false : true}
            defaultValue={
              tenant.integrations.openai && tenant.integrations.openai.apiKey
            }
            helperText={errors.apiKey?.message?.toString()}
            label="API Key"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("apiKey")}
          />
          <div className="assistantList">
            <section className="filterSection">
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="search event">
                        {<FaSearch />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="standard-search"
                label="Search for Assistant"
                type={"text"}
                value={assistantName}
                onChange={(e) => setAssistantName(e.target.value)}
                variant="filled"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
              />
              {tenant.integrations.openai &&
                tenant.integrations.openai.apiKey && (
                  <Button
                    sx={{
                      borderRadius: "25px",
                    }}
                    variant="outlined"
                    disabled={apiLoading}
                    onClick={() => setModalCreateAssistant(true)}
                  >
                    Add Assistant
                  </Button>
                )}
            </section>

            {allAssistants && (
              <DataGrid
                rows={filteredAssistants}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                sx={{
                  mt: 3,
                  border: 0,
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
                  padding: 1,
                  maxHeight: 1000,
                }}
                pageSizeOptions={[5, 15, 50, 100]}
                disableRowSelectionOnClick
              />
            )}
          </div>
          {apiLoading ? (
            <Loading />
          ) : (
            <div>
              <Button
                type="submit"
                sx={{ borderRadius: "25px" }}
                variant="contained"
                disabled={apiLoading}
              >
                save
              </Button>
            </div>
          )}
        </form>
      </Container>
      {modalCreateAssistant && (
        <ModalCreateAssistant
          setModalCreateAssistant={setModalCreateAssistant}
        />
      )}
      {modalUpdateAssistant && assistantToChange && (
        <ModalUpdateAssistant
          assistant={assistantToChange}
          setModalUpdateAssistant={setModalUpdateAssistant}
        />
      )}
      {modalDeleteAssistant && assistantToChange && (
        <ModalConfirmDeletion
          deleteId={assistantToChange.id}
          deleteFunction={deleteAssistant}
          setModalConfirmDeletion={setModalDeleteAssistant}
        />
      )}
    </>
  );
};

export default OpenAISettings;
