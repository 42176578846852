import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, MenuItem, Chip, Box, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridSortModel,
  GridPaginationModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridRowId,
} from "@mui/x-data-grid";
import { IoMdClose } from "react-icons/io";
import { MdEdit, MdPeople, MdFilterList } from "react-icons/md";
import { useRegistrantEvent } from "../../providers/registrantEvents";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import { IRegistrant } from "../../providers/registrantEvents/registrantEvents.interface";
import PageHeader from "../pageHeader";
import ModalUpdateRegistrant from "../ModalUpdateRegistrant";
import ModalChangeTags from "../ModalChangeTags";
import ModalListRegistrantEvents from "../ModalListRegistrantEvents/index";
import { Container } from "./styles";
import { ExportButton } from "../ExcelExport/ExportButton";
import { t } from "i18next";

export const RegistrantsContainer = () => {
  const {
    getRegistrants,
    registrants,
    totalCount,
    deleteRegistrant,
    deleteMultipleRegistrants,
  } = useRegistrantEvent();
  const { apiLoading } = useUser();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [updateItem, setUpdateItem] = useState<IRegistrant | null>(null);
  const [modalUpdateRegistrant, setModalUpdateRegistrant] = useState(false);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalListRegistrantEvents, setModalListRegistrantEvents] =
    useState(false);
  const [modalDeleteArrayRegistrants, setModalArrayDeleteRegistrants] =
    useState(false);
  const [modalChangeTags, setModalChangeTags] = useState(false);

  // Custom filter states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedField, setSelectedField] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [activeFilters, setActiveFilters] = useState<
    { field: string; value: string }[]
  >([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterFieldSelect = (field: string) => {
    setSelectedField(field);
    handleFilterClose();
  };

  const handleAddFilter = () => {
    if (selectedField && filterValue) {
      setActiveFilters([
        ...activeFilters,
        { field: selectedField, value: filterValue },
      ]);
      setSelectedField("");
      setFilterValue("");
    }
  };

  const handleRemoveFilter = (index: number) => {
    const newFilters = activeFilters.filter((_, i) => i !== index);
    setActiveFilters(newFilters);
  };

  const fetchRegistrants = useCallback(() => {
    const filters = activeFilters.map((filter) => ({
      field: filter.field,
      operator: "contains",
      value: filter.value,
    }));

    getRegistrants(paginationModel.page, paginationModel.pageSize, sortModel, {
      items: filters,
    });
  }, [paginationModel, sortModel, activeFilters]);

  useEffect(() => {
    fetchRegistrants();
  }, [fetchRegistrants]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "Tags",
      headerName: "Tags",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.tags && params.row.tags.map((e: any) => e.name),
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <p
          className="registrantTags"
          onClick={() => {
            setUpdateItem(params.row);
            setModalChangeTags(true);
          }}
        >
          {params.row.tags &&
            params.row.tags.map((e: any) => e.name).join(", ")}
        </p>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "Sessões",
      headerName: "Sessões",
      sortable: false,
      width: 130,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="registrantActions">
          <div
            onClick={() => {
              setUpdateItem(params.row);
              setModalListRegistrantEvents(true);
            }}
          >
            List sessions
          </div>
        </div>
      ),
    },
    {
      field: "utm_content",
      headerName: "utm_content",
      width: 150,
    },
    {
      field: "utm_source",
      headerName: "utm_source",
      width: 150,
    },
    {
      field: "utm_campaign",
      headerName: "utm_campaign",
      width: 150,
    },
    {
      field: "utm_term",
      headerName: "utm_term",
      width: 150,
    },
    {
      field: "utm_medium",
      headerName: "utm_medium",
      width: 150,
    },

    {
      field: "utm_content_id",
      headerName: "utm_content_id",
      width: 150,
    },
    {
      field: "utm_campaign_id",
      headerName: "utm_campaign_id",
      width: 150,
    },
    {
      field: "utm_medium_id",
      headerName: "utm_medium_id",
      width: 150,
    },
    {
      field: "li_fat_id",
      headerName: "li_fat_id",
      width: 150,
    },
    {
      field: "gclid",
      headerName: "gclid",
      width: 150,
    },
    {
      field: "fbclid",
      headerName: "fbclid",
      width: 150,
    },

    {
      field: "campaign",
      headerName: "campaign",
      width: 150,
    },
    {
      field: "param1",
      headerName: "param1",
      width: 150,
    },
    {
      field: "param2",
      headerName: "param2",
      width: 150,
    },
    {
      field: "country",
      headerName: "country",
      width: 150,
    },
    {
      field: "state",
      headerName: "state",
      width: 150,
    },
    {
      field: "city",
      headerName: "city",
      width: 150,
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt &&
        new Date(params.row.updatedAt).toDateString().split("T")[0],
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="registrantActions">
          <MdEdit
            size={28}
            onClick={() => {
              setUpdateItem(params.row);
              setModalUpdateRegistrant(true);
            }}
          />
          <IoMdClose
            size={28}
            onClick={() => {
              setModalConfirmDeletion(true);
              setDeleteId(params.row.id);
            }}
          />
        </div>
      ),
    },
  ];

  const filterableFields = [
    "email",
    "name",
    "phone",
    "country",
    "state",
    "city",
    "utm_content",
    "utm_source",
    "utm_term",
    "utm_medium",
    "utm_campaign",
    "utm_content_id",
    "utm_campaign_id",
    "utm_medium_id",
    "li_fat_id",
    "gclid",
    "fbclid",
    "campaign",
    "param1",
    "param2",
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ p: 1 }}>
        <ExportButton />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <PageHeader
        title={"Registrants"}
        description={"Registrants list"}
        icon={<MdPeople />}
      />
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Button
            variant="outlined"
            startIcon={<MdFilterList />}
            onClick={handleFilterClick}
          >
            Add Filter
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            {filterableFields.map((field) => (
              <MenuItem
                key={field}
                onClick={() => handleFilterFieldSelect(field)}
              >
                {field}
              </MenuItem>
            ))}
          </Menu>
          <Button
            disabled={rowSelectionModel.length <= 0 || apiLoading}
            sx={{ mt: 2, mb: 2, ml: 1 }}
            variant="outlined"
            color="error"
            onClick={() => setModalArrayDeleteRegistrants(true)}
          >
            {t("delete")} &#40;
            {rowSelectionModel.length}&#41; chat&#40;s&#41;
          </Button>
        </Box>
        {selectedField && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              label={`Filter by ${selectedField}`}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              sx={{ mr: 2 }}
            />
            <Button variant="contained" onClick={handleAddFilter}>
              Add
            </Button>
          </Box>
        )}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
          {activeFilters.map((filter, index) => (
            <Chip
              key={index}
              label={`${filter.field} contains: ${filter.value}`}
              onDelete={() => handleRemoveFilter(index)}
            />
          ))}
        </Box>
        {apiLoading ? (
          <Loading />
        ) : (
          <DataGrid
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rows={registrants}
            columns={columns}
            rowCount={totalCount}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            slots={{ toolbar: CustomToolbar }}
            pageSizeOptions={[5, 15, 50, 100, 250, 500, 1000]}
            disableRowSelectionOnClick
            rowSelectionModel={rowSelectionModel}
            checkboxSelection
            sx={{
              mt: 3,
              border: 0,
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
              padding: 1,
            }}
          />
        )}
      </Container>

      {deleteId && modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteRegistrant}
        />
      )}
      {updateItem && modalChangeTags && (
        <ModalChangeTags
          registrant={updateItem}
          setModalChangeTags={setModalChangeTags}
        />
      )}
      {updateItem && modalUpdateRegistrant && (
        <ModalUpdateRegistrant
          item={updateItem}
          setModalUpdateRegistrant={setModalUpdateRegistrant}
        />
      )}
      {updateItem && modalListRegistrantEvents && (
        <ModalListRegistrantEvents
          id={updateItem.id}
          open={modalListRegistrantEvents}
          setOpen={setModalListRegistrantEvents}
        />
      )}
      {modalDeleteArrayRegistrants && (
        <ModalConfirmDeletion
          //@ts-ignore
          deleteId={rowSelectionModel}
          deleteFunction={deleteMultipleRegistrants}
          setModalConfirmDeletion={setModalArrayDeleteRegistrants}
        />
      )}
    </>
  );
};
