import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
import { useUser } from "../../providers/users";
import { borderRadius } from "@mui/system";
const GeneralSettings = () => {
  const { tenantByPath, updateSettings, tenant } = useTenant();
  const { path } = useParams();
  const { apiLoading } = useUser();
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await updateSettings(data);
  };
  const formSchema = yup
    .object()
    .shape({
      domainName: yup
        .string()
        .max(128, t("field_max"))
        .required("field_required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <h3>General settings</h3>
      <TextField
        error={errors.name === undefined ? false : true}
        helperText={errors.name?.message?.toString()}
        id="standard-name"
        label="Account id"
        disabled={true}
        defaultValue={(tenantByPath && tenantByPath.path) || path}
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

          textAlign: "center",
          backgroundColor: "#FFF",
          "& fieldset": { border: "none" },
        }}
        {...register("path")}
      />
      <h3>Domain Settings</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="standard-domainName"
          error={errors.domainName === undefined ? false : true}
          helperText={errors.domainName?.message?.toString()}
          defaultValue={tenant.settings && tenant.settings.domainName}
          label="Domain"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("domainName")}
        />
        <div>
          <Button
            type="submit"
            sx={{ borderRadius: "25px" }}
            variant="contained"
            disabled={apiLoading}
          >
            {t("save")}
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default GeneralSettings;
