import { Button } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useState } from "react";
import { Container } from "./styles";

import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useEventSession } from "../../providers/eventSessions";
import { IProgrammedChat } from "../../providers/eventSessions/eventsSessions.interface";
import { useUser } from "../../providers/users";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import ModalCreateProgrammedChat from "../ModalCreateProgrammedChats";
import ModalUpdateProgrammedChat from "../ModalUpdateProgrammedChat";
import { t } from "i18next";
import ModalUpdateArrayProgrammedChats from "../ModalUpdateArrayProgrammedChat";
import ModalGenerateNamesToProgrammedChats from "../ModalGenerateNamesToProgrammedChats";
export const ChatHistoryContainer = ({
  programmedChats,
  eventSession,
}: any) => {
  const [modalCreateProgrammedChats, setModalCreateProgrammedChats] =
    useState(false);
  const [modalUpdateProgrammedChats, setModalUpdateProgrammedChats] =
    useState(false);
  const [modalDeleteProgrammedChat, setModalDeleteProgrammedChat] =
    useState(false);
  const [modalDeleteAllProgrammedChats, setModalAllDeleteProgrammedChats] =
    useState(false);
  const [modalDeleteArrayProgrammedChats, setModalArrayDeleteProgrammedChats] =
    useState(false);
  const [modalUpdateArrayProgrammedChats, setModalArrayUpdateProgrammedChats] =
    useState(false);
  const [
    modalGenerateNamesToProgrammedChats,
    setModalGenerateNamesToProgrammedChats,
  ] = useState(false);
  const [chatToChange, setChatToChange] = useState<IProgrammedChat | null>(
    null
  );
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const { apiLoading } = useUser();
  const {
    addProgrammedChats,
    deleteProgrammedChat,
    deleteMultipleProgrammedChats,
    deleteAllProgrammedChats,
  } = useEventSession();
  const handleFileUpload = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    await addProgrammedChats(eventSession.id, file, eventSession.eventId);
  };
  const columns: GridColDef[] = [
    {
      field: "Time",
      headerName: "Time",
      width: 300,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        `${Math.floor(params.value / 3600)}hr ${Math.floor(
          (params.value % 3600) / 60
        )} min ${params.value % 60} sec`,

      valueGetter: (params: GridValueGetterParams) =>
        params.row.hour * 3600 + params.row.minute * 60 + params.row.second,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,

      editable: false,
    },
    {
      field: "message",
      headerName: "Message",
      type: "string",

      width: 250,
      editable: false,
    },
    {
      field: "approved",
      headerName: "Approved",
      type: "boolean",
      width: 150,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      type: "string",

      width: 250,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="userActions">
          <MdEdit
            size={28}
            onClick={() => {
              setChatToChange(params.row);
              setModalUpdateProgrammedChats(true);
            }}
          />
          <IoMdClose
            size={28}
            onClick={() => {
              setChatToChange(params.row);
              setModalDeleteProgrammedChat(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Container>
        <div className="header">
          {" "}
          <h3>Chat history</h3>
          <div>
            <Button
              variant="outlined"
              color="error"
              component="label"
              disabled={apiLoading}
              sx={{ borderRadius: "25px", mr: 1 }}
              onClick={() => setModalAllDeleteProgrammedChats(true)}
            >
              Delete ALL Chats
            </Button>

            <Button
              variant="outlined"
              color="primary"
              component="label"
              disabled={apiLoading}
              sx={{ borderRadius: "25px", mr: 1 }}
              onClick={() => setModalCreateProgrammedChats(true)}
            >
              Add Chat
            </Button>

            <Button
              disabled={apiLoading}
              variant="outlined"
              color="primary"
              component="label"
              sx={{ borderRadius: "25px" }}
            >
              Import from csv
              <input
                className="inputNone"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                name="invoice"
              />
            </Button>
          </div>
        </div>

        <div>
          <Button
            disabled={rowSelectionModel.length <= 0 || apiLoading}
            sx={{ borderRadius: "25px", mt: 2, mb: 2, mr: 1 }}
            variant="contained"
            onClick={() => setModalArrayUpdateProgrammedChats(true)}
          >
            {t("approve")} &#40;
            {rowSelectionModel.length}&#41; chat&#40;s&#41;
          </Button>
          <Button
            disabled={rowSelectionModel.length <= 0 || apiLoading}
            sx={{ borderRadius: "25px", mt: 2, mb: 2 }}
            variant="outlined"
            color="error"
            onClick={() => setModalArrayDeleteProgrammedChats(true)}
          >
            {t("delete")} &#40;
            {rowSelectionModel.length}&#41; chat&#40;s&#41;
          </Button>
          <Button
            variant="outlined"
            color="primary"
            component="label"
            disabled={rowSelectionModel.length <= 0 || apiLoading}
            sx={{ borderRadius: "25px", mr: 1 }}
            onClick={() => setModalGenerateNamesToProgrammedChats(true)}
          >
            Generate &#40;
            {rowSelectionModel.length}&#41; name&#40;s&#41;
          </Button>
        </div>

        {programmedChats && (
          <DataGrid
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection
            rows={programmedChats}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
              sorting: {
                sortModel: [{ field: "Time", sort: "asc" }],
              },
            }}
            sx={{
              mt: 3,
              border: 0,
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
              padding: 1,
              maxHeight: 1000,
            }}
            pageSizeOptions={[5, 15, 50, 100]}
            disableRowSelectionOnClick
          />
        )}
      </Container>
      {modalCreateProgrammedChats && (
        <ModalCreateProgrammedChat
          setModalCreateProgrammedChat={setModalCreateProgrammedChats}
        />
      )}
      {modalUpdateProgrammedChats && chatToChange && (
        <ModalUpdateProgrammedChat
          programmedChat={chatToChange}
          setModalUpdateProgrammedChat={setModalUpdateProgrammedChats}
        />
      )}
      {modalDeleteProgrammedChat && chatToChange && (
        <ModalConfirmDeletion
          deleteId={chatToChange.id}
          deleteFunction={deleteProgrammedChat}
          setModalConfirmDeletion={setModalDeleteProgrammedChat}
        />
      )}
      {modalDeleteAllProgrammedChats && (
        <ModalConfirmDeletion
          deleteId={eventSession.id}
          deleteFunction={deleteAllProgrammedChats}
          setModalConfirmDeletion={setModalAllDeleteProgrammedChats}
        />
      )}
      {modalDeleteArrayProgrammedChats && (
        <ModalConfirmDeletion
          //@ts-ignore
          deleteId={rowSelectionModel}
          deleteFunction={deleteMultipleProgrammedChats}
          setModalConfirmDeletion={setModalArrayDeleteProgrammedChats}
        />
      )}
      {modalUpdateArrayProgrammedChats && (
        <ModalUpdateArrayProgrammedChats
          chatIds={rowSelectionModel}
          setModalUpdateArrayProgrammedChats={
            setModalArrayUpdateProgrammedChats
          }
          setChatIds={setRowSelectionModel}
        />
      )}
      {modalGenerateNamesToProgrammedChats && (
        <ModalGenerateNamesToProgrammedChats
          chatIds={rowSelectionModel}
          setModalGenerateNamesToProgrammedChats={
            setModalGenerateNamesToProgrammedChats
          }
          setChatIds={setRowSelectionModel}
          sessionId={eventSession.id}
        />
      )}
    </>
  );
};
