import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
interface IProps {
  setModalConfirmDeletion: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFunction: any;
  deleteId: string;
  parentId?: any;
  warningMessage?: string;
  remove?: boolean;
}

const ModalConfirmDeletion = ({
  setModalConfirmDeletion,
  deleteFunction,
  deleteId,
  parentId = null,
  warningMessage = "",
  remove = false,
}: IProps) => {
  const { apiLoading } = useUser();
  const { t } = useTranslation();
  const [warningCount, setWarningCount] = useState(warningMessage ? 10 : 0);
  const onClickDelete = async () => {
    setModalConfirmDeletion(false);
    if (parentId) {
      await deleteFunction(parentId, deleteId);
      setModalConfirmDeletion(false);
    } else {
      await deleteFunction(deleteId);
      setModalConfirmDeletion(false);
    }
  };

  useEffect(() => {
    const timer: any =
      warningCount > 0 &&
      setInterval(() => setWarningCount(warningCount - 1), 1000);
    return () => clearInterval(timer);
  }, [warningCount]);

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalDelete"
            onClick={() => {
              if (apiLoading) {
                return;
              }
              setModalConfirmDeletion(false);
            }}
          >
            <IoMdClose />
          </button>
        </span>
        <p> {remove ? t("are_u_sure_remove") : t("are_u_sure_delete")}</p>
        {warningMessage ? (
          <p className="warningMessage">{warningMessage}</p>
        ) : (
          <></>
        )}
        {
          <div className="deleteButtonsDiv">
            <Button
              sx={{ m: 2, borderRadius: "25px" }}
              variant={"contained"}
              size="large"
              disabled={apiLoading ? true : warningCount > 0 ? true : false}
              type="submit"
              onClick={async () => await onClickDelete()}
            >
              {t("delete")} {warningCount > 0 ? "(" + warningCount + ")" : ""}
            </Button>
            <Button
              sx={{ m: 2, borderRadius: "25px" }}
              variant="contained"
              size="large"
              disabled={apiLoading}
              type="submit"
              onClick={() => setModalConfirmDeletion(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        }
      </div>
    </Container>
  );
};

export default ModalConfirmDeletion;
