import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { t } from "i18next";
interface IProps {
  setModalCreateAudioFile: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalCreateAudioFile = ({ setModalCreateAudioFile }: IProps) => {
  const { apiLoading } = useUser();
  const { createAudioFile } = useNotification();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("english");
  const [file, setFile] = useState<File | null>(null);
  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    await createAudioFile(data);
    setModalCreateAudioFile(false);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFile(file);
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),

      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", {
            file_types: ".wav, .mp3, .mpeg, .aac, .ogg, 3gpp, .mp4",
          }),
          (value) => {
            return (
              value &&
              (value[0].type.includes("audio/") ||
                value[0].type.includes("video/"))
            );
          }
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Create audio file</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalCreateAudioFile(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
              >
                Save
              </Button>
            </div>
          </div>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            label={t("name")}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />

          <Button
            component="label"
            variant="outlined"
            startIcon={<AiOutlineLink />}
          >
            Upload Audio
            <input
              {...register("fileInput")}
              className="inputNone"
              type="file"
              accept="audio/mp3,audio/*"
              required
              onInput={handleFileUpload}
            />
          </Button>
          <p className="errosForm">
            {errors.fileInput && errors.fileInput.message?.toString()}
          </p>
          <Box>{file?.name}</Box>

          <FormControl>
            <InputLabel id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label={t("language")}
              {...register("language")}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
              }}
              onChange={handleChange}
            >
              <MenuItem value={"english"}>{t("english")}</MenuItem>
              <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
            </Select>
          </FormControl>
        </form>
      </div>
    </Container>
  );
};

export default ModalCreateAudioFile;
