import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Container } from "./styles";
import { FaPlay, FaSearch } from "react-icons/fa";
import { IoIosPlay, IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNotification } from "../../providers/notifications";
import { IAudioFile } from "../../providers/notifications/notifications.interface";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import ModalCreateAudioFile from "../ModalCreateAudioFile";
import ModalEditAudioFile from "../ModalUpdateAudioFile";
import { useUser } from "../../providers/users";
export const AudioFilesContainer = () => {
  const { allAudioFiles, getAllAudioFiles, deleteAudioFile } =
    useNotification();
  const [audioName, setAudioName] = useState("");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [audioFileToEdit, setAudioFileToEdit] = useState<IAudioFile | null>(
    null
  );
  const [audioFileToPlay, setAudioFileToPlay] = useState<IAudioFile | null>(
    null
  );
  const { apiLoading } = useUser();
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [modalCreateAudioFile, setModalCreateAudioFile] = useState(false);
  const [modalEditAudioFile, setModalEditAudioFile] = useState(false);
  const filteredAudios: IAudioFile[] = allAudioFiles
    ? allAudioFiles.filter((e: IAudioFile) => e.name.includes(audioName))
    : [];

  useEffect(() => {
    if (!allAudioFiles) {
      getAllAudioFiles();
    }
  }, []);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Audio Name",
      width: 160,
      editable: false,
    },
    {
      field: "language",
      headerName: "Language",
      width: 160,
    },
    {
      field: "file name",
      headerName: "File name",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.file ? params.row.file.name : "None",
      width: 160,
      editable: false,
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt.split("T")[0],
      width: 160,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="userActions">
          <MdEdit
            size={28}
            onClick={() => {
              setAudioFileToEdit(params.row);
              setModalEditAudioFile(true);
            }}
          />
          <IoMdClose
            size={28}
            onClick={() => {
              setModalConfirmDeletion(true);
              setDeleteId(params.row.id);
            }}
          />
        </div>
      ),
    },
    {
      field: "Play",
      headerName: "Play",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="userActions">
          {params.row.file && (
            <IoIosPlay
              size={28}
              onClick={() => {
                setAudioFileToPlay(params.row);
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Container>
        <section className="filterSection">
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search event">
                    {<FaSearch />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="standard-search"
            label="Search for Audio File"
            type={"text"}
            value={audioName}
            onChange={(e) => setAudioName(e.target.value)}
            variant="filled"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              width: 332,
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            sx={{
              borderRadius: "25px",
            }}
            variant="outlined"
            disabled={apiLoading}
            onClick={() => setModalCreateAudioFile(true)}
          >
            Add Audio File
          </Button>
        </section>
        {apiLoading ? (
          <Loading />
        ) : (
          <>
            {allAudioFiles && (
              <DataGrid
                rows={filteredAudios}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                sx={{
                  mt: 3,
                  border: 0,
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
                  padding: 1,
                  maxHeight: 1000,
                }}
                pageSizeOptions={[5, 15, 50, 100]}
                disableRowSelectionOnClick
              />
            )}
          </>
        )}
        {audioFileToPlay && (
          <div>
            <audio src={audioFileToPlay.file.url} controls autoPlay />
          </div>
        )}
      </Container>
      {deleteId && modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteAudioFile}
        />
      )}
      {modalCreateAudioFile && (
        <ModalCreateAudioFile
          setModalCreateAudioFile={setModalCreateAudioFile}
        />
      )}
      {modalEditAudioFile && audioFileToEdit && (
        <ModalEditAudioFile
          setModalEditAudioFile={setModalEditAudioFile}
          audioFile={audioFileToEdit}
        />
      )}
    </>
  );
};
