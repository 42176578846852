import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSalesCopilot } from "../../providers/salesCopilot";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { AiOutlineLink } from "react-icons/ai";
interface IProps {
  setModalCreateAssistant: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalCreateAssistant = ({ setModalCreateAssistant }: IProps) => {
  const { apiLoading } = useUser();
  const { createAssistant } = useSalesCopilot();
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const [file, setFile] = useState<File[] | null>(null);
  const [assistantProfileFile, setAssistantProfileFile] = useState<
    File[] | null
  >(null);
  const [model, setModel] = useState("gpt-4-turbo");
  const handleChange = (event: any) => {
    setModel(event.target.value);
  };

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    setFile(null);
    await createAssistant(data);
    setModalCreateAssistant(false);
  };
  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files;

    setFile(Object.values(file));
  };
  const handleAssistantProfileFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files;

    setAssistantProfileFile(Object.values(file));
  };
  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("name_required")).max(128, t("name_max")),
      description: yup
        .string()
        .required(t("description_required"))
        .max(128, t("description_max")),
      instructions: yup.string().required(t("instructions_required")),
      chatName: yup
        .string()
        .required(t("name_required"))
        .max(128, t("name_max")),
      responseDelay: yup
        .number()
        .nullable()
        .min(0)
        .max(29)
        .transform((_, val) => (val ? Number(val) : null)),
      profanityFilter: yup.string(),
      triggerWords: yup.string(),
      model: yup
        .string()
        .required("model é necessário para criar assistants.")
        .max(128, "model deve ter menos de 128 caractéres. "),
      fileInput: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".pdf, .html, .txt" }),
          (value) => {
            console.log(value[0].type);
            return (
              value &&
              (value[0].type === "application/pdf" ||
                value[0].type === "text/html" ||
                value[0].type === "text/plain")
            );
          }
        ),
      assistantProfileFile: yup
        .mixed()
        .required(t("file_required"))
        .test("fileSize", t("file_size"), (value) => {
          return value && value[0].size <= 1000 * 1024 * 1024;
        }),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Create Assistant</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalCreateAssistant(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                type="submit"
              >
                create
              </Button>
            </div>
          </div>
          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            label={t("name")}
            variant="standard"
            {...register("name")}
          />
          <TextField
            id="standard-description"
            error={errors.description === undefined ? false : true}
            helperText={errors.description?.message?.toString()}
            label={t("description")}
            variant="standard"
            {...register("description")}
          />
          <TextField
            id="standard-instructions"
            error={errors.instructions === undefined ? false : true}
            helperText={errors.instructions?.message?.toString()}
            label={t("instructions")}
            variant="standard"
            {...register("instructions")}
          />
          <TextField
            id="standard-chatName"
            error={errors.chatName === undefined ? false : true}
            helperText={errors.chatName?.message?.toString()}
            label="AI Assistant chat name"
            variant="standard"
            {...register("chatName")}
          />
          <Tooltip title="If not setted a default value will be used">
            <TextField
              id="standard-triggerWords"
              error={errors.triggerWords === undefined ? false : true}
              helperText={errors.triggerWords?.message?.toString()}
              label="AI Trigger words"
              variant="standard"
              {...register("triggerWords")}
            />
          </Tooltip>
          <TextField
            id="standard-profanityFilter"
            error={errors.profanityFilter === undefined ? false : true}
            helperText={errors.profanityFilter?.message?.toString()}
            label="AI Profanity filter"
            variant="standard"
            {...register("profanityFilter")}
          />
          <TextField
            id="standard-responseDelay"
            error={errors.responseDelay === undefined ? false : true}
            helperText={errors.responseDelay?.message?.toString()}
            label="AI Assistant response delay"
            variant="standard"
            InputProps={{ inputProps: { min: 0, max: 29 } }}
            type="number"
            {...register("responseDelay")}
          />

          <Button
            component="label"
            variant="outlined"
            startIcon={<AiOutlineLink />}
          >
            AI Assistant Profile Picture
            <input
              {...register("assistantProfileFile")}
              className="inputNone"
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              required
              onChange={handleAssistantProfileFileUpload}
            />
          </Button>
          <p className="errosForm">
            {errors.assistantProfileFile &&
              errors.assistantProfileFile.message?.toString()}
          </p>
          {assistantProfileFile &&
            assistantProfileFile.map((e) => <Box key={e.name}>{e?.name}</Box>)}
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="select-model">
              {t("model")}
            </InputLabel>
            <Select
              sx={{ mt: 1 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={model}
              label={t("model")}
              {...register("model")}
              onChange={handleChange}
            >
              <MenuItem value={"gpt-4o"}>gpt-4o</MenuItem>
              <MenuItem value={"gpt-4o-mini"}>gpt-4o-mini</MenuItem>
              <MenuItem value={"gpt-4-turbo"}>gpt-4-turbo</MenuItem>
            </Select>
          </FormControl>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AiOutlineLink />}
          >
            {t("files_upload")}
            <input
              {...register("fileInput")}
              className="inputNone"
              type="file"
              accept=".pdf, .txt, .html"
              required
              multiple
              onChange={handleFileUpload}
            />
          </Button>
          <p className="errosForm">
            {errors.fileInput && errors.fileInput.message?.toString()}
          </p>
          <FormHelperText>{t("files_message")}</FormHelperText>
          {file && file.map((e) => <Box key={e.name}>{e?.name}</Box>)}
        </form>
      </div>
    </Container>
  );
};

export default ModalCreateAssistant;
