import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import LoginForm from "../../components/LoginForm";
import TokenVerificationForm from "../../components/tokenVerificationForm";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { IRole } from "../../providers/roles/roles.interface";
import Footer from "../../components/Footer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {
  const { isLogged, isActive, user } = useUser();
  const navigate = useNavigate();
  const { path } = useParams();
  const query = useQuery();
  const { i18n } = useTranslation();
  const forceChangePassword =
    window.localStorage.getItem("EPA-FORCE-CHANGE-PASSWORD") || "";

  useEffect(() => {
    const language = query.get("language");
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
    if (forceChangePassword) {
      navigate(`/${path}/changepassword`);
    }
    if (isLogged && isActive === true) {
      navigate(`/${path}/events`);
    }
  }, [user]);

  return (
    <>
      <Container>
        <header>
          <img src={logo} alt="SPP Logo" className="loginLogo" />
        </header>
        {isLogged === true && isActive === false ? (
          <section className="formContainer">
            <TokenVerificationForm />
          </section>
        ) : (
          <LoginForm />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Login;
