const config = {
  columnNames: [
    "email",
    "name",
    "phone",
    "country",
    "state",
    "city",
    "utm_content",
    "utm_source",
    "utm_term",
    "utm_medium",
    "utm_campaign",
    "campaign",
    "param1",
    "param2",
  ],
  keys: [
    "email",
    "name",
    "phone",
    "country",
    "state",
    "city",
    "utm_content",
    "utm_source",
    "utm_term",
    "utm_medium",
    "utm_campaign",
    "campaign",
    "param1",
    "param2",
  ],
  fileName: `epa-registrants-${(Math.random() + 1)
    .toString(36)
    .substring(7)}.xlsx`,
  sheetName: "Registrants Info",
};

export { config };
