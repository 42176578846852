import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useUser } from "../users";
import {
  IAudioFile,
  ICreateAudioFile,
  ICreateWhatsappTemplate,
  IUpdateAudioFile,
  IWhatsappTemplate,
} from "./notifications.interface";
import { useEventSession } from "../eventSessions";

export const NotificationContext = createContext<any | null>(null);

export const NotificationProvider = ({ children }: IProviderProps) => {
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useUser();
  const location = useLocation();
  const { setAllEventSessions, allEventSessions, getAllEventSessions } =
    useEventSession();
  const path = location.pathname.split("/")[1];
  const [allAudioFiles, setAllAudioFiles] = useState<IAudioFile[] | null>(null);
  const [allWhatsappTemplates, setAllWhatsappTemplates] = useState<
    IWhatsappTemplate[] | null
  >(null);
  const getAllAudioFiles = () => {
    setApiLoading(true);
    api
      .get("/audiofiles", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllAudioFiles(res.data);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const createAudioFile = async (data: ICreateAudioFile) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", data.fileInput[0]);
    console.log({
      name: data.name,
      language: data.language,
    });
    await api
      .post(
        "/audiofiles",
        {
          name: data.name,
          language: data.language,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        api
          .post(`/audiofiles/${res.data.id}`, dataFile, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setApiLoading(false);
            getAllAudioFiles();
          })
          .catch((err) => {
            setApiLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const editAudioFile = async (id: string, data: IUpdateAudioFile) => {
    setApiLoading(true);
    let dataFile: FormData;

    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    await api
      .patch(`/audiofiles/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/audiofiles/${id}`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              getAllAudioFiles();
              setApiLoading(false);
            })
            .catch((err) => {
              setApiLoading(false);
              toast.error(err.response.data.message);
              console.log(err.response.data.message);
            });
        } else {
          getAllAudioFiles();
          setApiLoading(false);
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteAudioFile = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/audiofiles/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllAudioFiles();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const updateAudioNotification = async (id: string, data: any) => {
    setApiLoading(true);

    const session = await api
      .patch("audionotifications/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.audioNotifications = session.audioNotifications.map(
                (notification: any) => {
                  if (notification.id === res.data.id) {
                    return res.data;
                  } else {
                    return notification;
                  }
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      });
  };
  const updateEmailNotification = async (id: string, data: any) => {
    setApiLoading(true);

    const session = await api
      .patch("emailnotifications/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.emailNotifications = session.emailNotifications.map(
                (notification: any) => {
                  if (notification.id === res.data.id) {
                    return res.data;
                  } else {
                    return notification;
                  }
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })

      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
    return session;
  };

  const deleteAudioNotification = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("audionotifications/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.audioNotifications = session.audioNotifications.filter(
                (notification: any) => {
                  return notification.id !== id;
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      });
  };

  const deleteEmailNotification = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("emailnotifications/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.emailNotifications = session.emailNotifications.filter(
                (notification: any) => {
                  return notification.id !== id;
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      });
  };

  const deleteSMSNotification = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("smsnotifications/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.SMSNotifications = session.SMSNotifications.filter(
                (notification: any) => {
                  return notification.id !== id;
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      });
  };

  const updateSMSNotification = async (id: string, data: any) => {
    setApiLoading(true);

    const session = await api
      .patch("smsnotifications/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.SMSNotifications = session.SMSNotifications.map(
                (notification: any) => {
                  if (notification.id === res.data.id) {
                    return res.data;
                  } else {
                    return notification;
                  }
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })

      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const getAllWhatsappTemplates = (loading: boolean = true) => {
    loading && setApiLoading(true);
    api
      .get("/whatsapptemplates", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllWhatsappTemplates(res.data);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };
  const createWhatsappTemplate = async (data: ICreateWhatsappTemplate) => {
    setApiLoading(true);

    await api
      .post("/whatsapptemplates", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllWhatsappTemplates();
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const deleteWhatsappTemplate = async (id: string) => {
    setApiLoading(true);
    await api
      .delete(`/whatsapptemplates/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        getAllWhatsappTemplates();
        getAllEventSessions(false);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const updateWhatsappNotification = async (
    id: string,
    data: any,
    template: IWhatsappTemplate
  ) => {
    setApiLoading(true);

    const session = await api
      .patch("whatsappnotifications/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.whatsappNotifications = session.whatsappNotifications.map(
                (notification: any) => {
                  if (notification.id === res.data.id) {
                    return { ...res.data, template };
                  } else {
                    return notification;
                  }
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err);
      });
    return session;
  };

  const deleteWhatsappNotification = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("whatsappnotifications/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.whatsappNotifications =
                session.whatsappNotifications.filter((notification: any) => {
                  return notification.id !== id;
                });
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const sendTestEmail = async (email: string) => {
    setApiLoading(true);

    await api
      .post(
        "emailnotifications/test",
        { email },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const sendSampleTestEmail = async (
    email: string,
    subject: string,
    html: string
  ) => {
    // setApiLoading(true);

    await api
      .post(
        "emailnotifications/sample",
        { email, subject, html },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const sendTestSMS = async (phone: string) => {
    setApiLoading(true);

    await api
      .post(
        "smsnotifications/test",
        { phone },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const sendSampleTestSMS = async (phone: string, message: string) => {
    // setApiLoading(true);

    await api
      .post(
        "smsnotifications/sample",
        { phone, message },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const sendTestAudio = async (phone: string) => {
    setApiLoading(true);
    await api
      .post(
        "audionotifications/test",
        { phone },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const sendSampleTestAudio = async (phone: string, audioId: string) => {
    // setApiLoading(true);
    await api
      .post(
        "audionotifications/sample",
        { phone, audioId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const sendTestWhatsapp = async (phone: string) => {
    setApiLoading(true);

    await api
      .post(
        "whatsappnotifications/test",
        { phone },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const sendSampleTestWhatsapp = async (phone: string, templateId: string) => {
    // setApiLoading(true);

    await api
      .post(
        "whatsappnotifications/sample",
        { phone, templateId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  return (
    <NotificationContext.Provider
      value={{
        allAudioFiles,
        setAllAudioFiles,
        createAudioFile,
        editAudioFile,
        deleteAudioFile,
        getAllAudioFiles,
        deleteAudioNotification,
        deleteEmailNotification,
        deleteSMSNotification,
        updateAudioNotification,
        updateEmailNotification,
        updateSMSNotification,
        deleteWhatsappNotification,
        updateWhatsappNotification,
        allWhatsappTemplates,
        setAllWhatsappTemplates,
        createWhatsappTemplate,
        deleteWhatsappTemplate,
        getAllWhatsappTemplates,
        sendTestWhatsapp,
        sendTestEmail,
        sendTestSMS,
        sendTestAudio,
        sendSampleTestAudio,
        sendSampleTestEmail,
        sendSampleTestSMS,
        sendSampleTestWhatsapp,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
