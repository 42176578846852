import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTenant } from "../../providers/tenant";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface IProps {
  setRegister?: React.Dispatch<React.SetStateAction<number>>;
}

const RegisterForm = ({ setRegister }: IProps) => {
  const { registerUser, apiLoading } = useUser();
  const { tenantByPath } = useTenant();
  const navigate = useNavigate();
  const { path } = useParams();
  const [language, setLanguage] = useState("english");
  const { i18n, t } = useTranslation();

  const handleChange = (event: any) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value === "english" ? "en" : "pt");
  };

  const onSubmit = async (data: any) => {
    await registerUser({ ...data, confirm: undefined });
  };

  const formSchema = yup.object().shape({
    first_name: yup
      .string()
      .required(t("first_name_required"))
      .max(128, t("first_name_max")),
    last_name: yup
      .string()
      .required(t("last_name_required"))
      .max(128, t("last_name_max")),
    email: yup.string().required(t("email_required")).email(t("email_invalid")),
    password: yup
      .string()
      .required(t("password_required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[!#@$%&*()^-])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        t("password_matches")
      ),
    confirm: yup
      .string()
      .required(t("confirm_required"))
      .oneOf([yup.ref("password"), null], t("confirm_mathces")),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm: "",
      default_language: "english",
    },
  });

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <div className="registerContainer">
        <h1>{t("register")}</h1>
        <TextField
          error={errors.first_name === undefined ? false : true}
          helperText={errors.first_name?.message}
          id="standard-name"
          label={t("first_name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("first_name")}
        />
        <TextField
          error={errors.last_name === undefined ? false : true}
          helperText={errors.last_name?.message}
          id="standard-last_name"
          label={t("last_name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("last_name")}
        />
        <TextField
          error={errors.email === undefined ? false : true}
          helperText={errors.email?.message}
          id="standard-email"
          label={t("email")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("email")}
        />
        <TextField
          error={errors.password === undefined ? false : true}
          helperText={errors.password?.message}
          id="standard-password"
          label={t("password")}
          type="password"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("password")}
        />
        <TextField
          error={errors.confirm === undefined ? false : true}
          helperText={errors.confirm?.message}
          id="standard-confirm"
          label={t("confirm_password")}
          type="password"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("confirm")}
        />
        <FormControl sx={{ mt: 1 }}>
          <InputLabel id="demo-simple-select-label">{t("language")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            {...register("default_language")}
            onChange={handleChange}
          >
            <MenuItem value={"english"}>{t("english")}</MenuItem>
            <MenuItem value={"portuguese"}>{t("portuguese")}</MenuItem>
          </Select>
        </FormControl>
        {apiLoading ? (
          <Loading />
        ) : (
          <Button sx={{ mt: 2 }} variant="contained" type="submit">
            {t("finish_registration")}
          </Button>
        )}
        <p className="registerRedirect" onClick={() => navigate(`/${path}`)}>
          {t("already_registered")}
        </p>
      </div>
    </Container>
  );
};

export default RegisterForm;
