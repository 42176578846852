import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";

interface HourRelationInputProps {
  hourRelation: string;
  register: ReturnType<typeof useForm>["register"];
  errors: ReturnType<typeof useForm>["formState"]["errors"];
  hours?: number;
  notificationType: "whatsapp" | "email" | "sms" | "audio";
}

const HourRelationContainer = ({
  hourRelation,
  register,
  errors,
  hours,
  notificationType,
}: HourRelationInputProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {hourRelation !== "on" && (
        <p className="hourRelationContainer">
          Send this {notificationType} notification{" "}
          <TextField
            id="standard-hour"
            label={t("hours")}
            variant="filled"
            type="number"
            defaultValue={hours && Math.floor(hours / 3600000)}
            required
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("hoursInput")}
          />{" "}
          and
          <TextField
            id="standard-minutes"
            label={t("minutes")}
            type="number"
            required
            defaultValue={hours && Math.floor((hours % 3600000) / 60000)}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              ml: 1,
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("minutes")}
          />{" "}
          {hourRelation === "afterSignup"
            ? "after sign up"
            : `${hourRelation} the session`}
        </p>
      )}
    </Container>
  );
};

export default HourRelationContainer;
