import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */
  margin-top: 50px;
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  form > label {
    margin-top: 10px;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
    /* float: right; */
    /* pointer-events: none; */
  }
  .scheduleDiv {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .shortTerms {
    margin-left: 30px;
  }
  .advancedSettings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }
  .advancedSettings > div {
    display: flex;
    justify-content: space-between;
  }
  .advancedSettings > div > div {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;
