import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import {
  IDynamicValues,
  IEmailNotification,
} from "../../providers/notifications/notifications.interface";
import { useUser } from "../../providers/users";
import HtmEditor from "../HtmlEditor";
import { Container } from "./styles";
import { useTag } from "../../providers/tags";
import NotificationTags from "../NotificationTags";
import DynamicValuesContainer, {
  calculateExpirationTime,
} from "../DynamicValuesContainer";
import HourRelationContainer from "../HoursRelationContainer";
import ModalFormBase from "../ModalFormBase";
import { toast } from "react-toastify";
interface IProps {
  setModalEditEmail: React.Dispatch<React.SetStateAction<boolean>>;
  email: IEmailNotification;
}
const ModalEditEmail = ({ setModalEditEmail, email }: IProps) => {
  const { apiLoading } = useUser();

  const { updateEmailNotification, sendSampleTestEmail } = useNotification();
  const { session_id } = useParams();
  const { t } = useTranslation();
  const [valueContent, setValueContent] = useState(email.html);
  const [toRegistrants, setToRegistrants] = useState(email.to);
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<any>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues[]>(
    email.dynamicValues
  );
  const [isSending, setIsSending] = useState(false);
  const [emailTest, setEmailTest] = useState("");
  useEffect(() => {
    if (!allTags) {
      getAllTags();
    }

    if (tags.length === 0 && email.tags && email.to !== "all") {
      setTags(email.tags);
    }
    if (tagsExclude.length === 0 && email.tagsExclude && email.to !== "all") {
      setTagsExclude(email.tagsExclude);
    }
  }, []);

  const valueChanged = useCallback(
    (e: { value?: string }) => {
      if (!e.value) {
        return;
      }
      setValueContent(e.value);
    },
    [setValueContent]
  );

  const formSchema = yup
    .object()
    .shape({
      subject: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),

      hoursInput: yup.number().typeError(t("field_required")),
      minutes: yup.number().max(60).typeError(t("field_required")),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: any, event: any) => {
    event.preventDefault();
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    data.hourRelation = email.hourRelation;
    delete data.hoursInput;
    delete data.minutes;
    data.enabled = true;
    data.sessionId = session_id;
    data.html = valueContent;

    if (data.hourRelation === "on") data.hours = 0;
    if (tags.length > 0) {
      data.tags = tags;
    }
    data.tagsExclude = tagsExclude;
    data.dynamicValues = calculateExpirationTime(dynamicValues);
    await updateEmailNotification(email.id, data);
    setModalEditEmail(false);
  };

  return (
    <ModalFormBase
      title="Email Notification"
      setModal={setModalEditEmail}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <Container>
        <HourRelationContainer
          hourRelation={email.hourRelation}
          register={register}
          errors={errors}
          hours={email.hours}
          notificationType="email"
        />

        <NotificationTags
          tags={tags}
          setTags={setTags}
          tagsExclude={tagsExclude}
          setTagsExclude={setTagsExclude}
          register={register}
          allTags={allTags}
          toRegistrants={toRegistrants}
          setToRegistrants={setToRegistrants}
        />

        <h3 className="emailSettings">Email Settings</h3>

        <p>Email subject</p>

        <TextField
          error={errors.subject === undefined ? false : true}
          helperText={errors.subject?.message?.toString()}
          id="standard-email"
          defaultValue={email && email.subject}
          label="Enter an email subject"
          autoComplete="email"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            width: "100%",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("subject")}
        />

        <p>Email body</p>
        <HtmEditor
          valueChanged={valueChanged}
          setValueContent={setValueContent}
          valueContent={valueContent}
        />
        {!["on"].includes(email.hourRelation) && (
          <DynamicValuesContainer
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            hourRelation={email.hourRelation}
          />
        )}

        {valueContent && (
          <div className="testDiv">
            <TextField
              id="standard-emailFrom"
              label="Send test email"
              placeholder="youremail@email.com"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              type="email"
              value={emailTest}
              onChange={(e) => setEmailTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={async () => {
                if (!emailTest) {
                  return;
                }
                if (!getValues("subject")) {
                  toast.error("Subject is required");
                  return;
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(emailTest)) {
                  return;
                }
                setIsSending(true);
                await sendSampleTestEmail(
                  emailTest,
                  getValues("subject"),
                  valueContent
                );
                setIsSending(false);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              type="button"
              disabled={apiLoading || !emailTest || isSending}
              size="small"
            >
              Send
            </Button>
          </div>
        )}
      </Container>
    </ModalFormBase>
  );
};

export default ModalEditEmail;
