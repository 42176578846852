import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useEvent } from "../../providers/events";
import { useUser } from "../../providers/users";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
import { Container } from "./styles";
import { useEventSession } from "../../providers/eventSessions";
import ModalConfirmClone from "../ModalConfirmClone";
import { AiFillExclamationCircle } from "react-icons/ai";
import ModalSessionLinks from "../ModalSessionLinks";
interface IProps {
  currentItems: IEventSession[];
}

const EventSessionsList = ({ currentItems }: IProps) => {
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const [cloneId, setCloneId] = useState<string | null>(null);
  const [modalConfirmClone, setModalConfirmClone] = useState(false);
  const [modalSessionLinks, setModalSessionLinks] = useState(false);
  const [session, setSession] = useState<IEventSession | null>(null);
  const { deleteEventSession, cloneEventSession } = useEventSession();
  const { apiLoading } = useUser();
  const { path } = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      {currentItems && currentItems.length === 0 ? (
        <div className="eventList">
          <h4>No event session found</h4>
        </div>
      ) : (
        <div className="eventList">
          {currentItems &&
            currentItems.map((item, index: number) => (
              <div key={item.id} className="eventContainer">
                <div className="eventInfos">
                  <p className="eventTitle">{item.name}</p>
                  <p className="eventType">
                    {t("description")}: {item.description}
                  </p>
                  <p className="eventType">
                    Session number: {item.sessionNumber}
                  </p>
                  <p className="eventType">
                    Duration: {formatTime(item.videoDuration)}
                  </p>
                  {!item.sessionSchedule && (
                    <p className="missingLive">
                      <AiFillExclamationCircle /> Missing configuration for live
                    </p>
                  )}
                  {!item.replayControls && (
                    <p className="missingReplay">
                      <AiFillExclamationCircle /> Missing configuration for
                      replay
                    </p>
                  )}
                </div>
                <div className="buttonGroup">
                  <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    disabled={apiLoading}
                    onClick={() =>
                      navigate(`/${path}/eventsessions/${item.id}/update`)
                    }
                  >
                    {t("edit")}
                  </Button>
                  <Button
                    sx={{ borderRadius: "25px" }}
                    disabled={apiLoading}
                    variant="contained"
                    onClick={() => {
                      setCloneId(item.id);
                      setModalConfirmClone(true);
                    }}
                  >
                    {t("clone")}
                  </Button>
                  <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    disabled={apiLoading}
                    onClick={() =>
                      navigate(`/${path}/eventsessions/${item.id}/chat`)
                    }
                  >
                    {t("view_live_chat")}
                  </Button>
                  <Button
                    sx={{ borderRadius: "25px" }}
                    variant="contained"
                    onClick={() => {
                      setModalSessionLinks(true);
                      setSession(item);
                    }}
                  >
                    links
                  </Button>
                  <Button
                    sx={{
                      borderRadius: "25px",
                    }}
                    variant="outlined"
                    disabled={apiLoading}
                    onClick={() => {
                      setDeleteId(item.id);
                      setModalConfirmDeletion(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </div>
              </div>
            ))}
        </div>
      )}
      {modalConfirmDeletion && deleteId && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteEventSession}
        />
      )}{" "}
      {modalConfirmClone && cloneId && (
        <ModalConfirmClone
          cloneId={cloneId}
          setModalConfirmClone={setModalConfirmClone}
          cloneFunction={cloneEventSession}
        />
      )}
      {modalSessionLinks && session && (
        <ModalSessionLinks
          setModalSessionLinks={setModalSessionLinks}
          session={session}
        />
      )}
    </Container>
  );
};

export default EventSessionsList;

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString =
    hours > 0 ? `${hours} ${hours === 1 ? "hora" : "horas"}` : "";
  const minutesString =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "minuto" : "minutos"}` : "";
  const secondsString =
    remainingSeconds > 0
      ? `${remainingSeconds} ${remainingSeconds === 1 ? "segundo" : "segundos"}`
      : "";

  const timeArray = [hoursString, minutesString, secondsString].filter(Boolean);

  return timeArray.join(", ") || "0 segundos";
}
