import React from "react";

import {
  IWhatsappNotification,
  IWhatsappTemplate,
} from "../../providers/notifications/notifications.interface";

import { Container } from "./styles";
import { Button } from "@mui/material";

interface IProps {
  setModalShowWhatsappMessage: React.Dispatch<React.SetStateAction<boolean>>;

  template: IWhatsappTemplate;
}

const ModalShowWhatsappMessage = ({
  setModalShowWhatsappMessage,
  template,
}: IProps) => {
  console.log(template);

  // Function to replace placeholders with dynamicValues 'type'

  const replacePlaceholders = (
    message: string,
    dynamicValues: any[]
  ): string => {
    dynamicValues.forEach((dynamicValue) => {
      const placeholder = `{{${dynamicValue.number}}}`;

      if (message.includes(placeholder)) {
        const replacement = `<span>${dynamicValue.type}</span>`;

        message = message.replaceAll(placeholder, replacement);
      }
    });

    return message;
  };
  const formatMessage = (message: string): string[] => {
    return message
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line); // Split by lines and remove empty lines
  };
  // Replace the placeholders with types

  const processedMessage = replacePlaceholders(
    template.message,
    template.dynamicValues
  );
  const formattedMessage = formatMessage(processedMessage);

  return (
    <Container>
      <div className="modalContainer">
        <div className="modalHeader">
          <h3>Whatsapp template message</h3>
          <div className="modalButtonsHeader">
            <Button
              variant="outlined"
              size="large"
              //   disabled={apiLoading}
              sx={{ mr: 1, borderRadius: "25px" }}
              onClick={() => setModalShowWhatsappMessage(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
        {/* <p
          className="templateMessage"
          dangerouslySetInnerHTML={{ __html: processedMessage }}
        /> */}
        {formattedMessage.map((line, index) => (
          <p
            key={index}
            className="templateMessage"
            dangerouslySetInnerHTML={{ __html: line }}
          />
        ))}
      </div>
    </Container>
  );
};

export default ModalShowWhatsappMessage;
