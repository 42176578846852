import UserUpdateForm from "../../components/UserUpdateForm";

const UserUpdate = () => {
  return (
    <>
      <UserUpdateForm />
    </>
  );
};

export default UserUpdate;
