import EventCreationForm from "../../components/EventCreationForm";

const EventCreation = () => {
  return (
    <>
      <EventCreationForm />
    </>
  );
};

export default EventCreation;
