import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .assistanFileDiv {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .assistantFileName {
    white-space: "nowrap";
    overflow: "hidden";
    text-overflow: "ellipsis";
  }
  .assistantTrash:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
