import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  ListSubheader,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { useEventSession } from "../../providers/eventSessions";
import {
  IDynamicValues,
  IWhatsappTemplate,
} from "../../providers/notifications/notifications.interface";
import { useTag } from "../../providers/tags";
import NotificationTags from "../NotificationTags";
import ModalFormBase from "../ModalFormBase";
import HourRelationContainer from "../HoursRelationContainer";
import DynamicValuesContainer, {
  calculateExpirationTime,
} from "../DynamicValuesContainer";
import { FaSearch } from "react-icons/fa";
import Loading from "../Loading";

interface IProps {
  setModalAddWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
  hourRelation: string;
}
const ModalAddWhatsapp = ({ setModalAddWhatsapp, hourRelation }: IProps) => {
  const { apiLoading } = useUser();
  const {
    allWhatsappTemplates,
    getAllWhatsappTemplates,
    sendSampleTestWhatsapp,
  } = useNotification();
  const { session_id } = useParams();
  const { createWhatsappNotification } = useEventSession();
  const [TemplateId, setTemplateId] = useState("");
  const { t } = useTranslation();
  const [toRegistrants, setToRegistrants] = useState("all");
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues[]>([]);
  const [templateError, setTemplateError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [WhatsappTest, setWhatsappTest] = useState("");

  const filteredWhatsappTemplates: any[] = allWhatsappTemplates
    ? allWhatsappTemplates.filter(
        (e: any) =>
          e.name && e.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];

  const formSchema = yup
    .object()
    .shape({
      hoursInput: yup.number().typeError(t("field_required")),
      minutes: yup
        .number()
        .max(60)

        .typeError(t("field_required")),
      hoursExpiration: yup.number().integer().moreThan(-1),
      minutesExpiration: yup.number().integer().moreThan(-1).max(59),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    if (
      !allWhatsappTemplates ||
      allWhatsappTemplates.some((e: IWhatsappTemplate) =>
        ["received", "pending"].includes(e.status)
      )
    ) {
      getAllWhatsappTemplates();
    }
    if (!allTags) {
      getAllTags();
    }
  }, []);

  useEffect(() => {
    if (TemplateId) {
      const dynamicLinks = allWhatsappTemplates.find(
        (e: IWhatsappTemplate) => e.id === TemplateId
      ).dynamicValues;

      dynamicLinks.map((e: IDynamicValues) => {
        e.expirationTime = 0;
        return e;
      });
      setDynamicValues(dynamicLinks);
    }
  }, [TemplateId]);
  const onSubmit = async (data: any) => {
    const template = allWhatsappTemplates.find(
      (e: IWhatsappTemplate) => e.id === TemplateId
    );
    if (!template) {
      setTemplateError(true);
      return;
    }
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    delete data.hoursInput;
    delete data.minutes;
    delete data.seconds;
    data.hourRelation = hourRelation;
    if (hourRelation === "on") data.hours = 0;
    data.templateId = TemplateId;
    data.sessionId = session_id;
    data.enabled = true;

    data.dynamicValues = calculateExpirationTime(dynamicValues);
    delete data.hoursExpiration;
    delete data.minutesExpiration;

    await createWhatsappNotification(data, template);
    setModalAddWhatsapp(false);
  };
  return (
    <ModalFormBase
      title="Whatsapp Notification"
      setModal={setModalAddWhatsapp}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    >
      <Container>
        <HourRelationContainer
          hourRelation={hourRelation}
          register={register}
          errors={errors}
          notificationType="whatsapp"
        />

        <NotificationTags
          tags={tags}
          setTags={setTags}
          tagsExclude={tagsExclude}
          setTagsExclude={setTagsExclude}
          register={register}
          allTags={allTags}
          toRegistrants={toRegistrants}
          setToRegistrants={setToRegistrants}
        />

        <h3 className="WhatsappSettings">Whatsapp Settings</h3>

        {allWhatsappTemplates ? (
          <FormControl error={templateError}>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              Select Whatsapp Template
            </InputLabel>

            <Select
              labelId="demo-simple-select-roles"
              id="demo-simple-select-roles"
              required
              MenuProps={{ autoFocus: false }}
              value={TemplateId}
              onChange={(e) => {
                setTemplateId(e.target.value);
                setTemplateError(false);
              }}
              label={"Select Whatsapp Template "}
              onClose={() => setSearchText("")}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaSearch />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filteredWhatsappTemplates &&
                filteredWhatsappTemplates
                  .filter((a: IWhatsappTemplate) => a.status === "approved")
                  .map((e: IWhatsappTemplate) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        ) : (
          <Loading />
        )}

        {!["on"].includes(hourRelation) && (
          <DynamicValuesContainer
            addNew={false}
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            hourRelation={hourRelation}
          />
        )}

        {TemplateId && (
          <div className="testDiv">
            <TextField
              id="standard-WhatsappFrom"
              label="Send test Whatsapp"
              placeholder="+000000000000"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              value={WhatsappTest}
              onChange={(e) => setWhatsappTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={() => {
                if (!WhatsappTest) {
                  return;
                }

                sendSampleTestWhatsapp(WhatsappTest, TemplateId);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              disabled={apiLoading || !WhatsappTest}
              size="small"
            >
              Send
            </Button>
          </div>
        )}
      </Container>
    </ModalFormBase>
  );
};

export default ModalAddWhatsapp;
