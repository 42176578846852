import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 999;
  align-self: center;

  .modalContainer {
    /* margin-top: 50px; */
    width: 90%;
    max-width: 1100px;
    padding: 20px;
    max-height: 90%;
    border-radius: 5px;
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .modalHeader {
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }

  .modalContainer > span {
    display: flex;
    justify-content: flex-end;

    padding: 15px;
    height: 12px;
  }

  .modalContainer > span > button {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 5px;
  }
`;
