import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useUser } from "../../providers/users";
import { useTenant } from "../../providers/tenant";
import { Container } from "./styles";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ActivationPage = () => {
  const { activateUser, isLogged, user, verifyRolePermission, roleRecruit } =
    useUser();
  const query = useQuery();
  const { path } = useParams();
  const { tenantByPath } = useTenant();
  const navigate = useNavigate();
  const activation_token = query.get("code");
  const email = query.get("email");
  useEffect(() => {
    if (tenantByPath) {
      activateUser(activation_token, email, tenantByPath.id);
    }
  }, [tenantByPath]);

  useEffect(() => {
    if (isLogged) {
      navigate(`/${path}/events`);
    }
  }, [user]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default ActivationPage;
