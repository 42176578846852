import EventUpdateForm from "../../components/EventUpdateForm";

const EventUpdate = () => {
  return (
    <>
      <EventUpdateForm />
    </>
  );
};

export default EventUpdate;
