import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { IRole } from "../../providers/roles/roles.interface";
import ModalChangePassword from "../ModalChangePasword";
import PageHeader from "../pageHeader";
import { RxAvatar } from "react-icons/rx";

const ProfileContainer = () => {
  const { user } = useUser();
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalChangePassword, setModalChangePassword] = useState(false);
  return (
    <>
      <Container>
        <PageHeader
          title={t("profile")}
          description={t("profile")}
          icon={<RxAvatar />}
        />

        <div className="panel">
          <div className="panelBody">
            <div className="bodyItem">
              <p>
                <strong>{t("first_name")}</strong>
              </p>
              <p className="lastItem">{user.first_name}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("last_name")}</strong>
              </p>
              <p className="lastItem">{user.last_name}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("email")}</strong>
              </p>
              <p className="lastItem">{user.email}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("roles")}</strong>
              </p>
              <p className="lastItem">
                {" "}
                {user.roles.map((u: IRole) => u.name).join(", ")}
              </p>
            </div>
            <div className="profileButtons">
              <Button
                variant="contained"
                onClick={() => navigate(`/${path}/profile/update`)}
              >
                {t("update_profile")}
              </Button>
              <Button
                variant="contained"
                onClick={() => setModalChangePassword(true)}
              >
                {t("update_password")}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      {modalChangePassword && (
        <ModalChangePassword setModalChangePassword={setModalChangePassword} />
      )}
    </>
  );
};

export default ProfileContainer;
