// src/components/styles.tsx

import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;
