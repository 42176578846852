import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* gap: 7px; */
  align-items: center;
  padding: 15px;
  color: ${({ theme }) => theme.colors.black};

  main {
    animation: ${fadeAnimation} 1s;
  }
  .loginLogo {
    width: 220px;
    height: 120px;
  }
  @media (min-width: 800px) {
    display: flex;
    padding: 0px;

    main {
      padding: 40px 50px;
      max-width: 80vw;
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    main {
      max-width: 80vw;
      padding: 40px 150px;
      margin: 0 auto;
      width: 100%;
    }
  }
`;
