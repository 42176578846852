import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { Container } from "./styles";
import * as yup from "yup";
import { useUser } from "../../providers/users";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useSalesCopilot } from "../../providers/salesCopilot";

interface IPropps {
  setModalEditMessage: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: any;
  item: any;
}
const ModalEditMessage = ({
  setModalEditMessage,
  sendMessage,
  item,
}: IPropps) => {
  console.log(item);
  const { t } = useTranslation();
  const { trainAssistant } = useSalesCopilot();
  const formSchema = yup
    .object()
    .shape({
      message: yup.string().required("field_required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = (data: FormValues) => {
    sendMessage({
      ...item,
      message: data.message,
    });
    setModalEditMessage(false);
  };

  return (
    <Container>
      <div className="modalContainer">
        <span>
          <button
            className="exitModalPassword"
            onClick={() => {
              setModalEditMessage(false);
            }}
          >
            <IoMdClose size={20} />
          </button>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={errors.message === undefined ? false : true}
            helperText={errors.message?.message?.toString()}
            id="standard-message"
            defaultValue={item.message}
            multiline
            minRows={3}
            label="Message"
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("message")}
          />

          <button type="submit">{t("update")}</button>
        </form>
      </div>
    </Container>
  );
};

export default ModalEditMessage;
