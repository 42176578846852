import styled from "styled-components";

export const Container = styled.section`
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  padding: 15px;
  margin-top: 30px;
  margin-top: 50px;
  .filterSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 343px; */
    gap: 10px;
    margin-top: 20px;
  }
  .userActions > svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  div > audio {
    margin-top: 15px;
  }
`;
