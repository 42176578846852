import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoPricetagsOutline, IoSettingsOutline } from "react-icons/io5";
import {
  MdOutlineAnalytics,
  MdOutlineAttachMoney,
  MdPeople,
} from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/color-spp.png";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { RxHome } from "react-icons/rx";

const NavigationBar = () => {
  const { user, Logout, verifyRolePermission, roleAdmin, roleFinance } =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [url, setUrl] = useState<string | null>(null);
  const { path } = useParams();
  const { i18n, t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownFinance, setShowDropdownFinance] = useState(false);
  const onChangeLang = (e: any) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  return (
    <Container>
      <div className="sideBarFix">
        <img src={logo} alt="SPP Logo" className="navBarLogo" />

        <section className="navBarLinksContainer">
          <section className="navBarLinks">
            <p
              className={url && url.includes("/dashboard") ? "linkActive" : ""}
              onClick={() => {
                navigate("/" + path + "/dashboard");
              }}
            >
              <RxHome /> {t("dashboard")}
            </p>
            <p
              className={url && url.includes("/events") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/events")}
            >
              <MdOutlineAttachMoney /> {t("events")}
            </p>
            <p
              className={url && url.includes("/analytics") ? "linkActive" : ""}
              onClick={() =>
                navigate("/" + path + "/analytics", { replace: true })
              }
            >
              <MdOutlineAnalytics /> Analytics
            </p>
            <p
              className={
                url && url.includes("/registrants") ? "linkActive" : ""
              }
              onClick={() =>
                navigate("/" + path + "/registrants", { replace: true })
              }
            >
              <MdPeople /> Registrants
            </p>

            <p
              className={url && url.includes("/tags") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/tags", { replace: true })}
            >
              <IoPricetagsOutline /> Tags
            </p>

            <p
              className={url && url.includes("/settings") ? "linkActive" : ""}
              onClick={() =>
                navigate("/" + path + "/settings", { replace: true })
              }
            >
              <IoSettingsOutline /> {t("settings")}
            </p>

            {/* 

            <p
              className={url && url.includes("/profile") ? "linkActive" : ""}
              onClick={() => navigate("/" + path + "/profile")}
            >
              <RxAvatar /> {t("profile")}
            </p>

     
            )} */}
          </section>
          <div className="navBarLinks">
            <p onClick={() => Logout()}>
              <RiLogoutBoxLine /> {t("logout")}
            </p>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default NavigationBar;
