import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 5px;
  .shortCodesContainer {
    background-color: #e5e5e5;
    width: 35%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: auto;
    max-height: 600px;
  }
  h5 {
    text-align: center;
    font-size: 18px;
    border-bottom: 2px solid #e1e0e0;
    padding: 10px;
  }

  .editorContainer {
    max-width: 65%;
    min-height: 600px;
  }
  .ql-container {
    min-height: 534px;
  }
`;
