import styled from "styled-components";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .inputNone {
    opacity: 0;
    width: 1px;
  }
`;
