import Header from "../../components/Header";
import NavigationBar from "../../components/NavigationBar";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { pageMounting } = useUser();
  return (
    <Container>
      <Header />
      <NavigationBar />
      <main>{pageMounting ? <Loading /> : children}</main>
    </Container>
  );
};

export default Layout;
