import { createContext, useContext, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services";
import { IProviderProps } from "../tenant/tenant.interfaces";
import { useUser } from "../users";
import {
  IRegistrant,
  IRegistrantEvent,
  IRegsiterParticipant,
  IUpdateRegistrant,
} from "./registrantEvents.interface";
import { toast } from "react-toastify";

export const RegistrantEventContext = createContext<any | null>(null);

export const RegistrantEventProvider = ({ children }: IProviderProps) => {
  const [allRegistrantEvents, setAllRegistrantEvents] = useState<
    IRegistrantEvent[] | null
  >(null);
  const [registrants, setRegistrants] = useState<IRegistrant[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(null);
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useUser();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const getRegistrants = useCallback(
    async (
      page: number,
      pageSize: number,
      sortModel: any,
      filterModel: any
    ) => {
      setApiLoading(true);
      try {
        const response = await api.post(
          "/registrants/list",
          {
            page,
            pageSize,
            sortModel,
            filterModel,
            lastEvaluatedKey,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRegistrants(response.data.items);
        setTotalCount(response.data.totalCount);
        setLastEvaluatedKey(response.data.lastEvaluatedKey);
        setApiLoading(false);
      } catch (err) {
        setApiLoading(false);
        console.error(err);
        toast.error("Failed to fetch registrants");
      }
    },
    [token, lastEvaluatedKey, setApiLoading]
  );

  const refreshRegistrants = useCallback(() => {
    getRegistrants(0, 15, [], {}); // Reset to first page with default values
  }, [getRegistrants]);

  const getAllRegistrantEvents = async (id: string) => {
    setApiLoading(true);
    try {
      const response = await api.get("/registrantevents/eventsessions/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAllRegistrantEvents(response.data);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      console.error(err);
      toast.error("Failed to fetch registrant events");
    }
  };

  const getAllRegistrantEventsFromRegistrant = async (id: string) => {
    setApiLoading(true);
    try {
      const response = await api.get("/registrantevents/registrants/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApiLoading(false);
      return response.data;
    } catch (err) {
      setApiLoading(false);
      console.error(err);
      toast.error("Failed to fetch registrant events");
    }
  };

  const deleteRegistrant = async (id: string) => {
    setApiLoading(true);
    try {
      await api.delete(`registrants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApiLoading(false);
      refreshRegistrants();
      toast.success("Registrant deleted successfully");
    } catch (err) {
      setApiLoading(false);
      toast.error("Failed to delete registrant");
      console.error(err);
    }
  };

  const updateRegistrant = async (id: string, data: IUpdateRegistrant) => {
    setApiLoading(true);
    try {
      await api.patch(`/registrants/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApiLoading(false);
      refreshRegistrants();
      toast.success("Registrant updated successfully");
    } catch (err) {
      setApiLoading(false);
      toast.error("Failed to update registrant");
      console.error(err);
    }
  };
  const registerParticipant = async (
    id: string,
    data: IRegsiterParticipant
  ) => {
    setApiLoading(true);
    try {
      await api.post(`/registrantevents/widget/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApiLoading(false);

      toast.success("Participant registered successfully");
    } catch (err) {
      setApiLoading(false);
      toast.error("Failed to update registrant");
      console.error(err);
    }
  };
  const updateRegistrantTags = async (data: any) => {
    setApiLoading(true);
    try {
      await api.patch(`/tags/registrant`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApiLoading(false);
      refreshRegistrants();
      toast.success("Registrant tags updated successfully");
    } catch (err) {
      setApiLoading(false);
      toast.error("Failed to update registrant tags");
      console.error(err);
    }
  };

  const deleteMultipleRegistrants = (registrantsIds: string[]) => {
    setApiLoading(true);

    api
      .delete("/registrants/multiple", {
        headers: { Authorization: `Bearer ${token}` },
        data: { registrants: registrantsIds },
      })
      .then(() => {
        setApiLoading(false);
        refreshRegistrants();
        toast.success("Registrants deleted with success");
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(
          err.response?.data?.message || "Error deleting registrants"
        );
        toast.error(
          err.response?.data?.message || "Error deleting registrants"
        );
      });
  };
  return (
    <RegistrantEventContext.Provider
      value={{
        allRegistrantEvents,
        setAllRegistrantEvents,
        updateRegistrantTags,
        getAllRegistrantEvents,
        getRegistrants,
        registrants,
        totalCount,
        deleteRegistrant,
        updateRegistrant,
        getAllRegistrantEventsFromRegistrant,
        refreshRegistrants,
        deleteMultipleRegistrants,
        registerParticipant,
      }}
    >
      {children}
    </RegistrantEventContext.Provider>
  );
};

export const useRegistrantEvent = () => useContext(RegistrantEventContext);
