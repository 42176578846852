import { IoRefreshOutline } from "react-icons/io5";
import { useEventSession } from "../../providers/eventSessions";
import { Container } from "./styles";

interface IProps {
  title: string;
}
const TitleWithRefreshIcon = ({ title }: IProps) => {
  const { getAllEventSessions } = useEventSession();
  return (
    <Container>
      <h3>{title}</h3>
      <span className="refreshIcon" onClick={() => getAllEventSessions(true)}>
        {" "}
        <IoRefreshOutline />
      </span>
    </Container>
  );
};

export default TitleWithRefreshIcon;
