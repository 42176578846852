import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { codes } from "../HtmlEditor";
import { IDynamicValue } from "../../providers/notifications/notifications.interface";

interface IProps {
  setModalCreateWhatsappTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalCreateWhatsappTemplate = ({
  setModalCreateWhatsappTemplate,
}: IProps) => {
  const { apiLoading } = useUser();
  const { createWhatsappTemplate } = useNotification();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [dynamicLabels, setDynamicLabels] = useState(codes[0]);
  const [dynamicValues, setDynamicValues] = useState<IDynamicValue[]>([]);
  const [contentType, setContentType] = useState("twilio/text");
  const [titles, setTitles] = useState<string[]>([""]);

  const [showUrlInput, setShowUrlInput] = useState(false);
  const [currentUrlValue, setCurrentUrlValue] = useState("");

  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    data.content_type = contentType;
    data.dynamicValues = dynamicValues;
    let actions: any[] = [];

    if (contentType === "twilio/call-to-action") {
      actions.push({
        id: generateRandomString(10),
        url: data.url,
        type: "URL",
        title: data.title,
      });
    } else if (contentType === "twilio/quick-reply") {
      actions = titles.map((title) => ({
        id: generateRandomString(10),
        title,
      }));
    }

    if (actions.length > 0) {
      data.actions = actions;
    }

    delete data.url;
    delete data.title;

    await createWhatsappTemplate(data);
    setModalCreateWhatsappTemplate(false);
  };

  const handleChange = (e: any) => {
    setLanguage(e.target.value);
  };

  const handleChangeContentType = (e: any) => {
    setContentType(e.target.value);

    if (e.target.value !== "twilio/quick-reply") {
      setTitles([""]);
    }
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup
        .string()
        .required(t("name_required"))
        .max(128, t("name_max"))
        .matches(
          /^[a-z0-9_]+$/,
          "Just lower case letters, numbers and underlines are acceptable"
        ),
      message: yup.string().required(t("field_required")),
      url: yup.string().max(128, t("field_max")),
      title: yup.string().max(128, t("field_max")),
      titles: yup.array().of(yup.string().max(128, t("field_max"))),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const addTitleField = () => {
    setTitles([...titles, ""]);
  };

  const removeTitleField = (index: number) => {
    const newTitles = [...titles];
    newTitles.splice(index, 1);
    setTitles(newTitles);
  };

  const handleTitleChange = (index: number, value: string) => {
    const newTitles = [...titles];
    newTitles[index] = value;
    setTitles(newTitles);
  };

  const handleAddDynamicValue = () => {
    if (dynamicLabels.value) {
      setDynamicValues([
        ...dynamicValues,
        {
          type: dynamicLabels.tag,
          expiration: false,
          example: dynamicLabels.example,
          number: dynamicValues.length + 1,
          value: dynamicLabels.value,
        },
      ]);
    } else {
      setShowUrlInput(true);
    }
  };

  const handleUrlInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentUrlValue(e.target.value);
  };

  const handleUrlInputConfirm = () => {
    if (currentUrlValue) {
      setDynamicValues([
        ...dynamicValues,
        {
          number: dynamicValues.length + 1,
          type: dynamicLabels.tag,
          value: currentUrlValue,
          expiration: dynamicLabels.tag === "{{EXPIRABLE_LINK}}" ? true : false,
          example: dynamicLabels.example,
        },
      ]);
      setShowUrlInput(false);
      setCurrentUrlValue("");
    }
  };

  const removeDynamicValue = (index: number) => {
    const updatedDynamicValues = [...dynamicValues];
    updatedDynamicValues.splice(index, 1);
    setDynamicValues(updatedDynamicValues);
  };

  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Create Whatsapp Template</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalCreateWhatsappTemplate(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
              >
                Save
              </Button>
            </div>
          </div>

          <FormControl>
            <InputLabel id="demo-simple-select-label">ContentType</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contentType}
              label="Content Type"
              {...register("contentType")}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
              }}
              onChange={handleChangeContentType}
            >
              <MenuItem value={"twilio/text"}>twilio/text</MenuItem>
              <MenuItem value={"twilio/call-to-action"}>
                twilio/call-to-action
              </MenuItem>
              <MenuItem value={"twilio/quick-reply"}>
                twilio/quick-reply
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            error={errors.name === undefined ? false : true}
            helperText={errors.name?.message?.toString()}
            id="standard-name"
            label={t("name")}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("name")}
          />

          <FormControl>
            <InputLabel id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label={t("language")}
              {...register("language")}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
              }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>{t("english")}</MenuItem>
              <MenuItem value={"pt_BR"}>{t("portuguese")}</MenuItem>
            </Select>
          </FormControl>

          <div className="addDynamicValue">
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Short Codes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dynamicLabels}
                label="Short codes"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                }}
                onChange={(event) => {
                  //@ts-ignore
                  setDynamicLabels(event.target.value!);
                  setShowUrlInput(false);
                }}
              >
                {codes.map((e) => (
                  //@ts-ignore
                  <MenuItem value={e} key={e.tag}>
                    {e.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              sx={{ ml: 1, maxWidth: 100 }}
              onClick={handleAddDynamicValue}
            >
              Add
            </Button>
          </div>

          {showUrlInput && (
            <div className="urlInput">
              <TextField
                id="standard-url-input"
                label="Enter URL"
                variant="filled"
                value={currentUrlValue}
                onChange={handleUrlInputChange}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  textAlign: "center",
                  width: "100%",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
              />
              <Button
                variant="contained"
                sx={{ ml: 1 }}
                onClick={handleUrlInputConfirm}
              >
                Confirm
              </Button>
            </div>
          )}

          {dynamicValues.map((e: IDynamicValue, index: number) => (
            <div
              key={e.number}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div className="dynamicValueList">
                <div>
                  <p>
                    <b>Variable:</b>
                  </p>
                  <p>&#123;&#123;{e.number}&#125;&#125;</p>
                </div>
                <div>
                  <p>
                    <b>Variable value:</b>
                  </p>
                  <p>{e.type}</p>
                </div>
              </div>
              <Button
                variant="outlined"
                sx={{ ml: 1, borderRadius: "25px" }}
                onClick={() => removeDynamicValue(index)}
              >
                Remove
              </Button>
            </div>
          ))}

          <TextField
            error={errors.message === undefined ? false : true}
            helperText={errors.message?.message?.toString()}
            id="standard-message"
            label="Message"
            variant="filled"
            multiline
            minRows={3}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("message")}
          />

          {contentType !== "twilio/text" &&
            contentType !== "twilio/quick-reply" && (
              <>
                <h3>Actions</h3>
                <TextField
                  id="standard-title"
                  error={errors.titles === undefined ? false : true}
                  helperText={errors.titles?.message?.toString()}
                  label="Title"
                  variant="filled"
                  required
                  {...register("title")}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                    textAlign: "center",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                />

                {contentType === "twilio/call-to-action" && (
                  <TextField
                    id="standard-url"
                    error={errors.url === undefined ? false : true}
                    helperText={errors.url?.message?.toString()}
                    label="Url"
                    variant="filled"
                    required
                    {...register("url")}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      "& fieldset": { border: "none" },
                    }}
                  />
                )}
              </>
            )}

          {contentType === "twilio/quick-reply" && (
            <>
              <h3>Quick Reply Buttons</h3>
              {titles.map((title, index: number) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label={`Button ${index + 1}`}
                    variant="filled"
                    required
                    value={title}
                    onChange={(e) => handleTitleChange(index, e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      flex: 1,
                      boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      "& fieldset": { border: "none" },
                    }}
                  />
                  <Button
                    variant="outlined"
                    sx={{ ml: 1, borderRadius: "25px" }}
                    onClick={() => removeTitleField(index)}
                    disabled={titles.length === 1}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              {titles.length < 3 && (
                <Button
                  variant="contained"
                  sx={{ mb: 1, borderRadius: "25px" }}
                  onClick={addTitleField}
                >
                  Add Button
                </Button>
              )}
            </>
          )}
        </form>
      </div>
    </Container>
  );
};

export default ModalCreateWhatsappTemplate;

export function generateRandomString(length: number): string {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}
