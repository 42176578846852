import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useNotification } from "../../providers/notifications";
import { useUser } from "../../providers/users";
import { Container } from "./styles";
import { useEventSession } from "../../providers/eventSessions";
import { IAudioFile } from "../../providers/notifications/notifications.interface";
import { useTag } from "../../providers/tags/index";
import NotificationTags from "../NotificationTags";
interface IProps {
  setModalAddAudio: React.Dispatch<React.SetStateAction<boolean>>;
  hourRelation: string;
}
const ModalAddAudio = ({ setModalAddAudio, hourRelation }: IProps) => {
  const { apiLoading } = useUser();
  const { allAudioFiles, getAllAudioFiles, sendSampleTestAudio } =
    useNotification();
  const { session_id } = useParams();
  const { createAudioNotification } = useEventSession();
  const [audioId, setAudioId] = useState("");
  const { t } = useTranslation();
  const [toRegistrants, setToRegistrants] = useState("all");
  const { getAllTags, allTags } = useTag();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsExclude, setTagsExclude] = useState<string[]>([]);
  const [AudioTest, setAudioTest] = useState("");
  const formSchema = yup
    .object()
    .shape({
      hoursInput: yup.number().typeError(t("field_required")),
      minutes: yup
        .number()
        .max(60)

        .typeError(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    if (!allAudioFiles) {
      getAllAudioFiles();
    }
    if (!allTags) {
      getAllTags();
    }
  }, [allAudioFiles, allTags]);
  const onSubmit = async (data: any) => {
    data.hours = data.hoursInput * 3600000 + data.minutes * 60000;
    delete data.hoursInput;
    delete data.minutes;
    delete data.seconds;
    data.hourRelation = hourRelation;
    if (hourRelation === "on") data.hours = 0;
    data.audioId = audioId;
    data.sessionId = session_id;
    data.enabled = true;
    await createAudioNotification(data);
    setModalAddAudio(false);
  };
  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Audio Notification</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalAddAudio(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
          {hourRelation !== "on" && (
            <p>
              Send this Audio notification{" "}
              <TextField
                id="standard-hour"
                // error={errors.hours === undefined ? false : true}
                // helperText={errors.hours?.message?.toString()}
                label={t("hours")}
                variant="filled"
                type="number"
                required
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("hoursInput")}
              />{" "}
              and
              <TextField
                id="standard-minutes"
                // error={errors.minutes === undefined ? false : true}
                // helperText={errors.minutes?.message?.toString()}
                label={t("minutes")}
                type="number"
                required
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  ml: 1,
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("minutes")}
              />{" "}
              {hourRelation} the session
            </p>
          )}
          <NotificationTags
            tags={tags}
            setTags={setTags}
            tagsExclude={tagsExclude}
            setTagsExclude={setTagsExclude}
            register={register}
            allTags={allTags}
            toRegistrants={toRegistrants}
            setToRegistrants={setToRegistrants}
          />

          <h3 className="AudioSettings">Audio Settings</h3>
          <FormControl>
            <InputLabel sx={{ mt: 1 }} id="demo-simple-select-label">
              Select audio file
            </InputLabel>
            <Select
              labelId="demo-simple-select-roles"
              id="demo-simple-select-roles"
              required
              value={audioId}
              onChange={(e) => setAudioId(e.target.value)}
              label={"Select audio file "}
            >
              {allAudioFiles &&
                allAudioFiles.map((e: IAudioFile) => (
                  <MenuItem value={e.id} key={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </form>

        {audioId && (
          <div className="testDiv">
            <TextField
              id="standard-AudioFrom"
              label="Send test Audio"
              placeholder="+000000000000"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              value={AudioTest}
              onChange={(e) => setAudioTest(e.target.value)}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
            />
            <Button
              onClick={() => {
                if (!AudioTest) {
                  return;
                }

                sendSampleTestAudio(AudioTest, audioId);
              }}
              sx={{ borderRadius: "25px", width: 150, ml: 1 }}
              variant="contained"
              disabled={apiLoading || !AudioTest}
              size="small"
            >
              Send
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ModalAddAudio;
