import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center !important;
  z-index: 999;

  .modalContainer {
    width: 90%;
    max-width: 900px;
    padding: 20px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
  }
  .modalHeader {
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }

  .modalContainer > span {
    display: flex;
    justify-content: flex-end;
    /* width: 90%; */
    /* margin: 10px 15px; */
    padding: 15px;
    height: 12px;
  }
  .modalContainer > form {
    display: flex;
    justify-content: center;
    padding: 15px;
    gap: 25px;
    flex-direction: column;
  }
  form > p {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .smsSettings {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .suggestedShortcodes {
    color: #767676;
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
  }
  .modalContainer > span > button {
    background-color: ${({ theme }) => theme.colors.secondary};
    /* margin: 5px; */
    /* padding: /5px; */
    color: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 5px;
  }
`;
