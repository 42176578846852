import * as yup from "yup";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useEffect } from "react";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import { useTenant } from "../../providers/tenant";

interface IProps {
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
}

const SettingsEventSessionForm = ({
  setEventSession,
  handleComplete,
}: IProps) => {
  const navigate = useNavigate();
  const { path, event_id } = useParams();
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const { createEventSession } = useEventSession();

  const { tenant, getTenant } = useTenant();
  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, [tenant]);

  const formSchema = yup.object().shape({
    name: yup.string().required(t("name_required")).max(128, t("name_max")),
    title: yup.string().required(t("field_required")).max(128, t("field_max")),
    description: yup
      .string()
      .required(t("description_required"))
      .max(128, t("description_max")),
    liveType: yup
      .string()
      .required(t("field_required"))
      .oneOf(["REDIRECT", "HOST"]),
    liveRedirectLink: yup.string().when("liveType", {
      is: "REDIRECT",
      then: yup.string().required(t("field_required")).url(t("field_max")),
      otherwise: yup.string().notRequired(),
    }),
    videoUrl: yup.string().when("liveType", {
      is: "HOST",
      then: yup
        .string()
        .required(t("video_url_required"))
        .url(t("video_url_type")),
      otherwise: yup.string().notRequired(),
    }),
    hours: yup.number().when("liveType", {
      is: "HOST",
      then: yup
        .number()
        .typeError(t("field_required"))
        .required(t("field_required"))
        .max(10),
      otherwise: yup.number().notRequired(),
    }),
    minutes: yup.number().when("liveType", {
      is: "HOST",
      then: yup
        .number()
        .typeError(t("field_required"))
        .required(t("field_required"))
        .max(60),
      otherwise: yup.number().notRequired(),
    }),
    seconds: yup.number().when("liveType", {
      is: "HOST",
      then: yup
        .number()
        .typeError(t("field_required"))
        .required(t("field_required"))
        .max(60),
      otherwise: yup.number().notRequired(),
    }),
  });

  type FormValues = {
    name: string;
    title: string;
    description: string;
    liveType: "REDIRECT" | "HOST";
    liveRedirectLink?: string;
    videoUrl?: string;
    hours?: number;
    minutes?: number;
    seconds?: number;
    videoDuration?: number;
    eventId: string;
  };

  const onSubmit = async (data: FormValues) => {
    data.videoDuration =
      (data.hours || 0) * 3600 + (data.minutes || 0) * 60 + (data.seconds || 0);

    delete data.hours;
    delete data.minutes;
    delete data.seconds;
    data.eventId = event_id!;
    const event = await createEventSession(data);
    setEventSession(event);
    handleComplete();
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      liveType: "HOST",
    },
  });

  const watchLiveType = watch("liveType");

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("general_settings")}</h3>
        <TextField
          error={!!errors.name}
          helperText={errors.name?.message}
          id="standard-name"
          label="Internal name"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("name")}
        />
        <TextField
          id="standard-title"
          error={!!errors.title}
          helperText={errors.title?.message}
          label={t("title")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("title")}
        />
        <TextField
          id="standard-description"
          error={!!errors.description}
          helperText={errors.description?.message}
          label={t("description")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("description")}
        />

        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="live-type-label">Live type</InputLabel>
          <Controller
            name="liveType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="live-type-label"
                label={"Live type"}
                variant="filled"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
              >
                <MenuItem value="REDIRECT">REDIRECT</MenuItem>
                <MenuItem value="HOST">HOST</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        {watchLiveType === "REDIRECT" && (
          <TextField
            error={!!errors.liveRedirectLink}
            helperText={errors.liveRedirectLink?.message}
            label={"Live redirect"}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
            {...register("liveRedirectLink")}
          />
        )}

        {watchLiveType === "HOST" && (
          <>
            <h3>{t("video_configuration")}</h3>
            <TextField
              id="standard-videoUrl"
              error={!!errors.videoUrl}
              helperText={errors.videoUrl?.message}
              label={t("video_url")}
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("videoUrl")}
            />
            <h3>{t("video_duration")}</h3>
            <div className="durationDiv">
              <TextField
                id="standard-hour"
                error={!!errors.hours}
                helperText={errors.hours?.message}
                label={t("hours")}
                variant="filled"
                type="number"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("hours")}
              />
              <TextField
                id="standard-minutes"
                error={!!errors.minutes}
                helperText={errors.minutes?.message}
                label={t("minutes")}
                type="number"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  ml: 1,
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("minutes")}
              />
              <TextField
                id="standard-seconds"
                error={!!errors.seconds}
                helperText={errors.seconds?.message}
                label={t("seconds")}
                type="number"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  ml: 1,
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  "& fieldset": { border: "none" },
                }}
                {...register("seconds")}
              />
            </div>
          </>
        )}

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            disabled={apiLoading}
            sx={{ mr: 1, borderRadius: "25px" }}
          >
            'Save and next'
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default SettingsEventSessionForm;
