import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useState } from "react";
import { Container } from "./styles";

import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdEdit, MdPeople } from "react-icons/md";
import { useTag } from "../../providers/tags";

import { useUser } from "../../providers/users";
import Loading from "../Loading";
import ModalConfirmDeletion from "../ModalConfirmDeletion/index";
import ModalUpdateTag from "../ModalUpdateTag";
import PageHeader from "../pageHeader";
import ModalCreateTag from "../ModalCreateTag";

export const TagsContainer = () => {
  const { allTags, deleteTag } = useTag();
  const { apiLoading } = useUser();
  const [TagField, setTagField] = useState("");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [updateItem, setUpdateItem] = useState<any>(null);
  const [modalUpdateTag, setModalUpdateTag] = useState(false);
  const [modalCreateTag, setModalCreateTag] = useState(false);
  const [modalConfirmDeletion, setModalConfirmDeletion] = useState(false);
  const filteredTags: any[] = allTags
    ? allTags.filter((e: any) => e.name.includes(TagField))
    : [];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },

    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    {
      field: "Last update",
      headerName: "Last update",
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updatedAt && params.row.updatedAt.split("T")[0],
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="tagActions">
          <MdEdit
            size={28}
            onClick={() => {
              setUpdateItem(params.row);
              setModalUpdateTag(true);
            }}
          />
          <IoMdClose
            size={28}
            onClick={() => {
              setModalConfirmDeletion(true);
              setDeleteId(params.row.id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={"Tags"}
        description={"Tags list"}
        icon={<MdPeople />}
      />
      <Container>
        <section className="filterSection">
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search ">{<FaSearch />}</IconButton>
                </InputAdornment>
              ),
            }}
            id="standard-search"
            label="Search for Tag"
            type={"text"}
            value={TagField}
            onChange={(e) => setTagField(e.target.value)}
            variant="filled"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

              textAlign: "center",
              backgroundColor: "#FFF",
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            sx={{
              borderRadius: "25px",
            }}
            variant="outlined"
            disabled={apiLoading}
            onClick={() => setModalCreateTag(true)}
          >
            Add Tag
          </Button>
        </section>
        {apiLoading ? (
          <Loading />
        ) : (
          <>
            <DataGrid
              rows={filteredTags}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              sx={{
                mt: 3,
                border: 0,
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 2px 2px",
                padding: 1,
                maxHeight: 1000,
              }}
              pageSizeOptions={[5, 15, 50, 100]}
              disableRowSelectionOnClick
            />
          </>
        )}
      </Container>
      {modalCreateTag && (
        <ModalCreateTag setModalCreateTag={setModalCreateTag} />
      )}
      {deleteId && modalConfirmDeletion && (
        <ModalConfirmDeletion
          deleteId={deleteId}
          setModalConfirmDeletion={setModalConfirmDeletion}
          deleteFunction={deleteTag}
        />
      )}

      {updateItem && modalUpdateTag && (
        <ModalUpdateTag
          item={updateItem}
          setModalUpdateTag={setModalUpdateTag}
        />
      )}
    </>
  );
};
