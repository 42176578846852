import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";
import * as yup from "yup";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { Container, ColorPickerWrapper, ColorSection } from "./styles";
import { useUser } from "../../providers/users";
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}

const WidgetSettingsForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const { editEventSession } = useEventSession();
  const [primaryFontColor, setPrimaryFontColor] = useState("#000000");
  const [secondaryFontColor, setSecondaryFontColor] = useState("#000000");
  const [buttonColor, setButtonColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff");
  const [inputBackgroundColor, setInputBackgroundColor] = useState("#ffffff");
  const [inputTextColor, setInputTextColor] = useState("#000000");
  const [inputBorderColor, setInputBorderColor] = useState("#cccccc");
  const [labelColor, setLabelColor] = useState("#000000");
  const [countdownColor, setCountdownColor] = useState("#000000");
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState("#f8f9fa");
  const [formBackgroundColor, setFormBackgroundColor] = useState("#ffffff");
  const [countdown, setCountdown] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [titleFontSize, setTitleFontSize] = useState("24px");
  const [subheadFontSize, setSubheadFontSize] = useState("18px");
  const [labelFontSize, setLabelFontSize] = useState("14px");
  const [inputFontSize, setInputFontSize] = useState("16px");
  const [buttonFontSize, setButtonFontSize] = useState("16px");
  const [countdownFontSize, setCountdownFontSize] = useState("20px");
  const [displayColorPicker, setDisplayColorPicker] = useState({
    primary: false,
    secondary: false,
    button: false,
    background: false,
    buttonText: false,
    inputBackground: false,
    inputText: false,
    inputBorder: false,
    label: false,
    countdown: false,
    headerBackground: false,
    formBackground: false,
  });

  useEffect(() => {
    if (eventSession) {
      setPrimaryFontColor(eventSession.widget?.primaryFontColor || "#000000");
      setSecondaryFontColor(
        eventSession.widget?.secondaryFontColor || "#000000"
      );
      setButtonColor(eventSession.widget?.buttonColor || "#000000");
      setBackgroundColor(eventSession.widget?.backgroundColor || "#ffffff");
      setButtonTextColor(eventSession.widget?.buttonTextColor || "#ffffff");
      setInputBackgroundColor(
        eventSession.widget?.inputBackgroundColor || "#ffffff"
      );
      setInputTextColor(eventSession.widget?.inputTextColor || "#000000");
      setInputBorderColor(eventSession.widget?.inputBorderColor || "#cccccc");
      setLabelColor(eventSession.widget?.labelColor || "#000000");
      setCountdownColor(eventSession.widget?.countdownColor || "#000000");
      setHeaderBackgroundColor(
        eventSession.widget?.headerBackgroundColor || "#f8f9fa"
      );
      setFormBackgroundColor(
        eventSession.widget?.formBackgroundColor || "#ffffff"
      );
      setCountdown(eventSession.widget?.countdown || false);
      setShowHeader(eventSession.widget?.showHeader ? true : false);
      setTitleFontSize(eventSession.widget?.titleFontSize || "24px");
      setSubheadFontSize(eventSession.widget?.subheadFontSize || "18px");
      setLabelFontSize(eventSession.widget?.labelFontSize || "14px");
      setInputFontSize(eventSession.widget?.inputFontSize || "16px");
      setButtonFontSize(eventSession.widget?.buttonFontSize || "16px");
      setCountdownFontSize(eventSession.widget?.countdownFontSize || "20px");
    }
  }, [eventSession]);

  const formSchema = yup
    .object()
    .shape({
      textEventSubhead: yup.string(),
      buttonText: yup.string(),
      textFormTitle: yup.string(),
      labelEmail: yup.string(),
      labelName: yup.string(),
      labelPhone: yup.string(),
      labelDays: yup.string(),
      labelHours: yup.string(),
      labelMinutes: yup.string(),
      labelSeconds: yup.string(),
      primaryFontColor: yup.string(),
      secondaryFontColor: yup.string(),
      buttonColor: yup.string(),
      backgroundColor: yup.string(),
      buttonTextColor: yup.string(),
      inputBackgroundColor: yup.string(),
      inputTextColor: yup.string(),
      inputBorderColor: yup.string(),
      labelColor: yup.string(),
      countdownColor: yup.string(),
      headerBackgroundColor: yup.string(),
      formBackgroundColor: yup.string(),
      countdown: yup.boolean(),
      showHeader: yup.boolean(),
      redirectUrl: yup.string(),
      campaignId: yup.string(),
      confirmationText: yup.string(),
      titleFontSize: yup.string(),
      subheadFontSize: yup.string(),
      labelFontSize: yup.string(),
      inputFontSize: yup.string(),
      buttonFontSize: yup.string(),
      countdownFontSize: yup.string(),
    })
    .required();

  type FormValues = yup.InferType<typeof formSchema>;

  const onSubmit = async (data: FormValues) => {
    data.primaryFontColor = primaryFontColor;
    data.secondaryFontColor = secondaryFontColor;
    data.buttonColor = buttonColor;
    data.backgroundColor = backgroundColor;
    data.buttonTextColor = buttonTextColor;
    data.inputBackgroundColor = inputBackgroundColor;
    data.inputTextColor = inputTextColor;
    data.inputBorderColor = inputBorderColor;
    data.labelColor = labelColor;
    data.countdownColor = countdownColor;
    data.headerBackgroundColor = headerBackgroundColor;
    data.formBackgroundColor = formBackgroundColor;
    data.countdown = countdown;
    data.showHeader = showHeader;
    data.titleFontSize = titleFontSize;
    data.subheadFontSize = subheadFontSize;
    data.labelFontSize = labelFontSize;
    data.inputFontSize = inputFontSize;
    data.buttonFontSize = buttonFontSize;
    data.countdownFontSize = countdownFontSize;

    if (!eventSession) {
      toast.error("Something went wrong, please try again.");
      return;
    }

    const response = await editEventSession(eventSession.id, { widget: data });

    setEventSession(response);
    handleComplete();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleColorClick = (colorType: string) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [colorType]: !displayColorPicker[colorType],
    });
  };

  const handleColorClose = (colorType: string) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [colorType]: false,
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Widget Settings</h3>
        {eventSession && <ReadableIframeWithCopy eventSession={eventSession} />}

        <section className="basic-info-section">
          <h4>Basic Information</h4>
          <FormControlLabel
            control={
              <Switch
                checked={showHeader}
                onChange={(e) => setShowHeader(e.target.checked)}
                name="showHeader"
              />
            }
            label="Show Header"
          />
          <TextField
            error={!!errors.textEventSubhead}
            helperText={errors.textEventSubhead?.message?.toString()}
            id="textEventSubhead"
            label="Event Subhead"
            variant="filled"
            defaultValue={eventSession?.widget?.textEventSubhead}
            sx={{ mr: 2, mb: 2 }}
            {...register("textEventSubhead")}
          />
          <TextField
            error={!!errors.textFormTitle}
            helperText={errors.textFormTitle?.message?.toString()}
            id="textFormTitle"
            label="Form Title"
            variant="filled"
            defaultValue={eventSession?.widget?.textFormTitle}
            sx={{ mr: 2, mb: 2 }}
            {...register("textFormTitle")}
          />
          <TextField
            error={!!errors.buttonText}
            helperText={errors.buttonText?.message?.toString()}
            id="buttonText"
            label="Button text"
            variant="filled"
            defaultValue={eventSession?.widget?.buttonText}
            sx={{ mr: 2, mb: 2 }}
            {...register("buttonText")}
          />
          <TextField
            error={!!errors.redirectUrl}
            helperText={errors.redirectUrl?.message?.toString()}
            id="redirectUrl"
            label="Redirect URL"
            variant="filled"
            defaultValue={eventSession?.widget?.redirectUrl}
            sx={{ mr: 2, mb: 2 }}
            {...register("redirectUrl")}
          />
          <TextField
            error={!!errors.confirmationText}
            helperText={errors.confirmationText?.message?.toString()}
            id="confirmationText"
            label="Confirmation text"
            variant="filled"
            defaultValue={eventSession?.widget?.confirmationText}
            sx={{ mr: 2, mb: 2 }}
            {...register("confirmationText")}
          />
        </section>

        <section className="contact-info-section">
          <h4>Contact Information</h4>
          <TextField
            error={!!errors.labelEmail}
            helperText={errors.labelEmail?.message?.toString()}
            id="labelEmail"
            label="Email Label"
            variant="filled"
            defaultValue={eventSession?.widget?.labelEmail}
            sx={{ mr: 2, mb: 2 }}
            {...register("labelEmail")}
          />
          <TextField
            error={!!errors.labelName}
            helperText={errors.labelName?.message?.toString()}
            id="labelName"
            label="Name Label"
            variant="filled"
            defaultValue={eventSession?.widget?.labelName}
            sx={{ mr: 2, mb: 2 }}
            {...register("labelName")}
          />
          <TextField
            error={!!errors.labelPhone}
            helperText={errors.labelPhone?.message?.toString()}
            id="labelPhone"
            label="Phone Label"
            variant="filled"
            defaultValue={eventSession?.widget?.labelPhone}
            sx={{ mr: 2, mb: 2 }}
            {...register("labelPhone")}
          />
        </section>
        <section className="additional-section">
          <h3>Additional Settings</h3>
          <TextField
            error={!!errors.campaignId}
            helperText={errors.campaignId?.message?.toString()}
            id="campaignId"
            label="Campaign Id"
            variant="filled"
            defaultValue={eventSession?.widget?.campaignId}
            sx={{ mr: 2, mb: 2 }}
            {...register("campaignId")}
          />
        </section>
        <section className="countdown-section">
          <h4>Countdown Settings</h4>

          <FormControlLabel
            control={
              <Switch
                checked={countdown}
                onChange={(e) => setCountdown(e.target.checked)}
                name="countdown"
              />
            }
            label="Enable Countdown"
          />
          {countdown && (
            <>
              <TextField
                error={!!errors.startsInLabel}
                helperText={errors.startsInLabel?.message?.toString()}
                id="startsInLabel"
                label="Starts in Label"
                variant="filled"
                defaultValue={eventSession?.widget?.startsInLabel}
                sx={{ mr: 2, mb: 2 }}
                {...register("startsInLabel")}
              />
              <TextField
                error={!!errors.labelDays}
                helperText={errors.labelDays?.message?.toString()}
                id="labelDays"
                label="Days Label"
                variant="filled"
                defaultValue={eventSession?.widget?.labelDays}
                sx={{ mr: 2, mb: 2 }}
                {...register("labelDays")}
              />
              <TextField
                error={!!errors.labelHours}
                helperText={errors.labelHours?.message?.toString()}
                id="labelHours"
                label="Hours Label"
                variant="filled"
                defaultValue={eventSession?.widget?.labelHours}
                sx={{ mr: 2, mb: 2 }}
                {...register("labelHours")}
              />
              <TextField
                error={!!errors.labelMinutes}
                helperText={errors.labelMinutes?.message?.toString()}
                id="labelMinutes"
                label="Minutes Label"
                variant="filled"
                defaultValue={eventSession?.widget?.labelMinutes}
                sx={{ mr: 2, mb: 2 }}
                {...register("labelMinutes")}
              />
              <TextField
                error={!!errors.labelSeconds}
                helperText={errors.labelSeconds?.message?.toString()}
                id="labelSeconds"
                label="Seconds Label"
                variant="filled"
                defaultValue={eventSession?.widget?.labelSeconds}
                sx={{ mr: 2, mb: 2 }}
                {...register("labelSeconds")}
              />
            </>
          )}
        </section>

        <section className="color-settings-section">
          <h4>Color Settings</h4>
          <ColorSection>
            {[
              {
                label: "Primary Font Color",
                state: primaryFontColor,
                setter: setPrimaryFontColor,
                key: "primary",
              },
              {
                label: "Secondary Font Color",
                state: secondaryFontColor,
                setter: setSecondaryFontColor,
                key: "secondary",
              },
              {
                label: "Button Color",
                state: buttonColor,
                setter: setButtonColor,
                key: "button",
              },
              {
                label: "Background Color",
                state: backgroundColor,
                setter: setBackgroundColor,
                key: "background",
              },
              {
                label: "Button Text Color",
                state: buttonTextColor,
                setter: setButtonTextColor,
                key: "buttonText",
              },
              {
                label: "Input Background Color",
                state: inputBackgroundColor,
                setter: setInputBackgroundColor,
                key: "inputBackground",
              },
              {
                label: "Input Text Color",
                state: inputTextColor,
                setter: setInputTextColor,
                key: "inputText",
              },
              {
                label: "Input Border Color",
                state: inputBorderColor,
                setter: setInputBorderColor,
                key: "inputBorder",
              },
              {
                label: "Label Color",
                state: labelColor,
                setter: setLabelColor,
                key: "label",
              },
              {
                label: "Countdown Color",
                state: countdownColor,
                setter: setCountdownColor,
                key: "countdown",
              },
              {
                label: "Header Background Color",
                state: headerBackgroundColor,
                setter: setHeaderBackgroundColor,
                key: "headerBackground",
              },
              {
                label: "Form Background Color",
                state: formBackgroundColor,
                setter: setFormBackgroundColor,
                key: "formBackground",
              },
            ].map(({ label, state, setter, key }) => (
              <div key={key} className="color-picker-container">
                <FormLabel>{label}</FormLabel>
                <ColorPickerWrapper>
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: state, border: "1px solid #ccc" }}
                    onClick={() => handleColorClick(key)}
                  />
                  {displayColorPicker[key] && (
                    <div className="popover">
                      <div
                        className="cover"
                        onClick={() => handleColorClose(key)}
                      />
                      <SketchPicker
                        color={state}
                        onChange={(color) => setter(color.hex)}
                        className="sketch-picker"
                      />
                    </div>
                  )}
                </ColorPickerWrapper>
              </div>
            ))}
          </ColorSection>
        </section>

        <section className="font-size-settings-section">
          <h4>Font Size Settings</h4>
          {[
            {
              label: "Title Font Size",
              value: titleFontSize,
              setter: setTitleFontSize,
            },
            {
              label: "Subhead Font Size",
              value: subheadFontSize,
              setter: setSubheadFontSize,
            },
            {
              label: "Label Font Size",
              value: labelFontSize,
              setter: setLabelFontSize,
            },
            {
              label: "Input Font Size",
              value: inputFontSize,
              setter: setInputFontSize,
            },
            {
              label: "Button Font Size",
              value: buttonFontSize,
              setter: setButtonFontSize,
            },
            {
              label: "Countdown Font Size",
              value: countdownFontSize,
              setter: setCountdownFontSize,
            },
          ].map(({ label, value, setter }) => (
            <FormControl key={label} fullWidth margin="normal">
              <InputLabel>{label}</InputLabel>
              <Select
                value={value}
                onChange={(e) => setter(e.target.value)}
                label={label}
              >
                {[
                  "12px",
                  "14px",
                  "16px",
                  "18px",
                  "20px",
                  "24px",
                  "28px",
                  "32px",
                ].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </section>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            onClick={handleBack}
            disabled={apiLoading}
            sx={{ mr: 1, borderRadius: "25px" }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            disabled={apiLoading}
            sx={{ mr: 1, borderRadius: "25px" }}
          >
            Save
          </Button>
        </Box>
      </form>
    </Container>
  );
};

const ReadableIframeWithCopy = ({ eventSession }) => {
  const { t } = useTranslation();

  const getScriptUrl = () => {
    return `${process.env.REACT_APP_FRONTEND_URL}/v1/embed/widget-script.js`;
  };

  const getWidgetCode = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL!;
    return `<div id="event-registration-widget" style="width: 500px; "></div>
<script async src="${getScriptUrl()}"></script>
<script>
window.addEventListener('load', function() {
initEventWidget({
  id: "${eventSession.id}",
  tenantId: "${eventSession.tenantId}",
  baseUrl: "${backendUrl}"
});
});
</script>`;
  };

  const handlePreview = () => {
    const previewHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Event Registration Widget Preview</title>
<style>
  body {
      font-family: Arial, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
      background-color: #f0f0f0;
  }
</style>
</head>
<body>
<div id="widget-container">
  ${getWidgetCode()}
</div>
</body>
</html>`;

    const blob = new Blob([previewHtml], { type: "text/html" });
    const previewUrl = URL.createObjectURL(blob);
    window.open(previewUrl, "_blank");
  };

  return (
    <div>
      <pre
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "4px",
          overflowX: "auto",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
      >
        <code>{getWidgetCode()}</code>
      </pre>
      <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
        <CopyToClipboard
          onCopy={() => toast.success(t("copied"))}
          text={getWidgetCode()}
        >
          <Button
            sx={{
              borderRadius: "25px",
            }}
            variant="contained"
          >
            {t("copy")}
          </Button>
        </CopyToClipboard>
        <Button
          sx={{
            borderRadius: "25px",
          }}
          variant="contained"
          onClick={handlePreview}
        >
          {t("preview")}
        </Button>
      </Box>
    </div>
  );
};

export default WidgetSettingsForm;
