import ProfileContainer from "../../components/ProfileContainer";

const Profile = () => {
  return (
    <>
      <ProfileContainer />
    </>
  );
};

export default Profile;
