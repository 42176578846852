import Loading from "../../components/Loading";
import SettingsContainer from "../../components/SettingsContainer";
import { useTenant } from "../../providers/tenant";
import { useUser } from "../../providers/users";

const SettingsPage = () => {
  const { tenant } = useTenant();
  const { apiLoading } = useUser();
  return <>{!tenant ? <Loading /> : <SettingsContainer />}</>;
};

export default SettingsPage;
