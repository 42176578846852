import { useParams } from "react-router-dom";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";

interface IProps {
  dashboardLink?: boolean;
}

const ErrorPage = ({ dashboardLink = false }: IProps) => {
  const { path } = useParams();
  const { t } = useTranslation();
  return (
    <Container>
      <h1>404</h1>
      <h2>{t("page_not_found")}</h2>

      <p>{t("sorry_not_found")}</p>

      {dashboardLink === true ? (
        <a href={`/${path}/events`} target="_self">
          {t("home_page")}
        </a>
      ) : (
        <p> {t("contact_provider")}</p>
      )}
    </Container>
  );
};

export default ErrorPage;
