import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCart4 } from "react-icons/bs";
import { useEvent } from "../../providers/events";
import { IEvent } from "../../providers/events/events.interface";
import { useUser } from "../../providers/users";

import PageHeader from "../pageHeader";
import { Container } from "./styles";
import { FaSearch } from "react-icons/fa";
import PaginatedItems from "../Paginate";
import EventsList from "../EventsList";
import ModalConfirmDeletion from "../ModalConfirmDeletion";
interface IProps {
  currentItems: IEvent[];
}

const EventsContainer = () => {
  const { roleAdmin } = useUser();
  const { allEvents } = useEvent();
  const { t } = useTranslation();
  const [eventName, setEventName] = useState("");

  const filteredEvents: IEvent[] = allEvents
    ? allEvents.filter(
        (e: IEvent) =>
          e.name.toLowerCase().includes(eventName.toLowerCase()) ||
          e.language.toLowerCase().includes(eventName.toLowerCase())
      )
    : [];

  return (
    <Container>
      <PageHeader
        title={t("events")}
        description={t("events_list")}
        buttonContent={t("create")}
        buttonPath="events/create"
        verifyRole={true}
        roleToVerify={roleAdmin}
        icon={<BsCart4 />}
      />
      <section className="filterSection">
        <TextField
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search event">
                  {<FaSearch />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          id="standard-search"
          label={t("search_event")}
          type={"text"}
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          variant="filled"
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
        />
      </section>
      {allEvents && (
        <PaginatedItems
          items={filteredEvents}
          itemsPerPage={3}
          Items={EventsList}
        />
      )}
    </Container>
  );
};

export default EventsContainer;
