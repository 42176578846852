import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */
  h2 {
    text-transform: capitalize;
  }
  .passwordRecoveryHeader {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    margin: 10 auto;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .logoutLink {
    color: ${({ theme }) => theme.colors.gray};
    text-decoration: underline;
    /* text-align: center; */
    margin-top: 10px;
  }
  .logoutLink:hover {
    cursor: pointer;
    color: grey;
  }
`;
